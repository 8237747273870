import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../_services/api.service';
import {SharedService} from "../../_services/shared_service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})


export class SidebarComponent implements OnInit {

  collapse_disabled: boolean = false;
  public role_features = [];
  public show_dcList = false;
  public show_dc_transferList = false;
  public show_poList = false;
  public show_dcReport = false;
  public show_sales = false;
  public show_teamReport = false;
  public show_userReport = false;
  public show_loadinReport = false;
  public show_pricelistReport = false;
  public show_mt_module = false;
  public show_po_module = false;
  public show_dms_module = false;
  public show_expense_module = false;
  public show_expense_approve = false;
  public showExpenseToSettle = false;
  public url;
  public dms_drop_dwn_s = false;
  public dms_drop_dwn_p = false;
  public po_drop_dwn = false;
  public exp_drop_dwn = false;
  public claim_drop_dwn = false;
  public reports_drop_dwn = false;
  public retail_audit_drop_dwn = false;
  public assets_drop_dwn = false;
  public service_drop_dwn = false;
  public supplier_drop_dwn = false;
  public retail_drop_dwn = false;
  public influencer_drop_dwn = false;
  public users_drop_dwn = false;
  public products_drop_dwn = false;
  public retail_survey_drop_dwn = false;
  public beats_drop_dwn = false;
  public gstr_drop_dwn = false;
  public loadout_name = 'Production Order';
  public dmsType = '';
  public showCustomers = false;
  public showTasks = false;
  public showTaskPlanner = false;
  public showRetailAudit = false;
  public showDistributorAudit = false;
  public showAsset = false;
  public showRetailRequestAsset = false;
  public showAssetServiceTicket = false;
  public showRetailSurvey = false;
  public showUserSurvey = false;
  public show_user_module = false;
  public show_products_module = false;
  public showRetailers = false;
  public showInfluencers = false;
  public showProjects = false;
  public approveOutlet = false;
  public changeRequest = false;
  public showBeats = false;
  public showDeliveryPlan = false;
  public showPartnerApprove = false;
  public showLeads = false;
  public showPartnerLeads = false;
  public show_direct_primary_orders = false;
  public show_direct_primary_invoices = false;
  public show_schemes = false;
  public scheme_drop_dwn = false;
  public task_drop_dwn = false;
  public master_drop_dwn = false;
  public showMaster = false;
  public outletProductRecommendataion = false;
  public showRetailerApprove = false;
  public showPlanogram = false;

  public show_dmsv2_module = false;
  public show_dmsv2_customers = false;
  public show_dmsv2_purchase = false;
  public show_dmsv2_claims = false;
  public show_module_vansales = false;
  public show_module_payments = false;
  public show_dmsv2_claim_approve = false;

  public dmsv2_drop_dwn_partners = false;
  public dmsv2_drop_dwn_sales = false;
  public dmsv2_drop_dwn_purchase = false;
  public dmsv2_drop_dwn_claims = false;
  public dmsv2_drop_dwn_vansale = false;
  public dmsv2_drop_dwn_payments = false;

  public show_credit_notes = false;
  public showService = false;
  public showServiceRequest = false;
  public showGstr = false;
  constructor(private elRef: ElementRef,
              private renderer: Renderer2,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private apiService: ApiService,
              private sharedService: SharedService) {
    localStorage.setItem('collapse_disabled', this.collapse_disabled.toString());
    this.show_dcList = (localStorage.getItem('dc_head') === 'true');
    this.show_dc_transferList = (localStorage.getItem('feature_stock_transfer') === 'true');
    this.show_poList = (localStorage.getItem('pro_head') === 'true');
    this.show_sales = (localStorage.getItem('sales_head') === 'true');
    this.show_dcReport = (localStorage.getItem('dc_report') === 'true');
    this.show_teamReport = (localStorage.getItem('team_report') === 'true');
    this.show_userReport = (localStorage.getItem('user_report') === 'true');
    this.show_loadinReport = (localStorage.getItem('loadin_report') === 'true');
    this.show_pricelistReport = (localStorage.getItem('pricelist_report') === 'true');
    this.show_mt_module = (localStorage.getItem('mt_module') === 'true');
    this.show_po_module = (localStorage.getItem('po_module') === 'true');
    this.show_dms_module = (localStorage.getItem('dms_module') === 'true');
    this.show_expense_module = (localStorage.getItem('expense_module') === 'true');
    this.show_expense_approve = ((localStorage.getItem('feature_expense_approve2') === 'true') ||  (localStorage.getItem('feature_expense_approve1') === 'true'));
    this.showExpenseToSettle = (localStorage.getItem('feature_expense_settle') === 'true');
    this.showCustomers = (localStorage.getItem('module_distributors') === 'true');
    this.showRetailers = (localStorage.getItem('module_retailers') === 'true');
    this.showTasks = (localStorage.getItem('module_tasks') === 'true');
    this.showTaskPlanner = (localStorage.getItem('feature_task_planner') === 'true');
    this.showRetailAudit = (localStorage.getItem('module_retailAudit') === 'true');
    this.showDistributorAudit = (localStorage.getItem('module_distributorAudit') === 'true');
    this.showAsset = (localStorage.getItem('module_asset') === 'true');
    this.showRetailRequestAsset = (localStorage.getItem('feature_retail_request_asset') === 'true');
    this.showAssetServiceTicket = (localStorage.getItem('feature_asset_service_ticket') === 'true');
    this.showRetailSurvey = (localStorage.getItem('module_retail_survey') === 'true');
    this.show_user_module = (localStorage.getItem('module_users') === 'true');
    this.show_products_module = (localStorage.getItem('module_products') === 'true');
    this.showUserSurvey = (localStorage.getItem('module_user_survey') === 'true');
    this.approveOutlet = (localStorage.getItem('feature_approve_outlet') === 'true');
    this.changeRequest = (localStorage.getItem('feature_change_request') === 'true');
    this.showBeats = (localStorage.getItem('module_beats') === 'true');
    this.showInfluencers = (localStorage.getItem('feature_influencers') === 'true');
    this.showProjects = (localStorage.getItem('feature_projects') === 'true');
    this.showDeliveryPlan = (localStorage.getItem('feature_delivery_plan') === 'true');
    this.showPartnerApprove = (localStorage.getItem('feature_partner_approve') === 'true');
    this.showRetailerApprove = (localStorage.getItem('feature_retailer_approve') === 'true');
    this.showPlanogram = (localStorage.getItem('feature_planogram') === 'true');
    this.showLeads = (localStorage.getItem('feature_leads') === 'true');
    this.showPartnerLeads = (localStorage.getItem('feature_leads') === 'true');
    this.show_direct_primary_orders = (localStorage.getItem('module_direct_primary_orders') === 'true');
    this.show_direct_primary_invoices = (localStorage.getItem('module_direct_primary_invoices') === 'true');
    this.show_schemes = (localStorage.getItem('module_schemes') === 'true');
    this.showMaster = (localStorage.getItem('module_master') === 'true');
    this.outletProductRecommendataion = (localStorage.getItem('feature_outlet_prod_reco') === 'true');
    if (this.show_po_module) {
      this.loadout_name = 'Loadout';
    }

    this.show_dmsv2_module = (localStorage.getItem('module_dmsv2') === 'true');
    this.show_dmsv2_customers = (localStorage.getItem('module_dmsv2_customers') === 'true');
    this.show_dmsv2_purchase = (localStorage.getItem('module_dmsv2_purchase') === 'true');
    this.show_module_vansales = (localStorage.getItem('module_vansales') === 'true');
    this.show_module_payments = (localStorage.getItem('module_payments') === 'true');
    this.show_dmsv2_claims = (localStorage.getItem('module_claims') === 'true');
    this.show_dmsv2_claim_approve = (localStorage.getItem('feature_claim_approve') === 'true');
    this.show_credit_notes = (localStorage.getItem('feature_credit_notes') === 'true');

    this.showService = (localStorage.getItem('feature_service') === 'true');
    this.showServiceRequest = (localStorage.getItem('feature_service_request') === 'true');
    this.showGstr = (localStorage.getItem('feature_gstr') === 'true');
    //this.showGstr = true;

    // this.show_dmsv2_purchase = true;
    // this.show_module_vansales = true;
    // this.show_dmsv2_claim_approve = true;

    // this.show_dmsv2_customers = true;
  }

  ngOnInit() {

    /*document.getElementById("sidebarul").addEventListener("click",function(e) {
     console.log(e);
    });*/
  }

  hideShowDMSPartners() {
    this.dmsv2_drop_dwn_partners = !this.dmsv2_drop_dwn_partners;
    const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
    for (const item of elements) {
      if (item.id === 'dmsv2_customers' || item.id === 'dmsv2_projects' || item.id === 'dmsv2_suppliers' || item.id === 'dmsv2_change_request' ) {
        if (this.dmsv2_drop_dwn_partners) {
          this.renderer.removeClass(item, 'hide');
          //this.gotoDMS('customers', 'dmsv2_customers');
        } else {
          this.renderer.addClass(item, 'hide');
        }
      }
    }
  }


  hideShowDMSSecondary() {
    this.dmsv2_drop_dwn_sales = !this.dmsv2_drop_dwn_sales;
    const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
    for (const item of elements) {
      if (item.id === 'dmsv2_orders' || item.id === 'dmsv2_invoices' || item.id === 'dmsv2_sec_return_inv' || item.id === 'dmsv2_sec_invoice_claims'
        || item.id === 'dmsv2_delivery_plan' || item.id === 'dmsv2_tertiary') {
        if (this.dmsv2_drop_dwn_sales) {
          this.renderer.removeClass(item, 'hide');
          this.gotoDMS('sec_sale_orders', 'dmsv2_orders');
        } else {
          this.renderer.addClass(item, 'hide');
        }
      }
    }
  }

  hideShowDMSPurchase() {
    this.dmsv2_drop_dwn_purchase = !this.dmsv2_drop_dwn_purchase;
    const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
    for (const item of elements) {
      if (item.id === 'dmsv2_purchase_order' || item.id === 'dmsv2_grn' || item.id === 'dmsv2_purchase_return' || item.id === 'dmsv2_p_schemes' || item.id === 'dmsv2_p_stock' || item.id === 'dmsv2_batch_stock') {
        if (this.dmsv2_drop_dwn_purchase) {
          this.renderer.removeClass(item, 'hide');
          this.gotoDMS('purchase_orders', 'dmsv2_purchase_order');
        } else {
          this.renderer.addClass(item, 'hide');
        }
      }
    }
  }

  hideShowDMSClaims() {
    this.dmsv2_drop_dwn_claims = !this.dmsv2_drop_dwn_claims;
    const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
    for (const item of elements) {
      if (item.id === 'dmsv2_claims' || item.id === 'dmsv2_scheme_raise_claim' || item.id === 'dmsv2_sale_return_raise_claim'
        || item.id === 'dmsv2_multi_bill_raise_claim' || item.id === 'dmsv2_supplier_claims' || item.id === 'dmsv2_debit_notes' || item.id === 'dmsv2_my_expenses') {
        if (this.dmsv2_drop_dwn_claims) {
          this.renderer.removeClass(item, 'hide');
          this.gotoDMS('claims', 'dmsv2_claims');
        } else {
          this.renderer.addClass(item, 'hide');
        }
      }
    }
  }

  hideShowVansale() {
    this.dmsv2_drop_dwn_vansale = !this.dmsv2_drop_dwn_vansale;
    const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
    for (const item of elements) {
      if (item.id === 'dmsv2_loadout' || item.id === 'dmsv2_settlement' || item.id === 'dmsv2_transfer') {
        if (this.dmsv2_drop_dwn_vansale) {
          this.renderer.removeClass(item, 'hide');
          this.gotoVansales('load_outs', 'dmsv2_loadout');
        } else {
          this.renderer.addClass(item, 'hide');
        }
      }
    }
  }

  hideShowPayments() {
    this.dmsv2_drop_dwn_payments = !this.dmsv2_drop_dwn_payments;
    const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
    for (const item of elements) {
      if (item.id === 'dmsv2_credit_notes' || item.id === 'dmsv2_pay_receipts'  || item.id === 'dmsv2_ledger') {
        if (this.dmsv2_drop_dwn_payments) {
          this.renderer.removeClass(item, 'hide');
          this.gotoV2Payments('pay_receipts', 'dmsv2_pay_receipts');
        } else {
          this.renderer.addClass(item, 'hide');
        }
      }
    }
  }

  gotoDropdown(event) {
    //debugger;
    if (event === 'dms_secondary') {
      this.dmsType = 'S';
      this.dms_drop_dwn_s = !this.dms_drop_dwn_s;
      const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === 'dms_orders' || elements[i].id === 'dms_invoice' ||
          elements[i].id === 'dms_s_return' || elements[i].id === 'dms_s_payment' || elements[i].id === 'dms_sec_return_inv' ||
          elements[i].id === 'dms_s_credit' || elements[i].id === 'dms_del_plan' || elements[i].id === 'dms_scheme_claims') {
          if (this.dms_drop_dwn_s) {
            this.renderer.removeClass(elements[i], 'hide');
          } else {
            this.renderer.addClass(elements[i], 'hide');
          }
        } else if (elements[i].id === 'dms_orders_p' || elements[i].id === 'dms_invoice_p' ||
          elements[i].id === 'dms_p_return' || elements[i].id === 'dms_p_payment' ||
          elements[i].id === 'dms_p_credit') {
          this.dms_drop_dwn_p = false;
          this.renderer.addClass(elements[i], 'hide');
        }
      }
    }

    if (event === 'dms_primary') {
      this.dmsType = 'P';
      this.dms_drop_dwn_p = !this.dms_drop_dwn_p;
      const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === 'dms_orders_p' || elements[i].id === 'dms_invoice_p' ||
          elements[i].id === 'dms_p_return' || elements[i].id === 'dms_p_payment' ||
          elements[i].id === 'dms_p_credit') {
          if (this.dms_drop_dwn_p) {
            this.renderer.removeClass(elements[i], 'hide');
          } else {
            this.renderer.addClass(elements[i], 'hide');
          }
        } else if (elements[i].id === 'dms_orders' || elements[i].id === 'dms_invoice' ||
          elements[i].id === 'dms_s_return' || elements[i].id === 'dms_s_payment' ||
          elements[i].id === 'dms_s_credit') {
          this.dms_drop_dwn_s = false;
          this.renderer.addClass(elements[i], 'hide');
        }
      }
    }
    if (event === 'primary_po') {
      this.po_drop_dwn = !this.po_drop_dwn;
      const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === 'po' || elements[i].id === 'receipt' ||
          elements[i].id === 'return') {
          if (this.po_drop_dwn) {
            this.renderer.removeClass(elements[i], 'hide');
          } else {
            this.renderer.addClass(elements[i], 'hide');
          }
        }
      }
    }
    if (event === 'expense') {
      this.exp_drop_dwn = !this.exp_drop_dwn;
      const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === 'expense_2' || elements[i].id === 'expense_3' || elements[i].id === 'expense_5' || elements[i].id === 'claims') {
          if (this.exp_drop_dwn) {
            this.renderer.removeClass(elements[i], 'hide');
          } else {
            this.renderer.addClass(elements[i], 'hide');
          }
        }
      }
    }
    if (event === 'claim') {
      this.claim_drop_dwn = !this.claim_drop_dwn;
      const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === 'viewclaim' || elements[i].id === 'raiseclaim' || elements[i].id === 'returnclaim' || elements[i].id === 'approveclaim') {
          if (this.claim_drop_dwn) {
            this.renderer.removeClass(elements[i], 'hide');
          } else {
            this.renderer.addClass(elements[i], 'hide');
          }
        }
      }
    }
    if (event === 'retail_audit') {
      this.retail_audit_drop_dwn = !this.retail_audit_drop_dwn;
      const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === 'availability' || elements[i].id === 'visibility'
          || elements[i].id === 'marketshare' || elements[i].id === 'stocks') {
          if (this.retail_audit_drop_dwn) {
            this.renderer.removeClass(elements[i], 'hide');
          } else {
            this.renderer.addClass(elements[i], 'hide');
          }
        }
      }
    }
    if (event === 'assets') {
      this.assets_drop_dwn = !this.assets_drop_dwn;
      const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === 'request' || elements[i].id === 'tagged'
          || elements[i].id === 'service') {
          if (this.assets_drop_dwn) {
            this.renderer.removeClass(elements[i], 'hide');
          } else {
            this.renderer.addClass(elements[i], 'hide');
          }
        }
      }
    }
    if (event === 'customers') {
      this.retail_drop_dwn = !this.retail_drop_dwn;
      const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === 'requestRet'
          || elements[i].id === 'taggedRet'
          || elements[i].id === 'taggedInfl'
          || elements[i].id === 'taggedProj'
          || elements[i].id === 'taggedLeads'
          || elements[i].id === 'taggedPartnerLeads'
          || elements[i].id === 'newRet'
          || elements[i].id === 'partnerAppr'
          || elements[i].id === 'prodReco'
          || elements[i].id === 'retAppr'
          || elements[i].id === 'retPlg') {
          if (this.retail_drop_dwn) {
            this.renderer.removeClass(elements[i], 'hide');
          } else {
            this.renderer.addClass(elements[i], 'hide');
          }
        }
      }
    }
    if (event === 'suppliers') {
      this.supplier_drop_dwn = !this.supplier_drop_dwn;
      const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === 'suppList'
          || elements[i].id === 'suppAddr') {
          if (this.supplier_drop_dwn) {
            this.renderer.removeClass(elements[i], 'hide');
          } else {
            this.renderer.addClass(elements[i], 'hide');
          }
        }
      }
    }
    if (event === 'influencers') {
      this.influencer_drop_dwn = !this.influencer_drop_dwn;
      const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === 'taggedInfl') {
          if (this.influencer_drop_dwn) {
            this.renderer.removeClass(elements[i], 'hide');
          } else {
            this.renderer.addClass(elements[i], 'hide');
          }
        }
      }
    }
    if (event === 'users') {
      this.users_drop_dwn = !this.users_drop_dwn;
      const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === 'team' || elements[i].id === 'users'
          || elements[i].id === 'assessment' || elements[i].id === 'kpiscorecard') {
          if (this.users_drop_dwn) {
            this.renderer.removeClass(elements[i], 'hide');
          } else {
            this.renderer.addClass(elements[i], 'hide');
          }
        }
      }
    }
    if (event === 'retail_survey') {
      this.retail_survey_drop_dwn = !this.retail_survey_drop_dwn;
      const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === 'outletkyc' || elements[i].id === 'market'
          || elements[i].id === 'consumer' || elements[i].id === 'scouting'
          || elements[i].id === 'gifts') {
          if (this.retail_survey_drop_dwn) {
            this.renderer.removeClass(elements[i], 'hide');
          } else {
            this.renderer.addClass(elements[i], 'hide');
          }
        }
      }
    }
    if (event === 'reports') {
      this.reports_drop_dwn = !this.reports_drop_dwn;
      const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === 'reports' || elements[i].id === 'downloads') {
          if (this.reports_drop_dwn) {
            this.renderer.removeClass(elements[i], 'hide');
          } else {
            this.renderer.addClass(elements[i], 'hide');
          }
        }
      }
    }
    if (event === 'beats') {
      this.beats_drop_dwn = !this.beats_drop_dwn;
      const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === 'beatplan' || elements[i].id === 'beatschedule' || elements[i].id === 'beatretailer' || elements[i].id === 'territory') {
          if (this.beats_drop_dwn) {
            this.renderer.removeClass(elements[i], 'hide');
          } else {
            this.renderer.addClass(elements[i], 'hide');
          }
        }
      }
    }
    if (event === 'products') {
      this.products_drop_dwn = !this.products_drop_dwn;
      const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === 'products' || elements[i].id === 'pricelist'
          || elements[i].id === 'schemes' || elements[i].id === 'stock') {
          if (this.products_drop_dwn) {
            this.renderer.removeClass(elements[i], 'hide');
          } else {
            this.renderer.addClass(elements[i], 'hide');
          }
        }
      }
    }
    if (event === 'schemes') {
      this.scheme_drop_dwn = !this.scheme_drop_dwn;
      const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === 'single_scheme' || elements[i].id === 'multi_scheme'
          || elements[i].id === 'margin_scheme') {
          if (this.scheme_drop_dwn) {
            this.renderer.removeClass(elements[i], 'hide');
          } else {
            this.renderer.addClass(elements[i], 'hide');
          }
        }
      }
    }
    if (event === 'task') {
      this.task_drop_dwn = !this.task_drop_dwn;
      const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === 'task_master' || elements[i].id === 'task_survey' || elements[i].id === 'task_user' || elements[i].id === 'task_planner') {
          if (this.task_drop_dwn) {
            this.renderer.removeClass(elements[i], 'hide');
          } else {
            this.renderer.addClass(elements[i], 'hide');
          }
        }
      }
    }
    if (event === 'master') {
      this.master_drop_dwn = !this.master_drop_dwn;
      const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === 'schememaster' || elements[i].id === 'pricelistmaster') {
          if (this.master_drop_dwn) {
            this.renderer.removeClass(elements[i], 'hide');
          } else {
            this.renderer.addClass(elements[i], 'hide');
          }
        }
      }
    }
    if (event === 'services') {
      this.service_drop_dwn = !this.service_drop_dwn;
      const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === 'installation' || elements[i].id === 'servicerequest') {
          if (this.service_drop_dwn) {
            this.renderer.removeClass(elements[i], 'hide');
          } else {
            this.renderer.addClass(elements[i], 'hide');
          }
        }
      }
    }
    if (event === 'gstr') {
      this.gstr_drop_dwn = !this.gstr_drop_dwn;
      const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === 'gstr1' || elements[i].id === 'gstr2' || elements[i].id === 'tcs') {
          if (this.gstr_drop_dwn) {
            this.renderer.removeClass(elements[i], 'hide');
          } else {
            this.renderer.addClass(elements[i], 'hide');
          }
        }
      }
    }
  }

  gotoPage(url) {
    localStorage.setItem('my_date', null);
    this.router.navigate([this.apiService.redirect_url + '/' + url]);
  }

  gotoSales() {

    localStorage.setItem('my_date', null);
    this.router.navigate([this.apiService.redirect_url + '/sales']);
  }

  gotoVansaleCreditNotes() {
    this.router.navigate([this.apiService.redirect_url + '/vansale_credit_notes']);
  }
  gotoDCList() {

    localStorage.setItem('my_date', null);
    this.router.navigate([this.apiService.redirect_url + '/eod-dclist']);
  }
  gotoDCTransfer() {

    localStorage.setItem('my_date', null);
    this.router.navigate([this.apiService.redirect_url + '/vantransfers']);
  }

  goToPoList() {

    localStorage.setItem('my_date', null);
    this.router.navigate([this.apiService.redirect_url + '/pro_dc_list']);
  }

  goToDashboard() {

    localStorage.setItem('my_date', null);
    // if (localStorage.getItem('board_gt_manager') === 'true' || localStorage.getItem('board_diageo_manager') === 'true' || localStorage.getItem('board_mt_manager') === 'true') {
    //this.router.navigate([this.apiService.redirect_url + '/boards/board_one']);
    //this.router.navigate([this.apiService.redirect_url + '/dash']);
    // } else {
    //   this.router.navigate([this.apiService.redirect_url + '/dashboard']);
    // }

    if (this.sharedService.role_features) {
      if (this.sharedService.ACLcheck('feature_custom_dashboard')) {
        this.router.navigate([this.apiService.redirect_url + '/dash']);
      } else {
        this.router.navigate([this.apiService.redirect_url + '/boards/board_one']);
      }
    } else {
      this.router.navigate([this.apiService.redirect_url + '/boards/board_one']);
    }
  }

  gotoMTSales() {

    localStorage.setItem('my_date', null);
    this.router.navigate([this.apiService.redirect_url + '/mtsalesdc']);
  }

  gotoPO() {

    localStorage.setItem('my_date', null);
    this.router.navigate([this.apiService.redirect_url + '/po']);
  }


  goToReportLogs() {
    this.router.navigate([this.apiService.redirect_url + 'logs']);
  }

  goToDash() {

    localStorage.setItem('my_date', null);
    this.router.navigate([this.apiService.redirect_url + 'cd']);
  }

  gotoPO2() {

    localStorage.setItem('my_date', null);
    this.router.navigate([this.apiService.redirect_url + '/po_po']);
  }

  gotoReceipts() {

    localStorage.setItem('my_date', null);
    this.router.navigate([this.apiService.redirect_url + '/receipts']);
  }

  gotoReturns() {

    localStorage.setItem('my_date', null);
    this.router.navigate([this.apiService.redirect_url + '/returns']);
  }

  gotoOrders() {
    this.sharedService.setDmsParams();
    localStorage.setItem('my_dates', null);
    localStorage.setItem('order_status', null);
    localStorage.setItem('dms_type', 'S');
    localStorage.setItem('dms_order_type', 'order');
    this.router.navigate([this.apiService.redirect_url + '/orders'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {type: 'S'}
      });
  }

  gotoDMS(route, menutag) {
    this.router.navigate([this.apiService.redirect_url + '/dms/' + route],
      {
        relativeTo: this.activatedRoute
      });
    this.changeActive(menutag);
  }

  gotoVansales(route, menutag) {
    this.router.navigate([this.apiService.redirect_url + '/vansales/' + route],
      {
        relativeTo: this.activatedRoute
      });
    this.changeActive(menutag);
  }

  gotoV2Payments(route, menutag) {
    this.router.navigate([this.apiService.redirect_url + '/dms/' + route],
      {
        relativeTo: this.activatedRoute
      });
    this.changeActive(menutag);
  }


  gotoOrdersP() {
    this.sharedService.setDmsParams();
    localStorage.setItem('my_dates', null);
    localStorage.setItem('order_status', null);
    localStorage.setItem('dms_type', 'P');
    localStorage.setItem('dms_order_type', 'order');
    this.router.navigate([this.apiService.redirect_url + '/orders'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {type: 'P'}
      });
  }

  gotoInvoicesP() {
    this.sharedService.setDmsParams();
    localStorage.setItem('my_dates', null);
    localStorage.setItem('invoice_status', null);
    localStorage.setItem('d_status_p', null);
    localStorage.setItem('dms_type', 'P');
    localStorage.setItem('dms_order_type', 'order');
    this.router.navigate([this.apiService.redirect_url + '/order_invoices'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {type: 'P'}
      });
  }

  gotoInvoices() {
    this.sharedService.setDmsParams();
    localStorage.setItem('my_dates', null);
    localStorage.setItem('invoice_status', null);
    localStorage.setItem('d_status', null);
    localStorage.setItem('dms_type', 'S');
    localStorage.setItem('dms_order_type', 'order');
    this.router.navigate([this.apiService.redirect_url + '/order_invoices'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {type: 'S'}
      });
  }

  gotoSecondaryReturnInvoices() {
    this.router.navigate([this.apiService.redirect_url + '/return_invoice_list'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {type: 'S'}
      });
  }

  gotoRefund() {
    this.sharedService.setDmsParams();
    localStorage.setItem('my_dates', null);
    localStorage.setItem('dms_order_type', 'return');
    localStorage.setItem('dms_type', 'S');
    this.router.navigate([this.apiService.redirect_url + '/order_invoices'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {type: 'S', order_type: 'return'}
      });
  }

  gotoRefundInv() {
    this.sharedService.setDmsParams();
    localStorage.setItem('my_dates', null);
    localStorage.setItem('dms_order_type', 'return');
    localStorage.setItem('dms_type', 'P');
    this.router.navigate([this.apiService.redirect_url + '/order_invoices'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {type: 'P', order_type: 'return'}
      });
  }

  gotoExpense() {
    this.router.navigate([this.apiService.redirect_url + '/expense']);
  }

  goToCustomers() {
    this.router.navigate([this.apiService.redirect_url + '/retailer_list']);
  }

  gotoStocks() {
    this.router.navigate([this.apiService.redirect_url + '/livestockreport']);
  }

  gotoPayments(type) {
    this.sharedService.dmsParams.dmsType = type;
    this.router.navigate([this.apiService.redirect_url + '/dms_payments']);
  }

  gotoCredits(type) {
    this.sharedService.dmsParams.dmsType = type;
    this.router.navigate([this.apiService.redirect_url + '/credit_notes']);
  }

  gotoDeliveryPlan(type) {
    this.sharedService.dmsParams.dmsType = type;
    this.router.navigate([this.apiService.redirect_url + '/delivery_plan']);
  }

  gotoMyExpense() {
    this.router.navigate([this.apiService.redirect_url + "/expense/my_expense"]);
  }

  getExpenseToSettle() {
    this.router.navigate([this.apiService.redirect_url + "/expense/settle_expense"]);
  }

  gotoClaims() {
    this.router.navigate([this.apiService.redirect_url + "/claims/list"]);
  }

  gotoRaiseClaims() {
    this.router.navigate([this.apiService.redirect_url + "/claims/raise"]);
  }

  gotoReturnClaims() {
    this.router.navigate([this.apiService.redirect_url + "/claims/returns"]);
  }

  gotoApproveClaims() {
    this.router.navigate([this.apiService.redirect_url + "/claims/approve"]);
  }

  goToTasks() {
    this.router.navigate([this.apiService.redirect_url + '/task']);
  }

  goToTaskPlanner() {
    this.router.navigate([this.apiService.redirect_url + '/task_planner']);
  }

  goToTaskSurvey() {
    this.router.navigate([this.apiService.redirect_url + '/task/survey']);
  }

  goToTaskUsers() {
    this.router.navigate([this.apiService.redirect_url + '/user_tasks']);
  }


  goToRetailAudit() {
    this.router.navigate([this.apiService.redirect_url + '/retail_audit']);
  }

  goToDistributorAudit() {
    this.router.navigate([this.apiService.redirect_url + '/distributor_audit']);
  }

  goToRetailAuditAvailability() {
    this.router.navigate([this.apiService.redirect_url + '/retail_audit/availability']);
  }

  goToRetailAuditVisibility() {
    this.router.navigate([this.apiService.redirect_url + '/retail_audit/visibility']);
  }

  goToRetailAuditMarketShare() {
    this.router.navigate([this.apiService.redirect_url + '/retail_audit/marketshare']);
  }

  goToRetailAuditStocks() {
    this.router.navigate([this.apiService.redirect_url + '/retail_audit/stocks']);
  }

  goToRetailAssetRequest() {
    this.router.navigate([this.apiService.redirect_url + '/assets/retail_request']);
  }
  goToAssetServiceTicket() {
    this.router.navigate([this.apiService.redirect_url + '/assets/service_ticket']);
  }
  goToAssetRequest() {
    this.router.navigate([this.apiService.redirect_url + '/assets/request']);
  }

  goToAssetTagged() {
    this.router.navigate([this.apiService.redirect_url + '/assets/tagged']);
  }

  goToServiceRequest() {
    this.router.navigate([this.apiService.redirect_url + '/service/request']);
  }
  goToSupplier() {
    this.router.navigate([this.apiService.redirect_url + '/suppliers/list']);
  }

  goToRetailer() {
    this.router.navigate([this.apiService.redirect_url + '/customers/retailers']);
  }

  goToInfluencer() {
    this.router.navigate([this.apiService.redirect_url + '/customers/influencers']);
  }

  goToProjects() {
    this.router.navigate([this.apiService.redirect_url + '/customers/projects']);
  }

  goToLeads() {
    this.router.navigate([this.apiService.redirect_url + '/customers/leads']);
  }

  goToPartnerLeads() {
    this.router.navigate([this.apiService.redirect_url + '/customers/partner_leads']);
  }

  goToNewRetailer() {
    this.router.navigate([this.apiService.redirect_url + '/customers/new_outlet']);
  }

  goToChangeReq() {
    this.router.navigate([this.apiService.redirect_url + '/customers/request']);
  }

  goToOutletProductReco() {
    this.router.navigate([this.apiService.redirect_url + '/customers/outlet_product_recommendation']);
  }

  goToPartnerApprove() {
    this.router.navigate([this.apiService.redirect_url + '/customers/partner_approve']);
  }

  goToRetailerApprove() {
    this.router.navigate([this.apiService.redirect_url + '/customers/retailer_approve']);
  }
  goToPlanogramList() {
    this.router.navigate([this.apiService.redirect_url + '/customers/planogram']);
  }

  goToAssetService() {
    this.router.navigate([this.apiService.redirect_url + '/assets/service']);
  }

  goToUsers() {
    this.router.navigate([this.apiService.redirect_url + '/users']);
  }

  goToUserTeam() {
    this.router.navigate([this.apiService.redirect_url + '/users/team']);
  }

  goToProducts() {
    this.router.navigate([this.apiService.redirect_url + '/products']);
  }

  goToPricelist() {
    this.router.navigate([this.apiService.redirect_url + '/products/pricelist']);
  }

  goToSchemes() {
    this.router.navigate([this.apiService.redirect_url + '/products/schemes']);
  }

  goToStock() {
    this.router.navigate([this.apiService.redirect_url + '/products/stock']);
  }

  goToUserAssessment() {
    this.router.navigate([this.apiService.redirect_url + '/users/assessment']);
  }

  goToUserKPIScoreCard() {
    this.router.navigate([this.apiService.redirect_url + '/users/kpiscorecard']);
  }

  goToRetailSurveyMarket(menutag: string) {
    this.router.navigate([this.apiService.redirect_url + '/retail_survey/market_intelligence']);
    this.changeActive(menutag);
  }

  goToRetailSurveyKYC() {
    this.router.navigate([this.apiService.redirect_url + '/retail_survey/kyc']);
  }

  goToUserSurvey() {
    this.router.navigate([this.apiService.redirect_url + '/user_survey/user_survey_list']);
  }

  gotoReports() {
    this.router.navigate([this.apiService.redirect_url + '/reports']);
  }

  gotoDownloads() {
    this.router.navigate([this.apiService.redirect_url + 'logs']);
  }

  goToBeatPlan(menutag: string) {
    this.router.navigate([this.apiService.redirect_url + '/beats/beat_plan_list']);
    this.changeActive(menutag);
  }


  goToBeatSchedule() {
    this.router.navigate([this.apiService.redirect_url + '/beats/beat_schedule']);
  }

  goToBeatRetailer() {
    this.router.navigate([this.apiService.redirect_url + '/beats/beat_retailer']);
  }

  goToTerritory() {
    this.router.navigate([this.apiService.redirect_url + '/beats/territory']);
  }

  goToURL(url: string){
    this.router.navigate([this.apiService.redirect_url + url]);
  }

  changeActive(event: string) {
    // console.log("changeActive");
    // console.log(this.collapse_disabled);

    const collapsedisabled = localStorage.getItem('collapse_disabled');
    if (collapsedisabled && collapsedisabled === 'true') {
      this.collapse_disabled = true;
    } else {
      this.collapse_disabled = false;
    }
    if (this.collapse_disabled) {
      this.siderCollapse();
    }
    const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
    const insideThis = this;
    if (elements.length === 0) {
      setTimeout(function() {
        const elements1 = insideThis.elRef.nativeElement.querySelectorAll('.sidebar ul li');
        for (let i = 0; i < elements1.length; i++) {
          if (elements1[i] === event) {
            insideThis.renderer.addClass(elements1[i], 'active');
          } else {
            insideThis.renderer.removeClass(elements1[i], 'active');
          }
        }
      }, 100);
    } else {
      for (let i = 0; i < elements.length; i++) {
        if (event === 'dms_orders' || event === 'dms_orders_p' ||
          event === 'dms_invoice' || event === 'dms_invoice_p' || event === 'dms_s_return' || event === 'dms_sec_return_inv' ||
          event === 'dms_p_return') {
          if (elements[i].id === event) {
            this.renderer.addClass(elements[i], 'active');
          } else {
            this.renderer.removeClass(elements[i], 'active');
          }
        } else {
          if (elements[i].id === event) {
            this.renderer.addClass(elements[i], 'active');
          } else {
            this.renderer.removeClass(elements[i], 'active');
          }
        }
      }
    }
  }

  siderCollapse() {
    console.log(this.collapse_disabled);
    this.collapse_disabled = !this.collapse_disabled;
    localStorage.setItem('collapse_disabled', this.collapse_disabled.toString());
    this.renderer[this.collapse_disabled ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.sidebar'), 'active');
    const elements = this.elRef.nativeElement.querySelectorAll('.sidebar ul li label');
    const inside_collapse = this.collapse_disabled;
    const inside_renderer = this.renderer;
    if (this.collapse_disabled) {
      setTimeout(function() {
        for (let i = 0; i < elements.length; i++) {
          inside_renderer[inside_collapse ? 'removeClass' : 'addClass'](elements[i], 'hide');
        }
      }, 300);
    } else {
      for (let i = 0; i < elements.length; i++) {
        this.renderer[this.collapse_disabled ? 'removeClass' : 'addClass'](elements[i], 'hide');
      }
    }
    //this.renderer[this.collapse_disabled ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }
}
