import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { GridOptions } from "../../../node_modules/@ag-grid-enterprise/all-modules";
import { ApiService} from "../_services/api.service";
import { Router, ActivatedRoute  } from '@angular/router';
import { ColorCellRenderer } from "../colorCell.component";
import { DatePipe } from '@angular/common';
import { LoadingstateComponent} from "../components/loadingstate/loadingstate.component"
import { ReportUrlComponent} from "../reportUrl.component";
import {ApproveClickRenderer} from "../approveClick.component";

@Component({
  selector: 'app-report-log',
  templateUrl: './report-log.component.html',
  styleUrls: ['./report-log.component.scss']
})
export class ReportLogComponent implements OnInit {

  user;
  res_data;
  user_data = {
    "start_date":null,
    "access_token": null,
    "url": null,
    "end_date": null,  };

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public report_flag;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef:ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe) {

    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.user_data.start_date = this.myDate[0];
    this.user_data.end_date = this.myDate[1];
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');

    let log_params = JSON.parse(localStorage.getItem('log_params'));
    if(log_params)
    {
      if (log_params.start_date) this.myDate[0] = log_params.start_date;
      if (log_params.last_date) this.myDate[1] = log_params.last_date;
    }


    this.user_data.start_date = this.myDate[0];
    this.user_data.end_date = this.myDate[1];


    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.reportlog();
    this.reportlog();

    this.gridOptions = { rowHeight: 40,
      rowStyle: { 'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left' },
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
          { statusPanel: 'agTotalRowCountComponent', align: 'center' },
          { statusPanel: 'agFilteredRowCountComponent' },
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
    } as GridOptions;
  }

  reportlog() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    let rowdata = [];
    let datePipe = this.datePipe;

    console.log(this.user_data);
    this.res_data = this.apiService.reportLog(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status == 200) && (res['results'].data.length > 0)) {
          res['results'].data.forEach(function (data) {

            var pushData = {
              "Name": data.file_name,
              "Start Date": data.start_date,
              "End Date":data.last_date,
              "Created by": data.user,
              "Comments": data.comments,
              "Status": data.status,
              "File Path": data.file_path
            };

            rowdata.push(pushData);
          });

          this.data = rowdata[0];
          this.rowData = rowdata;

          for (var key in this.data) {
            if (this.data.hasOwnProperty(key)) {

              if (key == 'File Path') {
                this.columnDefs.push({
                  headerName: key,     headerClass: 'myagheader',field: key, sortable: true, filter: true, lockPosition: true, resizable: true,width:150,
                  cellRenderer: "reportURlRenderer",
                });
              }else if(key=="Name") {
                this.columnDefs.push({
                    headerName: key,
                    headerClass: 'myagheader',
                    field: key,
                    sortable: true,
                    filter: true,
                    lockPosition: true,
                    resizable: true,
                    width: 400,
                  }
                )
              }else if (key == 'Status') {
                this.columnDefs.push({
                  headerName: key,     headerClass: 'myagheader',field: key, sortable: true, filter: true, lockPosition: true, resizable: true,width:150,
                  cellStyle: function (params) {
                   /* if (params.value != null && params.value != undefined) {
                      if (params.value == 'progress') {
                        return {'background-color': 'SkyBlue'};
                      }else if (params.value == 'errored') {
                        return {'background-color': 'LightCoral'};
                      }else if (params.value == 'complete') {
                        return {'background-color': 'MediumSeaGreen'};
                      }
                    }*/
                    if (params.value != null && params.value != undefined) {
                      if (params.value == 'progress') {
                        return {'color': '#F6A82C'};
                      }else if (params.value == 'errored') {
                        return {'color': '#E24260'};
                      }else if (params.value == 'complete') {
                        return {'color': '#379862'};
                      }
                    }
                  },
                  cellRenderer: function (params) {
                    var displayElem = '<span class="ml-1 status">'+ params.value + '</span>';
                    return displayElem;
                  }
                });
              }else {
                this.columnDefs.push({
                  headerName: key,     headerClass: 'myagheader',field: key, sortable: true, filter: true, lockPosition: true, resizable: true,width:150,
                });
              }
            }
          }
          this.frameworkComponents = {
            reportURlRenderer: ReportUrlComponent
          };
          this.loaded = true;

        }else {
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    //console.log(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");

   /* const sortModel = [
      {colId: 'Date', sort: 'desc'}
    ];

    this.gridApi.setSortModel(sortModel);*/
    //console.log(this.rowData);

    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          this.gridApi.sizeColumnsToFit();
        } else {
          this.gridColumnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    // this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {

    /*this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable == "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');*/
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {

    this.user_data.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.reportlog();
  }
}
