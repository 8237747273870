import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {GridOptions} from '../../../node_modules/@ag-grid-enterprise/all-modules';
import {ApiService} from '../_services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {SharedService} from '../_services/shared_service';
import {environment} from '../../environments/environment';
import {ReportMasterList} from './ReportMasterList';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  public columnDefs = [];
  public searchValue;
  public frameworkComponents;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public report_flag;
  public show_dcReport = false;
  public show_teamReport = false;
  public show_userReport = false;
  public show_loadinReport = false;
  public show_pricelistReport = false;
  public show_prodReport = false;
  public show_returnReport = false;
  public show_growthReport = false;
  public show_outletReport = false;
  public show_liveStockReport = false;
  public show_vanReport = false;
  public show_schemeReport = false;
  public show_cancelReport = false;
  public show_beatReport = false;
  public show_tiReport = false;
  public show_outAggReport = false;
  public show_outProdReport = false;
  public show_btl_report = false;
  public show_dailyReport = false;
  public show_stockReport = false;
  public show_einvoiceReport = false;
  public show_retailers = false;
  public show_payment_ageing = false;
  public show_outlet_overdue = false;
  public show_cheque_approval = false;
  public show_monthly_attendance_report = false;
  public show_attendance_report = false;
  public all_reports = [];
  public showAll = false;
  public reportsList: any;
  public reportCategories: any = [];
  private gridApi;
  private gridColumnApi;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  private rowHeight;

  // authorizedReports = [];

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              private sharedService: SharedService
  ) {

    this.report_flag = this.route.snapshot.params["report_flag"];
    this.show_dcReport = (localStorage.getItem('dc_report') === 'true');
    this.show_teamReport = (localStorage.getItem('team_report') === 'true');
    this.show_userReport = (localStorage.getItem('user_report') === 'true');
    this.show_prodReport = (localStorage.getItem('prod_report') === 'true');
    this.show_loadinReport = (localStorage.getItem('loadin_report') === 'true');
    this.show_vanReport = (localStorage.getItem('van_report') === 'true');
    this.show_cancelReport = (localStorage.getItem('cancel_report') === 'true');
    this.show_schemeReport = (localStorage.getItem('scheme_report') === 'true');
    this.show_beatReport = (localStorage.getItem('beat_report') === 'true');
    this.show_tiReport = (localStorage.getItem('ti_report') === 'true');
    this.show_btl_report = (localStorage.getItem('btl_report') === 'true');
    this.show_outAggReport = (localStorage.getItem('outlet_agg_report') === 'true');
    this.show_outProdReport = (localStorage.getItem('outlet_prod_report') === 'true');
    this.show_pricelistReport = (localStorage.getItem('pricelist_report') === 'true');
    this.show_returnReport = (localStorage.getItem('return_report') === 'true');
    this.show_growthReport = (localStorage.getItem('growth_report') === 'true');
    this.show_outletReport = (localStorage.getItem('outlet_report') === 'true');
    this.show_liveStockReport = (localStorage.getItem('livestock_report') === 'true');
    this.show_dailyReport = (localStorage.getItem('daily_collection') === 'true');
    this.show_stockReport = (localStorage.getItem('stockhand_module') === 'true');
    this.show_einvoiceReport = (localStorage.getItem('einvoice_report_module') === 'true');
    this.show_retailers = (localStorage.getItem('dms_module') === 'true');
    this.show_payment_ageing = (localStorage.getItem('payment_ageing') === 'true');
    this.show_outlet_overdue = (localStorage.getItem('outlet_overdue') === 'true');
    this.show_cheque_approval = (localStorage.getItem('cheque_approval') === 'true');
    this.show_monthly_attendance_report = (localStorage.getItem('monthly_attendance_report') === 'true');
    this.show_attendance_report = (localStorage.getItem('report_attendance') === 'true');

    // this.reportsList = JSON.parse(localStorage.getItem('reports_list'));


    this.prepare_report_list();

    this.all_reports = [
      {"ID": 1, "NAME": "DC Sales", "CODE": "DCS", "enable": this.show_dcReport},
      {"ID": 2, "NAME": "Collection Report", "CODE": "COLL", "enable": this.show_userReport},
      {"ID": 3, "NAME": "Loadout Report", "CODE": "LDR", "enable": this.show_loadinReport},
      {"ID": 4, "NAME": "Pricelist Report", "CODE": "PRS", "enable": this.show_pricelistReport},
      {"ID": 5, "NAME": "Settlement Sales", "CODE": "SSR", "enable": this.show_teamReport},
      {"ID": 6, "NAME": "Product Sales", "CODE": "PSR", "enable": this.show_prodReport},
      {"ID": 7, "NAME": "Sales Return", "CODE": "SRR", "enable": this.show_returnReport},
      {"ID": 8, "NAME": "Growth Report", "CODE": "GRR", "enable": this.show_growthReport},
      {"ID": 9, "NAME": "Outlet Dump Report", "CODE": "ODR", "enable": this.show_outletReport}
    ];

    this.report();

    this.gridOptions = <GridOptions> {
      rowHeight: 75,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left', 'padding': '20px 0px'},
      rowSelection: 'single',
      onRowSelected: params => {
        if (params.data.ID === 1) {
          this.router.navigate([this.apiService.redirect_url + '/rawsalereport']);
        } else if (params.data.ID === 2) {
          this.router.navigate([this.apiService.redirect_url + '/usercollectionreport']);
        } else if (params.data.ID === 3) {
          this.router.navigate([this.apiService.redirect_url + '/loadinreport']);
        } else if (params.data.ID === 4) {
          this.router.navigate([this.apiService.redirect_url + '/pricelistreport']);
        } else if (params.data.ID === 5) {
          this.router.navigate([this.apiService.redirect_url + '/settlesalereport']);
        } else if (params.data.ID === 6) {
          this.router.navigate([this.apiService.redirect_url + '/prodcollectionreport']);
        } else if (params.data.ID === 7) {
          this.router.navigate([this.apiService.redirect_url + '/salereturnreport']);
        } else if (params.data.ID === 8) {
          this.router.navigate([this.apiService.redirect_url + '/growthreport']);
        }

      }
    } as GridOptions;

  }

  prepare_report_list() {
    const webModuleIds = JSON.parse(localStorage.getItem('role_features')).web_module_ids;
    console.log(webModuleIds);
    if (!environment.production) {
      // This portion is to be executed ONLY in DEV environments for local testing
      // webModuleIds.push({name: 'report_beat_performance'});
      // webModuleIds.push({name: 'report_single_margin_bill_scheme'});
      // webModuleIds.push({name: 'report_user_performance_summary'});
      // webModuleIds.push({name: 'report_beat_activity_summary'});
      // webModuleIds.push({name: 'report_outlet_sales'});
      // webModuleIds.push({name: 'report_retail_stock_range'});
    }

    this.reportsList = [];
    webModuleIds.forEach(feature => {
      const reortItems = ReportMasterList.filter(d => d.role_feature === feature.name);
      if (reortItems) {
        this.reportsList.push.apply(this.reportsList, reortItems);

      }
    });

    /*  this.reportsList.sort(function(a, b){
        var nameA=a.category.toLowerCase(), nameB=b.category.toLowerCase();
        if (nameA < nameB) //sort string ascending
          return -1;
        if (nameA > nameB)
          return 1;
        return 0; //default return value (no sorting)
      });*/

    const ordering = [
      'compliance',
      'sales',
      'stock',
      'finance',
      'targets',
      'productivity',
      'retail audit',
      'kpi',
      'survey',
      'task',
      'leads',
      'multi instance',
      'scheme',
      'master'
    ];

    this.reportsList.sort((a, b) => ordering.indexOf(a.category.toLowerCase()) - ordering.indexOf(b.category.toLowerCase()));

    const reportCateg = this.reportsList.map(p => p.category)
      .filter((type, index, arr) => arr.indexOf(type) === index);

    for (const key in reportCateg) {
      this.reportCategories.push({category: reportCateg[key], show: false});
    }
    console.log(this.reportsList);
  }

  cardReportClick(route) {
    this.router.navigate([route]);
  }

  goToReport(id) {
    if (id === '1') {
      this.router.navigate([this.apiService.redirect_url + '/rawsalereport']);
    } else if (id === '2') {
      this.router.navigate([this.apiService.redirect_url + '/usercollectionreport']);
    } else if (id === '3') {
      this.router.navigate([this.apiService.redirect_url + '/loadinreport']);
    } else if (id === '4') {
      this.router.navigate([this.apiService.redirect_url + '/pricelistreport']);
    } else if (id === '5') {
      this.router.navigate([this.apiService.redirect_url + '/settlesalereport']);
    } else if (id === '6') {
      this.router.navigate([this.apiService.redirect_url + '/prodcollectionreport']);
    } else if (id === '7') {
      this.router.navigate([this.apiService.redirect_url + '/salereturnreport']);
    } else if (id === '8') {
      this.router.navigate([this.apiService.redirect_url + '/growthreport']);
    } else if (id === '9') {
      this.router.navigate([this.apiService.redirect_url + '/outletdumpreport']);
    } else if (id === '10') {
      this.router.navigate([this.apiService.redirect_url + '/livestockreport']);
    } else if (id === '11') {
      this.router.navigate([this.apiService.redirect_url + '/vanreport']);
    } else if (id === '12') {
      this.router.navigate([this.apiService.redirect_url + '/schemereport']);
    } else if (id === '13') {
      this.router.navigate([this.apiService.redirect_url + '/cancelreport']);
    } else if (id === '14') {
      this.router.navigate([this.apiService.redirect_url + '/beatreport']);
    } else if (id === '15') {
      this.router.navigate([this.apiService.redirect_url + '/tireport']);
    } else if (id === '16') {
      this.router.navigate([this.apiService.redirect_url + '/outaggreport']);
    } else if (id === '17') {
      this.router.navigate([this.apiService.redirect_url + '/outaggprodreport']);
    } else if (id === '18') {
      this.router.navigate([this.apiService.redirect_url + '/btlreport']);
    } else if (id === '19') {
      this.router.navigate([this.apiService.redirect_url + '/dailycollection']);
    } else if (id === '20') {
      this.router.navigate([this.apiService.redirect_url + '/stockinhand']);
    } else if (id === '21') {
      this.router.navigate([this.apiService.redirect_url + '/einvoice']);
    } else if (id === '22') {
      this.router.navigate([this.apiService.redirect_url + '/retailer_list']);
    } else if (id === '23') {
      this.router.navigate([this.apiService.redirect_url + '/order_aging_report']);
    } else if (id === '24') {
      this.router.navigate([this.apiService.redirect_url + '/outlet_overdue_report']);
    } else if (id === '25') {
      this.router.navigate([this.apiService.redirect_url + '/payment_approval_report']);
    } else if (id === '26') {
      this.router.navigate([this.apiService.redirect_url + '/reports/monthly_attendance']);
    } else if (id === '27') {
      this.router.navigate([this.apiService.redirect_url + '/reports/beat-performance']);
    } else if (id === '28') {
      this.router.navigate([this.apiService.redirect_url + '/reports/attendance']);
    } else if (id === '29') {
      this.router.navigate([this.apiService.redirect_url + '/market_intelligence_report']);
    } else if (id === '30') {
      this.router.navigate([this.apiService.redirect_url + '/market_intelligence_summary_report']);
    } else if (id === '31') {
      this.router.navigate([this.apiService.redirect_url + '/retail_audit_report']);
    } else if (id === '32') {
      this.router.navigate([this.apiService.redirect_url + '/retail_audit_distribution_report']);
    }
  }

  report() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    const rowdata = [];


    this.all_reports.forEach(each_report => {

      if (each_report.enable) {
        rowdata.push(each_report);
      }

    });


    this.data = rowdata[0];
    this.rowData = rowdata;

    for (const key in this.data) {
      if (this.data.hasOwnProperty(key)) {
        if (key === 'enable') {
          this.columnDefs.push({headerName: key, field: key, sortable: true, filter: false, lockPosition: true, hide: true});
        } else {
          this.columnDefs.push({headerName: key, field: key, sortable: true, filter: false, lockPosition: true, resizable: true});
        }

      }
    }
    this.loaded = true;
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      params.columnApi.autoSizeColumns();
    }

    // this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid');
  }

  ngOnInit() {
    /* this.sharedService.setHeader('Reports');
     this.collapse_disable = localStorage.getItem('collapse_disabled');
     this.renderer[this.collapse_disable === 'true'
       ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');*/

    this.sharedService.setHeader('Reports');

  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  filterReport(category) {
    return this.reportsList.filter(reports => reports.category === category);
  }

  gotoDownloads() {
    this.router.navigate([this.apiService.redirect_url + 'logs']);
  }

  showReports() {
    this.showAll = !this.showAll;
    for (var key in this.reportCategories) {
      this.reportCategories[key].show = this.showAll;
    }
  }
}
