import {Component, OnInit} from "@angular/core";
import {TabPageBase} from "../pagebase/tabpagebase";

@Component({
  selector: 'app-gstr1-list',
  templateUrl: '../pagebase/tabpagebase.html',
  styleUrls: ['../pagebase/pagebase.scss']
})

export class TCSListComponent extends TabPageBase implements OnInit {

  ngOnInit(): void {
    this.sharedService.setHeader('TCS');
    this.showUpload = false;
    this.tabList = [{id: 'b2b', itemName: 'B2B'}, {id: 'b2cl', itemName: 'B2CL'}, {id: 'b2cs', itemName: 'B2CS'}, {id: 'hsn', itemName: 'HSN'},
      {id: 'cdnr', itemName: 'CDNR'}, {id: 'cdna', itemName: 'CDNA'}];
    this.checkAndLoadPreviousSate();
    this.showCustomFilter = false;
    this.showActionButton = false;
    this.showDate = true;
  }
}
