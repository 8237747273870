<div class="common-wrapper" xmlns="http://www.w3.org/1999/html">
  <div class="common-container">
    <div class="main-wrapper">
      <div>
        <div class="col-md-12 col-12 nopadding pl-0">
          <div class="eod-list-card">
            <!--<span style="display: block; font-weight: 400; font-size: 18px;margin-bottom: 5px;margin-left: 15px;">{{c}}</span>-->
            <div class="row">
              <img *ngIf="image && image != ''" [src]="image" style="width:150px;height:150px; cursor: pointer; margin-right: 15px;" (click)="showCustomerImage()" >
              <img *ngIf="!image || image == ''" [src]="'./../../../../assets/no-image-icon.png'" style="width:180px;height:180px; margin-right: 15px;" >
              <div class="eod-list-card-heading" style="width : 80%">
                <h3>{{customerDetails.name}}</h3>
                <span>Code : {{customerDetails.code}}</span><br/>
                <span>ID : {{customerDetails.id}}</span>
                <div class="col-md-12 col-12 nopadding pl-0" style="margin-top: 20px;">
                  <div class="col-md-3 pad0">
                    <label class="labelheading">Mobile</label>
                    <p class="spandesc" style="margin-bottom: 0px;">{{customerDetails.mobile}}</p>
                    <div *ngIf="customerDetails.otp_verified">
                      <span style="color:#379862;font-size: 9px; font-weight: 500;">OTP Verified</span>
                      <span style="margin-left:5px;display:inline;"> <i class="fas fa-check-circle " style="color:#379862"></i></span>
                    </div>
                  </div>
                  <div class="col-md-3 pad0">
                    <label class="labelheading">Classification</label>
                    <p class="spandesc">{{customerDetails.class_type}}</p>
                  </div>
                  <div class="col-md-3 pad0">
                    <label class="labelheading">Retailer Type</label>
                    <p class="spandesc">{{customerDetails.type}}</p>
                  </div>
                  <div class="col-md-3 pad0">
                    <label class="labelheading">Pricelist</label>
                    <p class="spandesc">{{customerDetails.pricelist}}</p>
                  </div>
                </div>
              </div>
              <div class="button-row">

              </div>
              <div style="position: absolute;right: 30px;">
                <div class="button-row">
                  <button *ngIf="showCreateCreditNote"
                    class="btn blue-btn" style="margin-right: 20px"
                    (click)="action_credit_note()"
                  >Credit Note
                  </button>
                  <button *ngIf="showManageTCS"
                          class="btn blue-btn" style="margin-right: 20px"
                          (click)="action_manage_tcs()"
                  >Manage TCS
                  </button>
                  <button *ngIf="showCollectPayment"
                           class="btn blue-btn" style="margin-right: 20px"
                    (click)="action_pay_settle()"
                  >Payments
                  </button>
                   <span (click)="fetchPreviousData()"><i
                     [ngClass]="{'disabledspan': rowIndex === 0,
                     'enabledspan': rowIndex >0}"
                     class="fal fa-arrow-circle-left fa-2x"></i></span>
                  <span style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;">{{rowIndex + 1}}
                    /{{customerData.length}}</span>
                  <span (click)="fetchNextData()"><i
                    [ngClass]="{'disabledspan': rowIndex === customerData.length-1,
                     'enabledspan': rowIndex < customerData.length-1}"
                    class="fal fa-arrow-circle-right fa-2x"></i></span>
                </div>
              </div>
              <!--  <div style="position: absolute;right: 30px;">
                  <div *ngIf="edit" class="button-row">
                    <button
                      mat-button
                      style="margin-right:15px;"
                      class="btn teal-btn"
                    >
                      SAVE
                    </button>
                    <span>
                           <a class="btn white-btn" (click)="discardClick()">DISCARD</a>
                        </span>
                  </div>
                  <div *ngIf="!edit" class="button-row">
                        <span>
                           <a class="btn white-btn" style="margin-right:15px;" (click)="editClick()">EDIT</a>
                        </span>
                  </div>
                </div>-->
            </div>
           <!-- <p style="border-bottom: 2px solid #f3f3f3; margin-top: 10px;"></p>
            <div class="eod-list-card-desc" style="width: 100%;">
              <div class="col-md-12 col-12 nopadding pl-0">
                <div class="col-md-2 pad0">
                  <label class="labelheading">Mobile</label>
                  <p style="margin-bottom: 0px;">{{customerDetails.mobile}}</p>
                  <div *ngIf="customerDetails.otp_verified">
                    <span style="color:#379862;font-size: 9px; font-weight: 500;">OTP Verified</span>
                    <span style="margin-left:5px;display:inline;"> <i class="fas fa-check-circle " style="color:#379862"></i></span>
                  </div>
                </div>
                <div class="col-md-2 pad0">
                  <label class="labelheading">Classification</label>
                  <p class="spandesc">{{customerDetails.class_type}}</p>
                </div>
                <div class="col-md-2 pad0">
                  <label class="labelheading">Retailer Type</label>
                  <p class="spandesc">{{customerDetails.type}}</p>
                </div>
                <div class="col-md-2 pad0">
                  <label class="labelheading">Pricelist</label>
                  <p class="spandesc">{{customerDetails.pricelist}}</p>
                </div>
              </div>
            </div>-->
          </div>
        </div>

        <div class="col-md-12 col-12 nopadding pl-0">
          <div style="background: white">
            <mat-tab-group (selectedTabChange)="changeActiveTab($event)">
              <mat-tab label="Overview">
              </mat-tab>
              <mat-tab label="Gallery">
              </mat-tab>
              <mat-tab label="Sales">
              </mat-tab>
              <mat-tab label="Attributes">
              </mat-tab>
              <mat-tab label="Change Request">
              </mat-tab>
              <mat-tab label="Product Recommendation">
              </mat-tab>
              <mat-tab label="Schemes">
              </mat-tab>
              <mat-tab label="Credit Notes">
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
        <div *ngIf="activetab==='Sales'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
          <div class="col-md-7 pad0" style="padding-right: 10px;">
            <div class="eod-list-card">
              <div class="row">
                <div class="eod-list-card-heading">
                  <h3 style="font-size: 16px;">SALES TREND</h3>
                </div>
                <div style="position: absolute; right: 30px;">
                  <div class="date-pickersmall">
                    <!--   <span class="datepicker-icon"><img src="../../assets/date.png"></span>-->
                    <span><i style="   position: absolute;left: 6px;top: 6px;"
                             class="far fa-calendar-alt fa-2x"></i></span>
                    <input [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt"
                           placeholder="Date">
                    <!--  <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>-->
                    <span><i style=" position: absolute;right: 6px;top: 6px;"
                             class="fas fa-caret-down fa-2x"></i></span>
                    <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                  </div>
                </div>
              </div>
              <div class="eod-list-card-desc">
                <div *ngIf="salesDataLoaded" class="row chartcard">
                  <ngx-charts-bar-horizontal-2d
                    [view]="view1"
                    [scheme]="colorScheme"
                    [results]="categoryChartData"
                    [gradient]="gradient"
                    [xAxis]="showXAxis"
                    [yAxis]="showYAxis"
                    [xAxisTickFormatting]="formatNumber"
                    [barPadding]="1"
                    [legend]="showLegend"
                    [legendTitle]="''"
                    [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel"
                    [xAxisLabel]="xAxisLabel"
                    [yAxisLabel]="yAxisLabel"
                    [animations]="animations"
                  >
                  </ngx-charts-bar-horizontal-2d>
                  <!--    <div style="clear: both">
                        <div class="row">
                          &lt;!&ndash; <div class="input-color" style="margin-left:20px;">
                             <span class="input-color color-box" style="background-color: #F3622D"></span>
                             <span  class="input-color color-box-text" >LMTD Return</span>
                           </div>&ndash;&gt;
                          <div class="input-color">
                            <span class="input-color color-box" style="background-color: #F3622D"></span>
                            <span class="input-color color-box-text">LMTD</span>
                          </div>
                          <div class="input-color">
                            <span class="input-color color-box" style="background-color: #57B757"></span>
                            <span class="input-color color-box-text">MTD</span>
                          </div>
                        </div>
                      </div>-->
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5 pad0" style="padding-right: 10px;">
            <div class="eod-list-card">
              <div class="eod-list-card-heading">
                <h3 style="font-size: 16px;">TARGET VS ACTUAL</h3>
              </div>
              <div class="eod-list-card-desc">
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="activetab==='Gallery'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
          <!-- <div class="col-md-12 pad0" style="padding-right: 10px;">
             <div class="eod-list-card">
               <div class="eod-list-card-heading">
                 <h3 style="font-size: 16px;">GALLERY</h3>
               </div>
               <div class="eod-list-card-desc">
                 <div class="row" style="margin-top:20px;">
                   <div style="display: inline;" >
                     <ng-container *ngFor="let tile of galleryphotodata">
                       <img  [src]="tile['photo']" style="height: 150px;width:250px;margin-right: 15px;margin-bottom: 8px;"/>
                     </ng-container>
                   </div>
                 </div>
               </div>
             </div>
           </div>-->
          <div class="col-md-12 pad0" style="padding-right: 10px;">
            <div class="eod-list-card">
              <div class="eod-list-card-heading">
                <h3 style="font-size: 16px;">SHELF ANALYSIS</h3>
              </div>
              <div class="eod-list-card-desc">
                <div class="row" style="margin-top:20px;">
                  <div style="display: inline;">
                    <ng-container *ngFor="let tile of shelfGalleryData">
                      <img [src]="tile['photo']"
                           style="height: 150px;width:250px;margin-right: 15px;margin-bottom: 8px;"/>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 pad0" style="padding-right: 10px;">
            <div class="eod-list-card">
              <div class="eod-list-card-heading">
                <h3 style="font-size: 16px;">BTL ACTIVITY</h3>
              </div>
              <div class="eod-list-card-desc">
                <div class="row" style="margin-top:20px;">
                  <div style="display: inline;">
                    <ng-container *ngFor="let tile of btlGalleryData">
                      <img [src]="tile['photo']"
                           style="height: 150px;width:250px;margin-right: 15px;margin-bottom: 8px;"/>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="activetab==='Overview'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
          <div class="col-md-5 pad0" style="padding-right: 10px;">
            <div class="eod-list-card">
              <div class="eod-list-card-heading">
                <h3 style="font-size: 16px;">ADDRESS & CONTACT</h3>
              </div>
              <div class="eod-list-card-desc">
                <!-- <div class="col-md-12 col-12 nopadding pl-0">
                   <div class="col-md-6 pad0">
                     <label class="labelheading">Beat</label>
                     <p class="spandesc">{{customerDetails.beatname}}</p>
                   </div>
                   <div class="col-md-6 pad0">
                     <label class="labelheading">Email</label>
                     <p class="spandesc">{{customerDetails.email}}</p>
                   </div>
                 </div>-->
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-6 pad0">
                    <label class="labelheading">Contact Person</label>
                    <p class="spandesc">{{customerDetails.contact_name}}</p>
                  </div>
                  <div class="col-md-6 pad0">
                    <label class="labelheading">Phone</label>
                    <p class="spandesc">{{customerDetails.phone}}</p>
                  </div>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0">
                  <label class="labelheading">Address</label>
                  <p class="spandesc">{{customerDetails.address}}</p>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0" style=" margin-top: 10px;">
                  <ag-grid-angular *ngIf="dataLoadedAddr" id="myGrid4" style="height: 100%" class="ag-theme-balham"
                                   [columnDefs]="columnDefsAddr" [gridOptions]="gridOptionsAddr"
                                   (gridReady)="onGridReadyAddr($event)" [frameworkComponents]="frameworkComponents"
                                   (cellClicked)="onCellClickedAddr($event)"
                                   [context]="contextAddr">
                  </ag-grid-angular>
                </div>
              </div>
            </div>
            <div class="eod-list-card">
              <div class="eod-list-card-heading">
                <h3 style="font-size: 16px;">FINANCE</h3>
              </div>
              <div class="eod-list-card-desc">
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-6 pad0">
                    <label class="labelheading">Pricelist</label>
                    <p class="spandesc">{{customerDetails.pricelist}}</p>
                  </div>
                  <div class="col-md-6 pad0">
                    <label class="labelheading">Monthly Potential</label>
                    <p class="spandesc">{{customerDetails.potential}}</p>
                  </div>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-6 pad0">
                    <label class="labelheading">Payment Mode</label>
                    <p class="spandesc">{{customerDetails.payment_mode}}</p>
                  </div>
                  <div class="col-md-6 pad0">
                    <label class="labelheading">Credit Limit</label>
                    <p class="spandesc">{{customerDetails.credit_limit}}</p>
                  </div>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-6 pad0">
                    <label class="labelheading">Outstanding</label>
                    <p class="spandesc">{{customerDetails.outstanding}}</p>
                  </div>
                  <div class="col-md-6 pad0">
                    <label class="labelheading">Credits</label>
                    <p class="spandesc">{{customerDetails.credits}}</p>
                  </div>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-6 pad0">
                    <label class="labelheading">GSTIN</label>
                    <p class="spandesc">{{customerDetails.gst_id}}</p>
                  </div>
                  <div class="col-md-6 pad0">
                    <label class="labelheading">UPI ID</label>
                    <p class="spandesc">{{customerDetails.upi_id}}</p>
                  </div>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-6 pad0">
                    <div *ngIf="customerDetails.has_einvoice" style="background-color: #EEF6F6; padding: 5px; margin-right: 30px;">
                      <span style="color:#2D2D2D;">E-Invoice</span>
                      <span style="margin-left:5px;display:inline;float: right;"> <i class="fas fa-check-circle" style="color:#379862"></i></span>
                    </div>
                    <div *ngIf="!customerDetails.has_einvoice" style="background-color: #F2F2F2; padding: 5px; margin-right: 30px;">
                      <span style="color:#2D2D2D;">E-Invoice</span>
                      <span style="margin-left:5px;display:inline;float: right;"> <i class="fas fa-times-circle " style="color:#7D7D7D"></i></span>
                    </div>
                  </div>
                  <div class="col-md-6 pad0">
                    <div *ngIf="customerDetails.has_purchase_order" style="background-color: #EEF6F6; padding: 5px; margin-right: 30px;">
                      <span style="color:#2D2D2D;">Purchase Order</span>
                      <span style="margin-left:5px;display:inline;float: right;"> <i class="fas fa-check-circle" style="color:#379862"></i></span>
                    </div>
                    <div *ngIf="!customerDetails.has_purchase_order" style="background-color: #F2F2F2; padding: 5px; margin-right: 30px;">
                      <span style="color:#2D2D2D;">Purchase Order</span>
                      <span style="margin-left:5px;display:inline;float: right;"> <i class="fas fa-times-circle " style="color:#7D7D7D"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="eod-list-card">
              <div class="eod-list-card-heading">
                <h3 style="font-size: 16px;">Logs</h3>
              </div>
              <div class="eod-list-card-desc">
                  <div class="history-tl-container">
                    <ul class="tl">
                      <ng-container *ngFor="let log of logData">
                        <li >
                          <div class="item-title">{{log.date}}</div>
                          <div class="item-title">{{log.user_name}}</div>
                        <!--  <div class="item-detail">{{log.body}}</div>-->
                          <div  style="font-size:12px;
  overflow-wrap: break-word;width:500px;" [innerHTML]="log.body"></div>
                        </li>
                      </ng-container>
                     <!-- <li  *ngFor="let log of logData">
                        <div class="item-title">{{log.date}}</div>
                        <div class="item-detail">{{log.body}}</div>
                      </li>-->
                    </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-7 pad0">
            <div class="eod-list-card">
              <div class="eod-list-card-heading">
                <h3 style="font-size: 16px;">MAP VIEW</h3>
              </div>
              <div class="eod-list-card-desc" style="width:100%">
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-4 pad0">
                    <label class="labelheading">Region</label>
                    <p class="spandesc">{{customerDetails.region}}</p>
                  </div>
                  <div class="col-md-4 pad0">
                    <label class="labelheading">Cluster</label>
                    <p class="spandesc">{{customerDetails.cluster}}</p>
                  </div>
                  <div class="col-md-4 pad0">
                    <label class="labelheading">Territory</label>
                    <p class="spandesc">{{customerDetails.territory}}</p>
                  </div>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0" style=" margin-top: 10px;">
                  <ag-grid-angular *ngIf="dataLoadedBeat" id="myGrid5" style="height: 100%" class="ag-theme-balham"
                                   [columnDefs]="columnDefsBeat" [gridOptions]="gridOptionsBeat"
                                   (gridReady)="onGridReadyBeat($event)" [frameworkComponents]="frameworkComponents"
                                   (cellClicked)="onCellClickedBeat($event)"
                                   [context]="contextBeat">
                  </ag-grid-angular>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="map" id="map1" style="margin-top: 20px;">
                    <agm-map
                      [latitude]="latitude"
                      [longitude]="longitude"
                      [zoom]="zoom">
                      <agm-marker [latitude]="latitude" [longitude]="longitude">
                      </agm-marker>
                    </agm-map>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="activetab==='Attributes'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
          <div class="col-md-6 pad0" style="padding-right: 10px;">
            <div class="eod-list-card">
              <div class="row">
                <div class="eod-list-card-heading">
                  <h3 style="font-size: 16px;">ATTRIBUTES</h3>
                </div>
                <span *ngIf="!attributeEdit" (click)="attributeEditClick()" title="Edit"
                      style="position: absolute;right: 40px; color: #183153;"><i class="fas fa-pen edit fa-2x"
                                                                                 data-action-type="edit"></i></span>
                <span *ngIf="attributeEdit" (click)="attributeEditClick()" title="Edit"
                      style="position: absolute;right: 40px; color: #183153;"><i class="fad fa-save edit fa-2x"
                                                                                 data-action-type="edit"></i></span>
              </div>

              <div class="eod-list-card-desc" style="width:100%">
                <div *ngIf="attributeEdit"
                     style="display: inline-block;margin-top: 5px;margin-right:15px;cursor:pointer;"
                     (click)="addAttributeRow()">
                  <i class="fas fa-plus-circle"
                     style="color:#17A2B8;margin-right: 5px;margin-top:5px;font-size:16px;"></i>
                  <span style="font-size: 16px; font-weight: 400;color:#5D5D5D">Add</span>
                </div>
                <ag-grid-angular *ngIf="dataLoaded" id="myGrid1" style="height: 50vh" class="ag-theme-balham"
                                 [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                                 (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                                 (cellClicked)="onCellClicked($event)"
                                 [context]="context">
                </ag-grid-angular>
              </div>
            </div>
          </div>
          <div class="col-md-6 pad0" style="padding-right: 10px;">
            <div class="eod-list-card">
              <div class="row">
                <div class="eod-list-card-heading">
                  <h3 style="font-size: 16px;">PARAMS</h3>
                </div>
                <span *ngIf="!paramEdit" (click)="paramEditClick()" title="Edit"
                      style="position: absolute;right: 40px; color: #183153;"><i class="fas fa-pen edit fa-2x"
                                                                                 data-action-type="edit"></i></span>
                <span *ngIf="paramEdit" (click)="paramEditClick()" title="Edit"
                      style="position: absolute;right: 40px; color: #183153;"><i class="fad fa-save edit fa-2x"
                                                                                 data-action-type="edit"></i></span>
              </div>

              <div class="eod-list-card-desc" style="width:100%">
                <div *ngIf="paramEdit" style="display: inline-block;margin-top: 5px;margin-right:15px;cursor:pointer;"
                     (click)="addParamRow()">
                  <i class="fas fa-plus-circle"
                     style="color:#17A2B8;margin-right: 5px;margin-top:5px;font-size:16px;"></i>
                  <span style="font-size: 16px; font-weight: 400;color:#5D5D5D">Add</span>
                </div>
                <ag-grid-angular *ngIf="dataLoaded1" id="myGrid2" style="height: 50vh" class="ag-theme-balham"
                                 [columnDefs]="columnDefs1" [gridOptions]="gridOptions1"
                                 (gridReady)="onGridReady1($event)" [frameworkComponents]="frameworkComponents1"
                                 (cellClicked)="onCellClicked1($event)"
                                 [context]="context1">
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="activetab==='Change Request'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
          <div class="col-md-12 pad0" style="padding-right: 10px;">
            <div class="eod-list-card">
              <!-- <div class="row">
                 <div class="eod-list-card-heading">
                   <h3 style="font-size: 16px;">ATTRIBUTES</h3>
                 </div>
                 <span *ngIf="!attributeEdit" (click)="attributeEditClick()" title="Edit" style="position: absolute;right: 40px; color: #183153;"><i class="fas fa-pen edit fa-2x" data-action-type="edit"></i></span>
                 <span *ngIf="attributeEdit" (click)="attributeEditClick()" title="Edit" style="position: absolute;right: 40px; color: #183153;"><i class="fad fa-save edit fa-2x" data-action-type="edit"></i></span>
               </div>-->

              <div class="eod-list-card-desc" style="width:100%">
                <ag-grid-angular *ngIf="dataLoaded2" id="myGrid3" style="height: 50vh" class="ag-theme-balham"
                                 [columnDefs]="columnDefs2" [gridOptions]="gridOptions2"
                                 (gridReady)="onGridReady2($event)" [frameworkComponents]="frameworkComponents2"
                                 (cellClicked)="onCellClicked2($event)"
                                 [context]="context2">
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="activetab==='Product Recommendation'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
          <div class="col-md-12 pad0" style="padding-right: 10px;">
            <div class="eod-list-card">
              <!-- <div class="row">
                 <div class="eod-list-card-heading">
                   <h3 style="font-size: 16px;">ATTRIBUTES</h3>
                 </div>
                 <span *ngIf="!attributeEdit" (click)="attributeEditClick()" title="Edit" style="position: absolute;right: 40px; color: #183153;"><i class="fas fa-pen edit fa-2x" data-action-type="edit"></i></span>
                 <span *ngIf="attributeEdit" (click)="attributeEditClick()" title="Edit" style="position: absolute;right: 40px; color: #183153;"><i class="fad fa-save edit fa-2x" data-action-type="edit"></i></span>
               </div>-->

              <div class="eod-list-card-desc" style="width:100%">
                <ag-grid-angular *ngIf="dataLoaded3" id="myGrid6" style="height: 50vh" class="ag-theme-balham"
                                 [columnDefs]="columnDefs3" [gridOptions]="gridOptions3"
                                 (gridReady)="onGridReady3($event)"
                                 (cellClicked)="onCellClicked3($event)"
                                 [context]="context3">
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="activetab==='Schemes'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
          <div class="col-md-12 pad0" style="padding-right: 10px;">
            <div class="eod-list-card">
              <!-- <div class="row">
                 <div class="eod-list-card-heading">
                   <h3 style="font-size: 16px;">ATTRIBUTES</h3>
                 </div>
                 <span *ngIf="!attributeEdit" (click)="attributeEditClick()" title="Edit" style="position: absolute;right: 40px; color: #183153;"><i class="fas fa-pen edit fa-2x" data-action-type="edit"></i></span>
                 <span *ngIf="attributeEdit" (click)="attributeEditClick()" title="Edit" style="position: absolute;right: 40px; color: #183153;"><i class="fad fa-save edit fa-2x" data-action-type="edit"></i></span>
               </div>-->

              <div class="eod-list-card-desc" style="width:100%">
                <ag-grid-angular *ngIf="dataLoadedScheme" id="myGrid7" style="height: 50vh" class="ag-theme-balham"
                                 [columnDefs]="columnDefsScheme" [gridOptions]="gridOptionsScheme"
                                 (gridReady)="onGridReadyScheme($event)"
                                 [context]="contextScheme">
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="activetab==='Credit Notes'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
          <div class="col-md-12 pad0" style="padding-right: 10px;">
            <div class="eod-list-card">
              <!-- <div class="row">
                 <div class="eod-list-card-heading">
                   <h3 style="font-size: 16px;">ATTRIBUTES</h3>
                 </div>
                 <span *ngIf="!attributeEdit" (click)="attributeEditClick()" title="Edit" style="position: absolute;right: 40px; color: #183153;"><i class="fas fa-pen edit fa-2x" data-action-type="edit"></i></span>
                 <span *ngIf="attributeEdit" (click)="attributeEditClick()" title="Edit" style="position: absolute;right: 40px; color: #183153;"><i class="fad fa-save edit fa-2x" data-action-type="edit"></i></span>
               </div>-->

              <div class="eod-list-card-desc" style="width:100%">
                <ag-grid-angular *ngIf="dataLoadedCreditNote" id="myGrid8" style="height: 50vh" class="ag-theme-balham"
                                 [columnDefs]="columnDefsCreditNote" [gridOptions]="gridOptionsCreditNote"
                                 (gridReady)="onGridReadyCreditNote($event)"
                                 [context]="contextScheme">
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
