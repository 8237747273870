import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {GridOptions} from "../../../node_modules/@ag-grid-enterprise/all-modules";
import {ApiService} from "../_services/api.service";
import {Router} from '@angular/router';
import {ColorCellRenderer} from "../colorCell.component";
import {DatePipe} from '@angular/common';
import {DateTimeAdapter} from 'ng-pick-datetime';

@Component({
  selector: 'app-mtsales',
  templateUrl: './mtsales.component.html'
})
export class MtSalesComponent implements OnInit {

  user;
  res_data;
  user_data = {
    date: null,
    dc_id: null,
    access_token: null,
    url: null,
    offset: null,
    van_ids: null
  };
  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public styleGrid;
  public myDate;
  public collapse_disable;
  public min;
  public max;

  constructor(private apiService: ApiService,
              private router: Router,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              private dateTimeAdapter: DateTimeAdapter<any>) {

    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.user_data.date = localStorage.getItem('my_date');
    if (this.user_data.date === null || this.user_data.date === "null") {
      this.user_data.date = this.myDate;
      localStorage.setItem('my_date', this.myDate);
    } else {
      this.myDate = this.user_data.date;
    }
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.dc_id = JSON.parse(localStorage.getItem('user')).partner_id;
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    dateTimeAdapter.setLocale('en-IN');
    this.apiDcMtList();
    this.gridOptions = {
      rowHeight: 50,
      rowStyle: {'text-align': 'left', padding: '20px 0px'},
      rowSelection: 'single',
      suppressAggFuncInHeader: true,
      pivotMode: false,
      groupHideOpenParents: true,
      groupDefaultExpanded: 9,
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        unSortIcon: true,
      },
      autoGroupColumnDef: {
        pinned: 'left'
      },
      onRowSelected: params => {
        localStorage.setItem('mt_params', JSON.stringify(params.data));
        this.router.navigate([this.apiService.redirect_url + "/mtoutsalesdc"]);
      }
    } as GridOptions;
  }

  apiDcMtList() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    const rowdata = [];
    const van_ids = [];
    this.res_data = this.apiService.dcmtsales(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          res['results'].data.forEach(data => {
            van_ids.push(data.partner_id);
            rowdata.push({
              DC: data.dc,
              VAN: data.van,
              "TOTAL MT OUTLETS": data.mt_out,
              "ORDER VALUE": "...",
              "ORDER PLACED": "...",
              "OUTLET WITHOUT INVOICE": "...",
              "ACTIVE PO": "...",
              van_id: data.salesman_id,
              dc_id: data.dc_id,
              dist_id: data.partner_id
            });
          });
          this.data = rowdata[0];
          this.rowData = rowdata;

          for (const key in this.data) {
            if (this.data.hasOwnProperty(key)) {
              if (key === "DC") {
                this.columnDefs.push({
                  headerName: key, field: key, rowGroup: true, hide: true,
                  cellRenderer(params) {
                    return '<b>' + params.value + '</b>';
                  },
                });
              } else if (key === "dc_id" || key === "van_id" || key === "dist_id") {
                this.columnDefs.push({
                  headerName: key, field: key, unSortIcon: true, hide: true
                });
              } else {
                this.columnDefs.push({headerName: key, field: key, unSortIcon: true, aggFunc: 'sum'});
              }
            }
          }
          this.loaded = true;
          this.user_data.van_ids = van_ids;
          this.res_data = this.apiService.dcmtsales2(this.user_data);
          let updated_row;
          this.res_data.subscribe(res1 => {
            console.log(res1);
            this.rowData.forEach(each_row => {
              updated_row = res1['results'].data.filter(existed => parseInt(each_row.dist_id, 10) === parseInt(existed.partner_id, 10));
              if (updated_row.length > 0) {
                each_row['ORDER VALUE'] = updated_row[0].order_value;
                each_row['ORDER PLACED'] = updated_row[0].order_placed;
                each_row['OUTLET WITHOUT INVOICE'] = updated_row[0].out_no_inv;
                each_row['ACTIVE PO'] = updated_row[0].active_po;
              }
            });
            this.gridApi.setRowData(this.rowData);
          }, error => {
            console.log(error);
          });
          this.frameworkComponents = {
            colorCellRenderer: ColorCellRenderer
          };
        } else {
          this.preloader = false;
          this.empty_state = true;
        }
      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {
    this.min = new Date(new Date().setHours(0, 0, 0, 0));
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    this.max = new Date(today.setDate(today.getDate() + 1));
    if (new Date().getMonth() !== this.max.getMonth()) {
      this.max = this.min;
    }
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {
    this.user_data.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    localStorage.setItem('my_date', this.user_data.date);
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    this.apiDcMtList();
  }
}
