import {DetailPageBase} from "../DetailPageBase";
import {ErrorHandler} from "../../error/error-handler";
import {forkJoin} from "rxjs";
import {SchemeCalculator} from "./SchemeCalculator";
import {TaxCalculator} from "./TaxCalculator";

export abstract class DMSBasePage extends DetailPageBase {

  public CUSTOMER_CACHE = false;

  // supplierMasterList = [];
  supplierList = [];
  activeSupplierList = [];
  customerList = [];
  UOMMasterList = [];
  TaxMasterList = [];
  UOMList = [];
  schemesMasterList = [];
  applicableSchemes = [];
  plantList = [];
  userList = [];
  beatList = [];
  prodList = [];
  OPERATION_MODE = '';
  print_details = [];

  outletTypeList = [];

  selected_pack_size = 0;
  selected_pack_name = 'Pack';
  entered_pack_Qty = 0;
  entered_unit_qty = 0;
  showSave = true;

  allow_discount = true;
  allow_discount_per_unit = true;
  allow_discount_per_volume = false;
  feature_show_product_code_name = true;
  edit_discount_field = 'discount';
  cash_dis = 0;

  /* END COMMON FOR ALL ORDER/INVOICE PAGES */


  static ConvertOrderToInvoice(order, user_id, delivery_plan_id) {

    function get_mid(uid, object_id) {
        return uid + '_' +  object_id + '_' + Date.now();
    }

    const inv = {
      id: 0,
      name: ''
    };
    const todate = new Date();
    inv['order_id'] = order.id;
    inv['partner_id'] = order.partner_id;
    inv['partner_shipping_id'] = order.partner_id;
    inv['origin'] = order.name;
    inv['mid'] = get_mid(order.partner_id, user_id);

    inv['date_invoice'] = todate.toDateString();
    inv['supplier_id'] = order['distributor_id'];
    inv['journal_id'] = 5;
    inv['account_id'] = 25;
    inv['currency_id'] = 21;
    inv['tax_notes'] = {};
    inv['reference_type'] = 'none';
    inv['note'] = '';
    inv['state'] = 'sale';
    inv['d_status'] = 'new';
    inv['p_status'] = 'new';
    inv['inv_dp_id'] = delivery_plan_id;

    if (!order['type']) {
      inv['invoice_type'] = 'S';
    } else {
      inv['invoice_type'] = order['type'];
    }
    inv['type'] = 'out_invoice';


    if (order.hasOwnProperty('supplier_id')) {
      inv['supplier_id'] = order.supplier_id;
    } else if (order.hasOwnProperty('distributor_id')) {
      inv['supplier_id'] = order.distributor_id;
    }

    if (order.hasOwnProperty('pricelist_id')) {
      inv['pricelist_id'] = order.pricelist_id;
    } else {
      inv['pricelist_id'] = 1;
    }
    //Copy derived fields
    if (order.hasOwnProperty('customer_code')) {
      inv['customer_code'] = order.customer_code;
    }
    if (order.hasOwnProperty('customer_name')) {
      inv['customer_name'] = order.customer_name;
    }
    if (order.hasOwnProperty('customer_gst_id')) {
      inv['customer_gst_id'] = order.customer_gst_id;
    }
    if (order.hasOwnProperty('pricelist_name')) {
      inv['pricelist_name'] = order.pricelist_name;
    }
    if (order.hasOwnProperty('supplier_name')) {
      inv['supplier_name'] = order.supplier_name;
    }
    if (order.hasOwnProperty('supplier_gst_id')) {
      inv['supplier_gst_id'] = order.supplier_gst_id;
    }
    if (order.hasOwnProperty('supplier_code')) {
      inv['supplier_code'] = order.supplier_code;
    }
    if (order.hasOwnProperty('beat_name')) {
      inv['beat_name'] = order.beat_name;
    }
    if (order.hasOwnProperty('salesman')) {
      inv['salesman'] = order.salesman;
    }
    if (order.hasOwnProperty('emp_code')) {
      inv['emp_code'] = order.emp_code;
    }

    if (order.hasOwnProperty('period_id')) {
      inv['period_id'] = this.get_current_period_id(inv['date_invoice']);
    }
    if (order.hasOwnProperty('area_id')) {
      inv['area_id'] = order.area_id;
    }
    if (order.hasOwnProperty('state_id')) {
      inv['state_id'] = order.state_id;
    }
    if (order.hasOwnProperty('region_id')) {
      inv['region_id'] = order.region_id;
    }
    if (order.hasOwnProperty('supplier_code')) {
      inv['supplier_code'] = order.supplier_code;
    }
    if (order.hasOwnProperty('territory_id')) {
      inv['territory_id'] = order.territory_id;
    }
    if (order.hasOwnProperty('supplier_territory_id')) {
      inv['supplier_territory_id'] = order.supplier_territory_id;
    }
    if (order.hasOwnProperty('user_id')) {
      inv['user_id'] = order.user_id;
    }
    if (order.hasOwnProperty('amount_untaxed')) {
      inv['amount_untaxed'] = order.amount_untaxed;
    }
    if (order.hasOwnProperty('amount_tax')) {
      inv['amount_tax'] = order.amount_tax;
    }
    if (order.hasOwnProperty('amount_total')) {
      inv['amount_total'] = order.amount_total;
    }
    if (order.hasOwnProperty('beat_plan_id')) {
      inv['beat_id'] = order.beat_plan_id;
    }
    if (order.hasOwnProperty('config_json')) {
      inv['config_json'] = order.config_json;
    }
    if (order.hasOwnProperty('note')) {
      inv['note'] = order.note;
    }

    if (order.hasOwnProperty('tax_notes')) {
      inv['tax_notes'] = order.tax_notes;
    }


    inv['lines'] = [];

    let seq = 10;
    if (order['lines']) {
      order['lines'].forEach(order_line => {

        const inv_line = {
          mid: get_mid(seq, user_id),
          origin: order.name,
          partner_id: order.partner_id,
          order_partner_id: order.partner_id,
          product_id: order_line.product_id,
          price_unit: order_line.price_unit,
          name: order_line.name,
          code: order_line.code,
          uom_id: order_line.product_uom,
          sequence: seq,
          uom_name: order_line.product_uom_name
        };

        // Check the invoiced qty and reduce the quantity appropriately in invoice
        if (order_line.quantity) {
          inv_line['quantity'] = order_line.quantity;
        } else if (order_line.product_uom_qty) {
          inv_line['quantity'] = order_line.product_uom_qty;
        }
        if (order_line.inv_qty) {
          inv_line['quantity'] = inv_line['quantity'] - order_line.inv_qty;
        }


        if (order_line.uom_id) {
          inv_line['uom_id'] = order_line.uom_id;
        } else if (order_line.product_uom) {
          inv_line['uom_id'] = order_line.product_uom;
        }
        if (order_line.hasOwnProperty('default_uom_id')) {
          inv_line['default_uom_id'] = order_line.default_uom_id;
        }
        if (order_line.hasOwnProperty('uom_name')) {
          inv_line['uom_name'] = order_line.uom_name;
        }

        if (order_line.hasOwnProperty('stock')) {
          inv_line['stock'] = order_line.stock;
        }

        // if (order_line.hasOwnProperty('inv_base_qty') && order_line.inv_base_qty > 0) {
        //   let different_uom = false;
        //   let factor_inv = 1;
        //   if (order_line.hasOwnProperty('factor_inv')) {
        //     factor_inv = order_line.factor_inv;
        //   }
        //
        //   if (order_line.inv_lines) {
        //     order_line.inv_lines.forEach(ail => {
        //       if (order_line.product_uom === ail.uom_id) {
        //         inv_line['quantity'] = inv_line['quantity'] - ail.quantity;
        //       } else {
        //         different_uom = true;
        //       }
        //     });
        //   }
        //
        //   if (!different_uom) {
        //     inv_line['quantity'] = (order_line.product_uom_qty * factor_inv) - order_line.inv_base_qty;
        //     inv_line['uom_id'] = order_line.base_uom_id;
        //   }
        // }

        if (order_line.hasOwnProperty('line_id')) {
          inv_line['order_line_id'] = order_line.line_id;
        }
        if (order_line.hasOwnProperty('taxmaster_id')) {
          inv_line['taxmaster_id'] = order_line.taxmaster_id;
        }
        if (order_line.hasOwnProperty('type')) {
          inv_line['type'] = order_line.type;
        }
        if (order_line.hasOwnProperty('scheme_id')) {
          inv_line['scheme_id'] = order_line.scheme_id;
        }
        if (order_line.hasOwnProperty('scheme_discount')) {
          inv_line['scheme_discount'] = order_line.scheme_discount;
        }
        if (order_line.hasOwnProperty('discount')) {
          inv_line['discount'] = order_line.discount;
        }
        if (order_line.hasOwnProperty('config_json')) {
          inv_line['config_json'] = order_line.config_json;
        }
        if (order_line.hasOwnProperty('scheme_code')) {
          inv_line['scheme_code'] = order_line.scheme_code;
        }
        if (order_line.hasOwnProperty('price_subtotal')) {
          inv_line['price_subtotal'] = order_line.price_subtotal;
        }
        if (order_line.hasOwnProperty('price_tax')) {
          inv_line['price_tax'] = order_line.price_tax;
        }
        if (order_line.hasOwnProperty('price_total')) {
          inv_line['price_total'] = order_line.price_total;
        }
        if (order_line.hasOwnProperty('trade_disc')) {
          inv_line['trade_disc'] = order_line.trade_disc;
        }
        if (order_line.hasOwnProperty('tax_amount')) {
          inv_line['tax_amount'] = order_line.tax_amount;
        }
        if (order_line.hasOwnProperty('tax_source')) {
          inv_line['tax_source'] = order_line.tax_source;
        }
        if (order_line.hasOwnProperty('fixed_cost')) {
          inv_line['fixed_cost'] = order_line.fixed_cost;
        }
        if (order_line.hasOwnProperty('volume')) {
          inv_line['volume'] = order_line.volume;
        }
        if (order_line.hasOwnProperty('weight')) {
          inv_line['weight'] = order_line.weight;
        }
        if (order_line.hasOwnProperty('tax_notes')) {
          inv_line['tax_notes'] = order_line.tax_notes;
        }

        // Required to recompute and validate invoice edit
        if (order_line.hasOwnProperty('min_margin')) {
          inv_line['min_margin'] = order_line.min_margin;
        }
        if (order_line.hasOwnProperty('max_margin')) {
          inv_line['max_margin'] = order_line.max_margin;
        }
        if (order_line.hasOwnProperty('reusable_qty')) {
          inv_line['reusable_qty'] = order_line.reusable_qty;
        }
        if (order_line.hasOwnProperty('factor_inv')) {
          inv_line['factor_inv'] = order_line.factor_inv;
        }
        if (order_line.hasOwnProperty('factor')) {
          inv_line['factor'] = order_line.factor;
        }
        if (order_line.hasOwnProperty('mrp')) {
          inv_line['mrp'] = order_line.mrp;
        }
        if (order_line.hasOwnProperty('hsn')) {
          inv_line['hsn'] = order_line.hsn;
        }

        if (inv_line['quantity'] > 0) {
          seq = seq + 10;
          inv['lines'].push(inv_line);
        }
      });
    }

    return inv;
  }

  // UI DATA ROWS Related.

  static GetBasePrice(prod, user_unitPrice = 0) {
    let basePrice = prod['unit_price'];
    if (user_unitPrice && user_unitPrice > 0) {
      basePrice = user_unitPrice;
    } else if (prod['margin'] > 0) {
      // unitPrice = unitPrice * (1 - (prod['margin'] / 100));
      basePrice = prod['unit_price'] * (1 - (prod['margin'] / 100));
    }

    if (prod.tax > 0 && prod.price_include === true) {
      if (prod.tax_type === 'fixed') {
        basePrice = basePrice - prod.tax;
      } else {
        basePrice = basePrice / (1 + (prod.tax / 100));
      }
    }
    return basePrice;
  }

  static GetUnitPrice(prod, user_unitPrice = 0) {
    let unitPrice = prod['unit_price'];
    if (user_unitPrice && user_unitPrice > 0) {
      unitPrice = user_unitPrice;
    } else if (prod['margin'] > 0) {
      // basePrice = prod['unit_price'] * (1 - (prod['margin'] / 100));
      unitPrice = unitPrice * (1 - (prod['margin'] / 100));
    }

    // if (prod.tax > 0 && prod.price_include === true) {
    //   if (prod.tax_type === 'fixed') {
    //     baseprice = baseprice - prod.tax;
    //   } else {
    //     baseprice = baseprice / (1 + (prod.tax / 100));
    //   }
    // }
    return unitPrice;
  }

  /* BEGIN COMMON FOR ALL ORDER/INVOICE PAGES */

  loadMasterDataFromCache(cacheCallback) {

      this._localCacheService.get('uom_master').then(uomCahce => {

        this._localCacheService.get('tax_master').then(taxCahce => {

          this._localCacheService.get('plant_master').then(plantCahce => {

            if (uomCahce === null || uomCahce === undefined || taxCahce === null || taxCahce === undefined) {
              return cacheCallback(false);
            } else {
              console.log('loading masters from cache');

              this.TaxMasterList = taxCahce as any[];
              this.UOMMasterList = uomCahce as any[];
              this.plantList = plantCahce as any[];
              return cacheCallback(true);
            }
          });
        });
      });
  }


  loadCustomersDataFromCache(cacheCallback) {


    this._localCacheService.get('customers').then(customerCahce => {

      this._localCacheService.get('suppliers').then(supplierCahce => {
          if (customerCahce === null || customerCahce === undefined || supplierCahce === null || supplierCahce === undefined) {
            return cacheCallback(false);
          } else {
            console.log('loading partners from Cache');
            this.customerList = customerCahce as any[];
            this.supplierList = supplierCahce as any[];
            return cacheCallback(true);
          }
      });
    });
  }

  async loadPartnersForSales(userData, callback) {
    await this.loadCustomersDataFromCache(async (partnerCache) => {
      if (!partnerCache) {
        const res = await this.apiService.postPromise('/api/pwa_dms_connects/get_partners_for_sales', userData);
        if (res) {

          let customerCacheService = null;
          if (this.CUSTOMER_CACHE) {
            customerCacheService = this._localCacheService;
          }
          this.customerList = this.parseServerResponse('customers', customerCacheService, true, res);
          this.supplierList = this.parseServerResponse('suppliers', customerCacheService, true, res);

          return callback(null, null);
        } else {
          return callback('Error', null);
        }
      }
      callback(null, null);
    });
  }

  // async loadDistributorsForSales(userData, callback) {
  //   const res = await this.apiService.postPromise('/api/pwa_dms_connects/get_distributors_for_sales', userData);
  //   if (res) {
  //     console.log(res);
  //     this.customerList = this.parseServerResponse('customers', null, true, res);
  //     this.supplierList = this.parseServerResponse('suppliers', null, true, res);
  //     return callback();
  //   } else {
  //     callback('error', null);
  //   }
  // }

  loadPartnersForPurchase(userData, callback) {

    this.apiService.post('/api/pwa_dms_connects/get_partners_for_purchase', userData).subscribe((res: any) => {
        this.customerList = this.parseServerResponse('customers', undefined, true, res);
        this.supplierList = this.parseServerResponse('suppliers', undefined, true, res);
        return callback();
      },
      error => {
        return callback('Error', null);
      });
  }

  async loadPartnersForVanSales(userData, callback) {
    const res = await this.apiService.postPromise('/api/pwa_van_sales_connects/get_partners_for_load_out', userData);
    if (res.hasOwnProperty('results') && (res['results'].status === 200) && res['results'].data) {
      this.customerList = res['results'].data['vans'];
      this.supplierList = res['results'].data['suppliers'];
      this.activeSupplierList = res['results'].data['suppliers'];
      return callback(null, null);
    } else {
      this.handleError(ErrorHandler.getErrorObject(res.results.msg));
      return callback('Error', null);
    }
  }

  async loadSuppliersForTertiarySales(userData, callback) {
    const res = await this.apiService.postPromise('/api/pwa_dms_connects/get_partners_for_counter_sales', userData);
    if (res.hasOwnProperty('results') && (res['results'].status === 200) && res['results'].data) {
      this.customerList = res['results'].data['customers'];
      this.supplierList = res['results'].data['suppliers'];
      this.activeSupplierList = res['results'].data['suppliers'];
      return callback(null, null);
    } else {
      this.handleError(ErrorHandler.getErrorObject(res.results.msg));
      return callback('Error', null);
    }
  }

  parseServerResponse(key, cache, validate, res) {
    if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
      if (res['results'].data) {

        if (res['results'].data[key] && cache) {
          cache.set(key, res['results'].data[key]).then();
        } else if (res['results'].data && cache) {
          cache.set(key, res['results'].data).then();
        }
        //
        // if (validate && !(res['results'].data[key] || res['results'].data) ) {
        //   this.handleError(ErrorHandler.getErrorObject('Records not found!'));
        //   return null;
        // } else {

        if (res['results'].data[key]) {
          return res['results'].data[key];
        } else {
          return res['results'].data;
        }
        // }
      }
    } else {
      this.handleError(ErrorHandler.getErrorObject(res.results.msg));
      return null;
    }
  }
  //
  // LoadDefaultSupplier(callback) {
  //   this._localCacheService.get('suppliers').then(supplierCahce => {
  //     if (supplierCahce === null || supplierCahce === undefined) {
  //       const userData = {};
  //       userData['access_token'] = this.userContext.access_token;
  //
  //       this.apiService.post('/api/pwa_dms_connects/get_supplier_for_dms', userData).subscribe((res: any) => {
  //           this.supplierList = this.parseServerResponse('suppliers', this._localCacheService, true, res);
  //           return callback();
  //         },
  //         error => {
  //           return callback('Error', null);
  //         });
  //     } else {
  //       console.log('loading supplier from Cache');
  //       this.supplierList = supplierCahce as any[];
  //     }
  //   });
  // }

  async loadCustomerEditMasters() {
    const userData = {};
    userData['access_token'] = this.userContext.access_token;
    const master_res = await this.apiService.postPromise('/api/pwa_dms_connects/get_customer_masters_data_dms', userData);
    this.outletTypeList = this.parseServerResponse('outlet_types', this._localCacheService, true, master_res);
  }

  getDefaultSupplier() {
    const defaultSupplier = this.supplierList[0];
    if (!defaultSupplier) {
      this.toastr.error('Default supplier Not Found Contact support');
      return false;
    } else if (defaultSupplier.territory_id === undefined || defaultSupplier.territory_id <= 0) {
      this.toastr.error('Default Territory not configured for selected Supplier:' + defaultSupplier.name + ', Contact support for more information');
      return false;
    } else if (defaultSupplier.territory_id === undefined || defaultSupplier.territory_id <= 0) {
      this.toastr.error('Default Secondary pricelist not configured for selected Supplier:' + defaultSupplier.name + ', Contact support for more information');
      return false;
    } else {
      return defaultSupplier;
    }
  }

  loadMasterDataForEdit(callback) {
    const userData = {};
    userData['access_token'] = this.userContext.access_token;

    const salesmanProfiles = ['Manager', 'Executive', 'Delivery Executive'];
    const userMasterList = JSON.parse(localStorage.getItem('all_salesmans'));
    this.userList = [];
    userMasterList.forEach(user => {
      if (salesmanProfiles.includes(user.profile)) {
        this.userList.push(user);
      }
    });
    if (this.userList.length === 0 && userMasterList.length > 0) {
      this.userList = userMasterList;
    }
    this.loadMasterDataFromCache(async (masterCacheLoaded) => {
      if (!masterCacheLoaded) {

        const master_res = await this.apiService.postPromise('/api/pwa_dms_connects/get_masters_for_dms', userData);
        if (master_res) {
          this.UOMMasterList = this.parseServerResponse('uom_master', this._localCacheService, true, master_res);
          this.TaxMasterList = this.parseServerResponse('tax_master', this._localCacheService, false, master_res);
          this.plantList = this.parseServerResponse('plant_master', this._localCacheService, false, master_res);
        }
      }

      if (this.OPERATION_MODE === 'P') {
        this.loadPartnersForPurchase(userData, callback);
      } else if (this.OPERATION_MODE === 'L') {

        this.loadPartnersForVanSales(userData, callback);
      } else if (this.OPERATION_MODE === 'T') {

        this.loadSuppliersForTertiarySales(userData, callback);
      } else {

        this.loadPartnersForSales(userData, callback);
      }
    });
  }


  populateSupplierAttributes(supplier_id) {
    if (this.supplierList) {
      const supplier = this.supplierList.find(item => item.id === supplier_id);
      if (supplier) {
        this.activeObject['distributor_id'] = supplier_id;
        this.activeObject['supplier_name'] = supplier.name;
        this.activeObject['supplier_code'] = supplier.code;
        this.activeObject['supplier_gst_id'] = supplier.gst_id;

        this.activeObject['supplier_territory_id'] = supplier.territory_id;
        // if (this.activeObject['supplier_territory_id'] > 0) {
        if (this.activeObject['territory_id'] === null || this.activeObject['territory_id'] === undefined || this.activeObject['territory_id'] === 0) {
          this.activeObject['territory_id'] = this.activeObject['supplier_territory_id'];
        }
        if (this.activeObject['pricelist_id'] === null || this.activeObject['pricelist_id'] === undefined) {
          if (supplier.sec_pricelist_id > 0) {
            this.activeObject['pricelist_id'] = supplier.sec_pricelist_id;
          }
        }

        return true;
        // } else {
        //   this.toastr.error('Default Territory mapping is missing for selected supplier');
        //   return false;
        // }
      } else {
        this.toastr.error('supplier record not found in master list');
        return false;
      }
    } else {
      this.toastr.error('supplier list not found in master list');
      return false;
    }
  }

  filterEligibleSchemes(allSchemes, customer_id) {
    const fileredSchemes = [];

    let customer_obj;
    if (customer_id) {
      customer_obj = this.customerList.find(item => item.id === customer_id);
    }

    if (customer_id) {
      if (allSchemes) {
        allSchemes.forEach(scheme => {
          let eligibale = true;
          if (scheme.criteria) {
            const eligible_status = [];
            scheme.criteria.forEach(cr => {
              if (cr.type === 'outlet_type') {
                if (cr.expression === '=' && cr.outlet_type_id !== customer_obj.retailer_type) {
                  // eligibale = false;
                  eligible_status.push(false);
                } else if (!(cr.expression === '!=' && cr.outlet_type_id === customer_obj.retailer_type)) {
                  // eligibale = false;
                  eligible_status.push(false);
                } else {
                  eligible_status.push(true);
                }
              } else if (cr.type === 'key_outlet') {
                if (cr.expression === '=') {
                  if (cr.value === 'false' && customer_obj.key_outlet === false) {
                    // eligibale = true;
                    eligible_status.push(true);
                  } else if (cr.value === 'true' && customer_obj.key_outlet === true) {
                    // eligibale = true;
                    eligible_status.push(true);
                  } else {
                    // eligibale = false;
                    eligible_status.push(false);
                  }
                } else {
                  eligible_status.push(true);
                }
              }
            });

            eligible_status.forEach(es => {
              if (eligibale) {
                eligibale = es;
              }
            });
          }

          if (eligibale) {
            fileredSchemes.push(scheme);
          }
        });
      }
    }
    return fileredSchemes;
  }

  loadProducts(customer_id, distributor_id, territory_id, sec_pricelist_id, callback) {

    this.prodList = [];
    this.schemesMasterList = [];
    this.applicableSchemes = [];

    const userData = {};
    userData['access_token'] = this.userContext.access_token;
    userData['distributor_id'] = distributor_id;
    userData['customer_id'] = customer_id;
    userData['territory_id'] = territory_id;
    userData['pricelist_id'] = sec_pricelist_id;
    userData['s_type'] = this.OPERATION_MODE;


    if (sec_pricelist_id === 0 || sec_pricelist_id === undefined) {
      this.toastr.error('Error Loading products with no priselist mapped with supplier');
    } else {
      this.apiService.post('/api/pwa_dms_connects/get_products_for_dms', userData).subscribe(res => {
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          this.prodList = res['results'].data.products;
          this.prodList.forEach(p => {
            if (p['code'] && this.feature_show_product_code_name) {
              p['display_name'] = p['code'] + ' - ' + p['product'];
            } else {
              p['display_name'] =  p['product'];
            }
            p['base_price'] = p['unit_price'];
            // if (p['margin'] > 0) {
            //   p['base_price'] = p['unit_price'];
            //   p['unit_price'] = p['base_price'] * (1 - (p['margin'] / 100));
            // } else {
            //   p['base_price'] = p['unit_price'];
            // }
            // if (p.taxmaster_id && this.TaxMasterList) {
            //   const taxMaster = this.TaxMasterList.find(item => item.id === p.taxmaster_id);
            //   if (taxMaster && taxMaster.price_include) {
            //     if (taxMaster.amount_type === 'fixed') {
            //       p['base_price'] = p['unit_price'] - taxMaster.amount;
            //     } else {
            //       p['base_price'] = p['unit_price'] / (1 + (taxMaster.amount / 100));
            //     }
            //   }
            // }
          });

          if (customer_id && customer_id > 0) {
            this.schemesMasterList = this.filterEligibleSchemes(res['results'].data.schemes, customer_id);
          } else {
            this.schemesMasterList = res['results'].data.schemes;
          }
          return callback();
        } else {
          this.toastr.error('Error fetching products for given pricelist');
        }
      });
    }
  }

  get_supplier_available_stocks(ordersList, callback) {

    const uqProducts = new Set();
    const uqDistributor_ids = [];
    const uqterritory_ids = [];
    const lineData = [];
    ordersList.forEach(order => {
      if (order.lines) {
        order.lines.forEach(line => {
          lineData.push(line);
          uqProducts.add(line.product_id);
        });
      }
      if (order.supplier_id) {
        if (!uqDistributor_ids.includes(order.supplier_id)) {
          uqDistributor_ids.push(order.supplier_id);
        }
      } else if (order.distributor_id) {
        if (!uqDistributor_ids.includes(order.distributor_id)) {
          uqDistributor_ids.push(order.distributor_id);
        }
      }
      if (order.supplier_territory_id) {
        if (!uqterritory_ids.includes(order.supplier_territory_id)) {
          uqterritory_ids.push(order.supplier_territory_id);
        }
      } else {
        //   lookup Territory_id
        if (uqDistributor_ids.length > 0) {
          const supplier_obj = this.supplierList.find(item => item.id === uqDistributor_ids[0]);
          if (supplier_obj) {
            if (!uqterritory_ids.includes(supplier_obj.territory_id)) {
              uqterritory_ids.push(supplier_obj.territory_id);
            }
          }
        }
      }
    });

    // if (uqterritory_ids.length === 0) {
    //   //   Invalid Condition
    //   return callback(ErrorHandler.getErrorObject('Missing supplier Territory, check configuration'), []);
    // }


    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    // paramObject.data = {};
    // paramObject['territory_id'] = uqterritory_ids[0];
    paramObject['distributor_id'] = uqDistributor_ids[0];
    paramObject['product_ids'] = [...uqProducts];

    this.apiService.post("/api/pwa_dms_connects/get_supplier_stock", paramObject)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            callback(null, res.results.data);

            // document.getElementById("spanselectmenu").style.width = document.getElementById("spanselect").offsetWidth.toString() + 'px';
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            callback(ErrorHandler.getErrorObject(res.results.msg), []);
          } else {
            callback(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'), []);
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => callback(ErrorHandler.getErrorObject(error), []));
  }


  async get_customer_available_stocks(partner_id, territory_id, product_ids, callback) {

    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    // paramObject.data = {};
    // paramObject['territory_id'] = territory_id;
    paramObject['distributor_id'] = partner_id;
    paramObject['product_ids'] = product_ids;

    const res = await this.apiService.postPromise("/api/pwa_dms_connects/get_supplier_stock", paramObject);
    if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
      return callback(null, res['results'].data);

    } else if (res.hasOwnProperty('results') && (res['results'].status === 203)) {
      return callback(ErrorHandler.getErrorObject(res['results'].msg), null);
    } else {
      return callback(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'), null);
    }
  }

  addProductLine(productId, uomId, currentStock, quantity, user_unitPrice) {
    const prod = this.prodList.find(item => item.p_id === productId);
    const uom_obj = this.UOMList.find(item => item.id === uomId);

    if (prod === undefined || prod === null) {
      this.toastr.error('unable to filter selected product');
      return 0;
    }

    if (uom_obj === undefined || uom_obj === null) {
      this.toastr.error('unable to filter selected UOM');
      return 0;
    }

    const selected_uom_factor = this.get_factor_for_selected_uom(uomId, prod.uom_id);
    let unitPrice = DMSBasePage.GetUnitPrice(prod, user_unitPrice);
    let baseprice = DMSBasePage.GetBasePrice(prod, user_unitPrice);
    // if (prod['margin'] > 0) {
    //   baseprice = prod['unit_price'];
    //   unitPrice = baseprice * (1 - (prod['margin'] / 100));
    // }
    //
    // if (prod.tax > 0 && prod.price_include === true) {
    //   if (prod.tax_type === 'fixed') {
    //     baseprice = baseprice - prod.tax;
    //   } else {
    //     baseprice = baseprice / (1 + (prod.tax / 100));
    //   }
    // }

    if (user_unitPrice !== undefined && user_unitPrice > 0 && user_unitPrice !== unitPrice) {
      unitPrice = parseFloat(user_unitPrice);
      baseprice = parseFloat(user_unitPrice);

      if (unitPrice !== parseFloat(prod['unit_price'])) {
        if (parseFloat(prod['max_margin']) > 0 && unitPrice > parseFloat(prod['unit_price'])) {
          if (((unitPrice - parseFloat(prod['unit_price']))) > (parseFloat(prod['max_margin']) * parseFloat(prod['unit_price'])) / 100) {
            this.toastr.error('You are trying to set unit price more then the allowed range');
            return 0;
          }
        } else if (parseFloat(prod['min_margin']) > 0 && unitPrice < parseFloat(prod['unit_price'])) {
          if (unitPrice < (parseFloat(prod['unit_price']) - (parseFloat(prod['min_margin']) * parseFloat(prod['unit_price'])) / 100)) {
            this.toastr.error('You are trying to set unit price more less the allowed range');
            return 0;
          }
        }
      }
    }

    const line_subtotal = baseprice * parseInt(quantity, 10);
    let unitMeasure = 1;
    if (prod.hasOwnProperty('param_json')) {
      if (prod.param_json.hasOwnProperty('Unit Measure')) {
        unitMeasure = prod.param_json['Unit Measure'];
      }
    }

    let vol = 0;
    let wgt = 0;
    if (prod.hasOwnProperty('volume')) {
        vol = prod['volume'] * parseInt(quantity, 10) * selected_uom_factor;
    }
    if (prod.hasOwnProperty('weight')) {
      wgt = prod['weight'] * parseInt(quantity, 10) * selected_uom_factor;
    }

    //TODO: exclude promo/foc items
    const prod_match = this.rowData.find(item => item.product_id === productId && item.uom_id === uomId && item.type === 'normal');
    let line = {};
    if (prod_match) {
      //     Matching Item already exiists, Update QTY
      //     scheme, Subtotal, tax to be recompued
      let line_uid = 0;
      this.rowData.forEach(item => {
        if (item.product_id === productId) {
          item['quantity'] = quantity;
          item['deleted'] = false;
          item['uom_id'] = uomId;
          item['volume'] = vol;
          item['weight'] = wgt;
          item['price_unit'] = unitPrice;
          item['base_price'] = baseprice;

          item['reusable_qty'] = parseInt(quantity, 10);
          item['price_subtotal'] = line_subtotal; //Price_subtotal to be recomputed after scheme and discount computation
          item['price_tax'] = 0;
          item['price_total'] = line_subtotal;

          line_uid = item.uid;
        }
      });

      return line_uid;
    } else {
      // Insert New Row

      const line_config_json = {base_price: baseprice};
      const disc_details = {};
      let line_discount = 0;
      if (parseFloat(prod['extra_margin']) > 0) {
        disc_details['user_dis'] = prod['extra_margin'];
        line_discount = parseFloat(prod['extra_margin']);
      }
      if (parseFloat(String(this.cash_dis)) > 0) {
        this.cash_dis = parseFloat(String(this.cash_dis));
        disc_details['cash_dis'] = this.cash_dis;
        line_discount = line_discount + this.cash_dis;
      }
      if (line_discount > 0) {
        line_config_json['disc_details'] = disc_details;
      } else {
        delete line_config_json['disc_details'];
      }


      const unique_id = this.rowData.length + 10;
      line = {
        uid: unique_id,
        line_id: 0,
        type: 'normal',
        stock: currentStock,
        display_name: prod['display_name'],
        code: prod['code'],
        name: prod['product'],
        categ_id: prod['categ_id'],
        product_id: productId,
        unit_measure: unitMeasure,
        tax_amount: parseFloat(prod['tax']),
        uom_id: uomId,
        default_uom_id: prod.uom_id,
        uom_name: uom_obj['name'],
        price_unit: unitPrice,
        base_price: baseprice,
        discount:  line_discount,
        config_json: line_config_json,
        trade_disc: 0,
        fixed_cost: 0,
        scheme_discount: 0,
        quantity: parseInt(quantity, 10),
        reusable_qty: parseInt(quantity, 10),
        price_subtotal: line_subtotal, //Price_subtotal to be recomputed after scheme and discount computation
        price_tax: 0,
        price_total: line_subtotal,
        tax_name: prod['tax_name'],
        taxmaster_id: prod['taxmaster_id'],
        factor_inv: prod['factor_inv'],
        factor: prod['factor'],
        volume: vol,
        weight: wgt
      };

      // if (line['mid'] === undefined || line['mid'].length === 0) {
      line['mid'] = this.get_mid(this.rowData.length);
      // }
      this.rowData.push(line);

      return unique_id;
    }
  }

  compute_totals() {
    this.activeObject.amount_untaxed = 0;
    this.activeObject.amount_tax = 0;
    this.activeObject.amount_total = 0;
    this.activeObject.scheme_discount = 0;
    this.activeObject.line_discount = 0;
    this.activeObject.tax_source = 0;

    this.rowData.forEach(line => {
      if (line['deleted'] === undefined || line['deleted'] === false) {
        this.activeObject.scheme_discount = parseFloat(this.activeObject.scheme_discount) + parseFloat(line.scheme_discount);
        this.activeObject.amount_untaxed = parseFloat(this.activeObject.amount_untaxed) + parseFloat(line.price_subtotal);
        this.activeObject.amount_tax = parseFloat(this.activeObject.amount_tax) + parseFloat(line.price_tax);
        // this.activeObject.amount_total = parseFloat(this.activeObject.amount_total) + parseFloat(line.price_total);
        if (line.disc_value && line.disc_value > 0) {
          this.activeObject.line_discount = parseFloat(this.activeObject.line_discount) + (parseFloat(line.disc_value));
        }
      }
    });

    if (this.activeObject.tcs && this.activeObject.tcs > 0) {
      this.activeObject.tax_source = (this.activeObject.amount_untaxed + this.activeObject.amount_tax) * (parseFloat(this.activeObject.tcs) / 100);
    }

    this.activeObject.amount_total = this.activeObject.amount_untaxed + this.activeObject.amount_tax + this.activeObject.tax_source;
  }

  async compute_scheme_and_tax() {

    //Assumption, Scheme to be applied on unitPrice * qty
    //Subtotal & Total To be recomputed


    const s = new SchemeCalculator(this.rowData, this.schemesMasterList, this.prodList, this.UOMMasterList);

    const updatedRows = s.computeSchemes();
    updatedRows.forEach(row => {
      let base_price = parseFloat(row['base_price']);
      if (!base_price) {
        base_price = parseFloat(row['price_unit']);
      } //Fail safe check, ideally should never occur

      let base_total = parseInt(row['quantity'], 10) * base_price;
      if (row['discount'] && row['discount'] > 0) {

        const discount_value = (parseFloat(row['discount']) / 100) * base_total;
        row['disc_value'] = discount_value;
        row['disc_qty'] = discount_value / parseInt(row['quantity'], 10);
        // row['disc_volume'] = event.data['disc_qty'] / unit_measure;

        base_total = base_total - discount_value;
      }
      if (row['scheme_discount'] && row['scheme_discount'] > 0) {
        base_total -= parseFloat(row['scheme_discount']);
      }
      row['price_subtotal'] = base_total;
    });

    //Assumption, Tax to be applied on Subtotal, (all discount & subtotal computation to be done before this lne
    //Tax & Total To be recomputed
    const t = new TaxCalculator(updatedRows, this.TaxMasterList, this.prodList);
    this.activeObject['tax_notes'] = t.CalculateLineTax();
    // if (!this.bulkSelector) {
    this.rowData = updatedRows;
    if (this.gridApi.getDisplayedRowCount() && this.gridApi.getDisplayedRowCount() > 0) {
      if (this.rowData && this.rowData.length !== this.gridApi.getDisplayedRowCount()) {
        this.gridApi.setRowData(this.rowData);
      } else {
         this.gridApi.applyTransactionAsync({update: updatedRows}, () => {
         });
      }
    } else {
      this.gridApi.setRowData(this.rowData);
    }


    // } else {
    //   this.cartItems = updatedRows;
    // }
    // Compute and display Data outside Grid.
    this.compute_totals();
  }

  applyCashDiscount() {
    if (isNaN(this.cash_dis)) {
      this.cash_dis = 0;
    }  else if (this.cash_dis > 99) {
      this.cash_dis = 99;
    } else {
      this.rowData.forEach((row) => {
        if (row.type !== 'promo') {

          if (!row.hasOwnProperty('config_json') || !row.config_json.hasOwnProperty('disc_details') || row.config_json.disc_details === null) {
            row.config_json['disc_details'] = {};
          }

          if (row.config_json['disc_details'].hasOwnProperty('user_dis')) {
            row['discount'] = parseFloat(row.config_json['disc_details'].user_dis) + parseFloat(String(this.cash_dis));
          } else {
            row['discount'] = parseFloat(String(this.cash_dis));
            row.config_json['disc_details'].user_dis = 0;
          }

          row.config_json['disc_details']['cash_dis'] = parseFloat(String(this.cash_dis));
        }
      });

      if (!this.activeObject.hasOwnProperty('config_json') || this.activeObject.config_json === null) {
        this.activeObject.config_json = {};
      }
      this.activeObject['config_json']['cash_dis'] = this.cash_dis;

      this.compute_scheme_and_tax();
    }
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  compute_scheme_and_tax_without_setData(line_uid, cartItems) {

    //Assumption, Scheme to be applied on unitPrice * qty
    //Subtotal & Total To be recomputed
    // let s;
    // if (!this.bulkSelector) {
    //   s = new SchemeCalculator(this.rowData, this.schemesMasterList, this.prodList, this.UOMList);
    // } else {
    const s = new SchemeCalculator(cartItems, this.schemesMasterList, this.prodList, this.UOMList);
    // }
    const updatedRows = s.computeSchemes();

    //Assumption, Tax to be applied on Subtotal, (all discount & subtotal computation to be done before this lne
    //Tax & Total To be recomputed
    const t = new TaxCalculator(updatedRows, this.TaxMasterList, this.prodList);
    const tn = t.CalculateLineTax();
    const result = {
      items: updatedRows,
      tax_notes: tn
    };
    // this.activeObject['tax_notes'] = t.CalculateLineTax();
    // if (!this.bulkSelector) {
    //   this.rowData = updatedRows;
    //   this.gridApi.setRowData(this.rowData);
    // } else {
    return result;
    // }
    // Compute and display Data outside Grid.
    // this.compute_totals();
  }

  compute_tax() {

    //Assumption, Scheme to be applied on unitPrice * qty
    //Subtotal & Total To be recomputed
    //Assumption, Tax to be applied on Subtotal, (all discount & subtotal computation to be done before this lne
    //Tax & Total To be recomputed
    this.rowData.forEach(row => {
      let base_price = parseFloat(row['base_price']);
      if (!base_price) {
        base_price = parseFloat(row['price_unit']);
      } //Fail safe check, ideally should never occur

      // let base_total = parseInt(row['quantity'], 10) * base_price;
      // if (row['discount'] && row['discount'] > 0) {
      //
      //   const discount_value = (parseFloat(row['discount']) / 100) * base_total;
      //   row['disc_value'] = discount_value;
      //   row['disc_qty'] = discount_value / parseInt(row['quantity'], 10);
      //   // row['disc_volume'] = event.data['disc_qty'] / unit_measure;
      //
      //   base_total = base_total - discount_value;
      // }
      // if (row['scheme_discount'] && row['scheme_discount'] > 0) {
      //   base_total -= parseFloat(row['scheme_discount']);
      // }
      row['price_subtotal'] = parseInt(row['quantity'], 10) * base_price;
    });

    const t = new TaxCalculator(this.rowData, this.TaxMasterList, this.prodList);
    this.activeObject['tax_notes'] = t.CalculateLineTax();
      // this.rowData = updatedRows;
    this.gridApi.setRowData(this.rowData);
    // Compute and display Data outside Grid.
    this.compute_totals();
  }

  // Order Actions
  ConfirmOrders(order_ids, callback, supplier_territory_id?: any) {
    this.userContext['order_ids'] = order_ids;
    if (supplier_territory_id !== undefined && supplier_territory_id > 0) {
      this.userContext['supplier_territory_id'] = supplier_territory_id;
    } else if (this.activeObject && this.activeObject.hasOwnProperty('supplier_territory_id')) {
      this.userContext['supplier_territory_id'] = this.activeObject.supplier_territory_id;
    }

    this.apiService.post('/api/pwa_dms_connects/confirm_orders', this.userContext)
      .subscribe(res => {
          return callback(null, res);
        },
        error => {
          return callback( "ERROR", null);
        });
  }

  CancelOrders(order_ids, callback) {
    this.userContext['order_ids'] = order_ids;
    this.apiService.post('/api/pwa_dms_connects/cancel_orders', this.userContext)
      .subscribe(res => {
          return callback(null, res);
        },
        error => {
          return callback( "ERROR", null);
        });
  }

  // Invoice Actions
  // ConfirmInvoices(invoice_ids, callback) {
  //   this.pageReady = false;
  //   this.userContext['invoice_ids'] = invoice_ids;
  //   this.apiService.post('/api/pwa_dms_connects/confirm_invoices', this.userContext)
  //     .subscribe(res => {
  //         this.pageReady = true;
  //         return callback(null, res);
  //       },
  //       error => {
  //         this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
  //         return callback( "ERROR", null);
  //       });
  // }

  ChangeInvoiceStatus(invoice_id, status, callback) {

    this.userContext.data.id = invoice_id;
    this.userContext['action'] = status;
    this.userContext['feature_disable_supplier_live_stock'] = this.sharedService.ACLcheck('feature_disable_supplier_live_stock');
    this.apiService.post('/api/pwa_dms_connects/sale_invoice_status_change', this.userContext)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && res.results.status === 200) {
            if (res.results.hasOwnProperty('data') && res.results.data.length > 0) {
              if (status === 'confirm') {
                this.toastr.success('Invoice Confirmed : ' + this.activeID);
              } else if (status === 'cancel') {
                this.toastr.success('Invoice Cancelled : ' + this.activeID);
              } else if (status === 'dispatched') {
                this.toastr.success('Invoice Dispatched : ' + this.activeID);
              } else if (status === 'delivered') {
                this.toastr.success('Invoice Delivered : ' + this.activeID);
              } else if (status === 'received') {
                this.toastr.success('Invoice Received : ' + this.activeID);
              }

              callback(null, res.results.data[0]);
            } else {
              this.toastr.warning('Record Saved with warning!');
              callback(null, null);
            }
            this.pageReady = true;
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            callback(res.results.msg, null);
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            callback('error', null);
          }
        },
        error => {
          this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          callback('error', null);
        });
  }

  async ReconcileInvoice(amount_collected, invoices, credits_allocated, credit_notes, payment_type) {
    const user_context = {};
    user_context['access_token'] = this.userContext.access_token;
    user_context['data'] = {};
    user_context['data']['amount_collected'] = amount_collected;
    user_context['data']['invoices'] = invoices;
    user_context['data']['credits_allocated'] = credits_allocated;
    user_context['data']['credit_notes'] = credit_notes;
    user_context['data']['payment_type'] = payment_type;

    return await this.apiService.postPromise('/api/pwa_dms_connects/sale_invoice_mark_paid', user_context);
  }

  async processPrintDetails(orderdata) {
    const inThis = this;
    let total_qty = 0;
    let total_discount = 0;
    let printDetails = {};
    const all_orders = [];
    for (const data of orderdata) {
      // res['results'].data.forEach(async data => {
      total_qty = 0;
      printDetails = data;
      const tax_breaks = [];
      const taxnotes = [];
      printDetails['label_config'] = {};
      const labels = JSON.parse(localStorage.getItem('custom_labels'));
      if (labels) {
        labels.forEach(lab => {
          printDetails['label_config'][lab.label] = lab.display;
        });
      } else {
        printDetails['label_config']['PrimaryCurrency'] = '';
        printDetails['label_config']['SecondaryCurrency'] = '';
        printDetails['label_config']['SupplierLabel'] = 'Supplier';
        printDetails['label_config']['TaxLabel'] = 'GST';
      }
      if (typeof printDetails['tax_notes'] === 'string') {
        printDetails['tax_notes'] = JSON.parse(printDetails['tax_notes']);
      }
      if (printDetails['tax_notes']) {
        Object.keys(printDetails['tax_notes']).forEach(each_tax => {
          tax_breaks.push({
            name: each_tax,
            tax: printDetails['tax_notes'][each_tax].toFixed(inThis.apiService.decimalPolicy)
          });
          taxnotes.push(each_tax);
        });
      }
      printDetails['tax_breakup'] = tax_breaks;
      if (printDetails['partner_id'] === printDetails['partner_shipping_id']) {
        printDetails['customer_skip'] = true;
      } else {
        printDetails['customer_skip'] = false;
      }
      printDetails['print_text'] = inThis.domSanitizer.bypassSecurityTrustHtml(printDetails['print_text']);
      let rate = 0;
      let spli;
      printDetails['sale_items'].forEach(sale_item => {
        sale_item['label'] = '[' + sale_item.type + ']';
        const breaks = [];
        sale_item['new_tax'] = 0;
        if (sale_item['tax_notes']) {
          sale_item['tax_notes'] = JSON.parse(sale_item['tax_notes']);
          if (typeof sale_item['tax_notes'] === 'string' && sale_item['tax_notes'] !== "") {
            sale_item['tax_notes'] = JSON.parse(sale_item['tax_notes']);
          }
        }
        if (sale_item['tax_notes'] && sale_item['tax_notes'].constructor === Object) {
          Object.keys(sale_item['tax_notes']).forEach(each_tax => {
            if (sale_item['tax_notes'][each_tax].includes('(')) {
              spli = sale_item['tax_notes'][each_tax].split('(');
              rate = spli[1].split('%')[0].split(')')[0];
              breaks.push({name: each_tax, tax: spli[0], rate});
            } else {
              if (sale_item.hasOwnProperty('tax')) {
                spli = sale_item['tax'].split('(');
                rate = spli[1].split('%')[0].split(')')[0];
              } else {
              }
              breaks.push({name: each_tax, tax: sale_item['tax_notes'][each_tax], rate});
            }

          });
        }
        sale_item['tax_notes'] = breaks;

        total_discount += parseFloat(sale_item.vps_dis);
        if (printDetails['d_status'] === 'delivered') {
          sale_item['abs_qty'] = Math.abs(sale_item['delivered_qty']);
          total_qty += parseInt(sale_item.delivered_qty, 0);
          sale_item.unit_price = parseFloat(sale_item.unit_price) - (parseFloat(sale_item.scheme_discount) / parseFloat(sale_item.delivered_qty)) -
            (parseFloat(sale_item.unit_price) * (parseFloat(sale_item.discount) / 100));
        } else {
          sale_item['abs_qty'] = Math.abs(sale_item['qty']);
          total_qty += parseInt(sale_item.qty, 0);
          sale_item.unit_price = parseFloat(sale_item.unit_price) - (parseFloat(sale_item.scheme_discount) / parseFloat(sale_item.qty)) -
            (parseFloat(sale_item.unit_price) * (parseFloat(sale_item.discount) / 100));
        }
        sale_item.unit_price = sale_item.unit_price.toFixed(inThis.apiService.decimalPolicy);
      });
      printDetails['total_qty'] = total_qty;
      printDetails['discount'] = total_discount.toFixed(inThis.apiService.decimalPolicy);
      if (printDetails['date_delivery']) {
        printDetails['invoice_date'] = inThis.datePipe.transform((new Date(printDetails['date_delivery'] + ' UTC')).toString(), 'dd/MM/yyyy');
      } else if (printDetails['date_delivered'] != null) {
        printDetails['invoice_date'] = inThis.datePipe.transform((new Date(printDetails['date_delivered'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
      }
      printDetails['order_date'] = inThis.datePipe.transform((new Date(printDetails['order_date'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
      if (printDetails['date_delivered'] != null) {
        printDetails['date_delivered'] = inThis.datePipe.transform((new Date(printDetails['date_delivered'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
      }
      if (printDetails['logo']) {
        const resp = await inThis.apiService.fetchImageWait({
          access_token: inThis.userContext.access_token,
          data: [{store_fname: printDetails['logo'], container: "res_partner"}]
        });
        // res_data.subscribe(resp => {
        console.log(resp);
        if (resp['result'].length > 0) {
          printDetails['logo'] = inThis.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + resp['result'][0].bill_datas);
        }
        all_orders.push(printDetails);
      } else {
        all_orders.push(printDetails);
      }
      this.print_details = all_orders;
      console.log(all_orders);
    }
  }

  get_factor_for_selected_uom(selected_uom_id, default_uom_id) {
    let factor_inv = 1;
    if (this.UOMMasterList) {
      const selected_uom = this.UOMMasterList.find(u => u.id === selected_uom_id);
      const default_uom = this.UOMMasterList.find(u => u.id === default_uom_id);
      if (selected_uom && default_uom) {
        factor_inv = default_uom.factor * selected_uom.factor_inv;
      }
    }
    return factor_inv;
  }

  get_base_uom_factor(uom_id) {
    let factor_inv = 1;
    if (this.UOMMasterList) {
      const uom = this.UOMList.find(u => u.id === uom_id);
      if (uom) {
        factor_inv = uom.factor_inv;
      }
    }
    return factor_inv;
  }

  get_base_uom(uom_id) {
    let base_uom_id;
    if (this.UOMMasterList) {
      const uom = this.UOMMasterList.find(u => u.id === uom_id);
      if (uom) {
        const base_uom = this.UOMMasterList.find(u => u.category_id === uom.category_id && u.uom_type === 'reference');
        if (base_uom) {
          base_uom_id = base_uom.id;
        }
      }
    }
    return base_uom_id;
  }

}
