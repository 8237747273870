import {Component, OnInit, ElementRef, Renderer2, ViewChild, NgZone} from '@angular/core';
import { GridOptions } from '@ag-grid-enterprise/all-modules';
import { ApiService} from '../../_services/api.service';
import { SharedService } from '../../_services/shared_service';
import { Router } from '@angular/router';
import { ColorCellRenderer } from '../../colorCell.component';
import { DatePipe } from '@angular/common';
import { AgmMap, LatLngBounds, MapsAPILoader, MouseEvent } from "@agm/core";
import { LoadingstateComponent} from '../../components/loadingstate/loadingstate.component';
import { DateTimeAdapter } from 'ng-pick-datetime';
import * as XLSX from "xlsx-js-style";
import {ToastrService} from 'ngx-toastr';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {PaymentPopupComponent} from "../../components/payment_popup/payment_popup.component";
import {PrintInvoiceComponent} from "../../printInvoice.component";
import {PrintClickRenderer} from "../../printClickRenderer.component";
import {OutstandingShowComponent} from "../../outstandingShow.component";
declare var $: any;

@Component({
  selector: 'app-dmssales',
  templateUrl: './dms_retailers.component.html',
  styleUrls: ['./dms_retailers.component.scss']
})
export class Dms_retailersComponent implements OnInit {
  @ViewChild('search')

  user;
  res_data;
  user_data = {
    date: null,
    supplier_id: null,
    access_token: null,
    url: null,
    offset: null,
    data: null
  };

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  public frameworkComponents;
  public rowData = [];
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public styleGrid;
  public myDate;
  public collapse_disable;
  public isModelOpened = true;
  public typeList;
  public selectedType = [];
  public classType = [];
  public selectedClass = [];
  public selectedTerr = [];
  public selectedBeat = [];
  public beatList = [];
  public terrList = [];
  public confirmEnable = true;
  public datas;
  public paymentMode = '';
  public comments = '';
  public amount = 0;
  public out_amount = 0;
  public writeoff = false;
  public credit_note = false;
  public writeoff_amt = 0;
  public paymentModes = [{id: 1, name: 'cash'}, {id: 2, name: 'cheque'}, {id: 3, name: 'online'}];
  public retail_credits;
  public order_paids;
  public chequeNo;
  public chequeDate;
  public branch;
  public ref;
  public payDate: any;
  private API_URL: string;
  public custRefresh = true;
  private roleFeatures: any;
  public generateCredt = false;
  public codeEnable = false;
  public context;

  constructor(private apiService: ApiService,
              private sharedService: SharedService,
              private router: Router,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              private toastr: ToastrService,
              public dialog: MatDialog,
              private ngZone: NgZone,
              private dateTimeAdapter: DateTimeAdapter<any>) {

    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.user_data.date = localStorage.getItem('my_date');
    if (this.user_data.date == null || this.user_data.date == 'null') {
      this.user_data.date = this.myDate;
      localStorage.setItem('my_date', this.myDate);
    } else {
      this.myDate = this.user_data.date;
    }
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.user_data.supplier_id = JSON.parse(localStorage.getItem('user')).partner_id;

    this.roleFeatures = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_distributor_generate_credit' === existed.name;
    });

    if (this.roleFeatures.length > 0) {
      this.generateCredt = true;
    }

    let inthis = this;

    this.columnDefs = [
      { headerName: 'Name',headerClass: 'myagheader', field: 'name' , sortable: true, filter : true, resizable: true, lockPosition: true,
        checkboxSelection: true, width: 250, pinned: 'left' },
      { headerName: 'Code', headerClass: 'myagheader',field : 'code', sortable: true, filter : true,
        resizable: true, lockPosition: true, width: 150, pinned: 'left'},
      { headerName: 'Zone',headerClass: 'myagheader', field: 'zone', sortable: true, filter : true, resizable: true,
        lockPosition: true, width: 150 },
      { headerName: 'Area', headerClass: 'myagheader',field: 'area', sortable: true, filter : true,
        resizable: true, lockPosition: true, width: 150},
      { headerName: 'Mobile', headerClass: 'myagheader',field: 'mobile', sortable: true, filter : true, resizable: true,
        lockPosition: true},
      { headerName: 'Type',headerClass: 'myagheader', field: 'type', sortable: true, filter : true,
        resizable: true, lockPosition: true, width: 150},
      { headerName: 'State',headerClass: 'myagheader', field: 'state', sortable: true, filter : true,
        resizable: true, lockPosition: true},
      { headerName: 'Created By',headerClass: 'myagheader', field: 'created_by', sortable: true, filter : true,
        resizable: true, lockPosition: true,
        hide: true},
      { headerName: 'Status',headerClass: 'myagheader', field: 'status', sortable: true, filter : true, resizable: true,
        lockPosition: true},
      { headerName: 'Beat',headerClass: 'myagheader', field: 'beat', sortable: true, filter : true, resizable: true,
        lockPosition: true,
        valueGetter: function (params) {
        if (params.data.beat) {
          const beats = [...new Set(params.data.beat.map(item => item.bp))];
          return beats.join();
        } else {
          return params.data.beat;
        }
        }},
      { headerName: 'Territory',headerClass: 'myagheader', field: 'territory', sortable: true, filter : true, resizable: true,
        lockPosition: true},
      { headerName: 'Cluster',headerClass: 'myagheader', field: 'cluster', sortable: true, filter : true, resizable: true,
        lockPosition: true},
      { headerName: 'Create Date',headerClass: 'myagheader', field: 'create_date', sortable: true, filter : true,
        resizable: true, lockPosition: true,
        valueGetter: function (params) {
          return inthis.datePipe.transform((new Date(params.data.create_date + ' UTC')).toString(), 'dd/MM/yyyy HH:mm:ss');
        }},
      { headerName: 'Credit Limit', headerClass: 'myagheader',field: 'credit_limit', sortable: true, filter : true,
        resizable: true, lockPosition: true},
      { headerName: 'Outstanding',headerClass: 'myagheader', sortable: true, filter : true,
        resizable: true, lockPosition: true,
        cellRenderer: 'outstandingShowRenderer'},
      { headerName: 'Credits', headerClass: 'myagheader',field: 'credits', sortable: true, filter : true,
        resizable: true, lockPosition: true},
      { headerName: 'customer_id',headerClass: 'myagheader', field: 'id', hide: true},
      { headerName: 'user_id',headerClass: 'myagheader', hide: true}
    ];

    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    dateTimeAdapter.setLocale('en-IN');

    this.apiDmsOrderList();

    this.gridOptions = { rowHeight: 40,
      rowStyle: { 'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left' },
      rowSelection: 'single',
      suppressAggFuncInHeader: true,
      pivotMode: false,
      groupHideOpenParents: true,
      groupDefaultExpanded: 9,
      groupMultiAutoColumn: true,
      autoGroupColumnDef: {
        pinned: 'left'
      },
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
          { statusPanel: 'agFilteredRowCountComponent' },
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      }
    } as GridOptions;

  }

  close() {
    this.isModelOpened = true;
  }

  generateCredit() {
    debugger;
    let datas = {
      write_off: false,
      paid_amount: 0,
      invoices: [],
      payment_date: '',
      payment_type: '',
      comments: '',
      retailer: null,
      partner_id: null,
      user_id: null,
      credits: 0,
      code: null,
      ref_id: null,
      cheque_no: null,
      cheque_date: null,
      branch_name: null,
      credit_used: false,
      cr_used_credits: 0,
      credit_amt: 0,
      invoice_type: 'out_invoice'
    };
    let customer = this.gridApi.getSelectedRows();
    if (customer.length > 0) {
      datas.retailer = customer[0].name;
      datas.partner_id = customer[0].id;
      datas.user_id = customer[0].user_id;
      datas.code = customer[0].code;
      datas.credits = customer[0].credits;
      datas.cr_used_credits = datas.credits;
    }
    this.datas = datas;
    var paymentData: any = {};
    paymentData.amount = this.amount;
    paymentData.out_amount = customer[0].outstanding;
    paymentData.writeoff = this.writeoff;
    paymentData.writeoff_amt = this.writeoff_amt;
    paymentData.payDate  = this.payDate;
    paymentData.chequeDate = this.chequeDate;
    paymentData.chequeNo = this.chequeNo;
    paymentData.branch = this.branch;
    paymentData.ref = this.ref;
    paymentData.title = 'GENERATE CREDIT NOTE';
    paymentData.credit_creation = true;

    let config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "600px",
      maxHeight: "600px",
      data:{
        datas: this.datas,
        paymentData: paymentData
      }
    };
    const creatediaeref =  this.dialog.open(PaymentPopupComponent,config);
    creatediaeref.componentInstance.markPayment.subscribe(data => {
      this.createCreditNote(data);
    });
  }

  createCreditNote(params) {
    debugger;
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.datas.payment_date = params.payDate;
    this.datas.payment_type = params.paymentMode;
    this.datas.paid_amount = parseFloat(String(params.amount)).toFixed(2);
    this.datas.comments = params.comments;
    this.datas.write_off = params.writeoff;
    this.datas.cheque_date = params.chequeDate;
    this.datas.cheque_no = params.chequeNo;
    this.datas.branch = params.branch;
    this.datas.ref = params.ref;
    this.datas.credit_amt = params.amount;
    let data = {
      datas: this.datas,
      access_token: localStorage.getItem('resfreshToken')
    }
    this.API_URL = '/api/pwa_connects/generate_credit_note';
    this.apiService.post(this.API_URL, data)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          debugger;
          this.close();
          this.toastr.success('Payment Successfull');
          this.apiDmsOrderList();
        } else {
          this.toastr.error('Payment Failed' + res.results.msg);
        }
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      });
  }

  refreshCust() {
    this.custRefresh = false;
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    debugger;
    const inData2 = {
      access_token: localStorage.getItem('resfreshToken'),
      supplier_id: JSON.parse(localStorage.getItem('user')).partner_id
    };
    this.apiService.getRetailers(inData2)
      .subscribe(resp => {

        console.log(resp);
        // localStorage.setItem('customers_list', JSON.stringify(resp['results'].data));
        this.rowData = resp['results'].data;
        this.gridApi.setRowData(this.rowData);
        this.custRefresh = true;
        this.preloader = false;
        this.loaded = true;
        this.empty_state = false;
      }, error => {
        console.log(error);
        this.custRefresh = true;
        this.preloader = false;
        this.empty_state = true;
      });
  }

  toLedger(params) {
    debugger;
    this.sharedService.dmsParams.ret_id = params.id;
    this.router.navigate([this.apiService.redirect_url + '/reports/customer_ledger']);
  }

  apiDmsOrderList() {
    debugger;
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    let inthis = this;
    const rowdata = [];
    let customers = [];
    const datePipe = this.datePipe;
    this.API_URL = '/api/dms_connects/get_dms_retailers';
    const inData2 = {
      access_token: localStorage.getItem('resfreshToken'),
      supplier_id: JSON.parse(localStorage.getItem('user')).partner_id
      // url: this.url
    };
    this.apiService.post(this.API_URL, inData2)
      .subscribe(resp => {
        customers = resp['results'].data;
        this.rowData = customers;
        this.loaded = true;

        this.context = {componentParent: this};
        this.frameworkComponents = {
          outstandingShowRenderer: OutstandingShowComponent,
        };
      }, error => {
        console.log(error);
        this.toastr.warning('Customer List not Loaded');
        this.preloader = false;
        this.empty_state = true;
      });
    // if (customers && customers.length > 0) {
    //   this.rowData = customers;
    //   this.loaded = true;
    //
    //   this.frameworkComponents = {
    //     colorCellRenderer: ColorCellRenderer
    //   };
    // } else {
    //   this.toastr.warning('Customer List not Loaded yet');
    //   this.preloader = false;
    //   this.empty_state = true;
    // }
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inthis = this;
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          // params.api.sizeColumnsToFit();
          inthis.sharedService.resetPinned(inthis.gridOptions, [
            { colId: 'name', pinned: 'left' },
            { colId: 'code', pinned: 'left' }
          ]);
        } else {
          params.columnApi.autoSizeColumns();
          inthis.sharedService.clearPinned(inthis.gridOptions);
        }
      });
    });
    if (screen.width > 991) {
      inthis.sharedService.resetPinned(inthis.gridOptions, [
        { colId: 'name', pinned: 'left' },
        { colId: 'code', pinned: 'left' }
      ]);
    } else {
      inthis.gridColumnApi.autoSizeColumns();
      inthis.sharedService.clearPinned(inthis.gridOptions);
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {

    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable == 'true' ? 'addClass' : 'removeClass']

    (this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {

    this.user_data.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    localStorage.setItem('my_date',  this.user_data.date);
    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.apiDmsOrderList();
  }
}
