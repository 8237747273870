import {PageBase} from "../../../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";
import {ErrorHandler} from "../../../error/error-handler";
import {MatDialogConfig} from "@angular/material/dialog";
import {BulkInvoiceComponent} from "../sale_order/bulk_invoice/bulk_invoice";
import {ClaimGeneratePopupComponent} from "./claim_generate_popup/claim_generate_popup.component";


@Component({
  selector: 'app-claims-list',
  templateUrl: '../../../pagebase/pagebase.html',
  styleUrls: ['../../../pagebase/pagebase.scss']
})

export class ClaimsListComponent extends PageBase implements OnInit, IPage {

  customFilter: any = {
    name: 'Status',
    key: 'status',
    value: 'new',
    values: [],
    show: true
  };

  ngOnInit(): void {

    this.sharedService.setHeader('Claims');
    this.showCreate = true;
    this.showActionButton = true;
    this.customFilter.values = [
      {id: 'all', itemName: 'All'},
      {id: 'new', itemName: 'New'},
      {id: 'confirmed', itemName: 'Confirmed'},
      {id: 'approved', itemName: 'Approved'},
      {id: 'rejected', itemName: 'Rejected'},
    ];
    this.showCustomFilter = true;
    this.checkAndLoadPreviousSate();
    this.getAuthorizedActions();
    this.checkAccessControl();
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {

    this.API_URL = '/api/pwa_dms_connects/get_partner_claims';

    this.columnDefs = [
      {headerName: "ID", field: "id", width: 30},
      {headerName: "CLAIM NO", field: "name", width: 50,  checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true},
      {headerName: "DATE", field: "date", width: 60},
      {headerName: "TYPE", field: "type", width: 60},
      {headerName: "AMOUNT", field: "amount", width: 50,  aggFunc: 'sum', cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      {headerName: "STATUS", field: "status", width: 100},
      {headerName: "COMMENTS", field: "comments", width: 100},
      {headerName: "APPROVED BY", field: "apr_by", width: 50},
      {headerName: "APPROVED DATE", field: "apr_date", width: 50},
    ];

    this.getRowId = (params) => params.data.id;
  }

  onCellClicked(event) {
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    if (filteredRowData !== null && filteredRowData.length > 0) {
      const ids = [];
      filteredRowData.forEach(r => {
        ids.push(r.data.id);
      });
      localStorage.setItem('active_ids', JSON.stringify(ids));
      this.router.navigate([this.apiService.redirect_url + '/dms/claim_detail'], {
        relativeTo: this.route,
        queryParams: {id: rows[0].id}
      });
    }
  }

  checkAccessControl() {
    // this._localCacheService.getAccessControlList().then(list => {
   // this.showCreate = false;
    // });
  }

  openCreateDialog() {
    // this.router.navigate([this.apiService.redirect_url + '/dms/sale_invoice'], {
    //   relativeTo: this.route,
    //   queryParams: {id: 0 }
    // });
    const config: MatDialogConfig = {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal',
      data: {
        // supplier_territory_id: supp_territory_id
      },
    };

    const dialogRef = this.dialog.open(ClaimGeneratePopupComponent, config);
   /* dialogRef.componentInstance.bulkInvoiceCreate.subscribe(status => {
      if (status === 'success') {
        this.dialog.closeAll();
        window.location.reload();
      }
    });*/
  }

}
