import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'partial-reject-popup',
  templateUrl: './partial_reject_popup.component.html',
  styleUrls: ['./partial_reject_popup.component.scss']

})

export class PartialRejectPopupComponent implements OnInit {

  @Output() partialrejectsubmit: EventEmitter<any> = new EventEmitter<any>();

  title;
  rejectData: any = {};
  beatActivityData = [];

  constructor(
    public dialog: MatDialog,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.title = dialogdata.title;
    this.beatActivityData = dialogdata.beatActivityData;
    if (!this.beatActivityData || this.beatActivityData.length === 0) {
      this.toastr.error(this.title + ' reason configuration not set!!');
      this.closeDialog();
    } else {
      if (this.beatActivityData && this.beatActivityData.length > 0) {
        this.rejectData.reason_id = this.beatActivityData[0].id;
      }
    }
  }

  ngOnInit(): void {

  }

  performAction(){
    this.rejectData.reason_id = parseInt(this.rejectData.reason_id, 10);
    this.rejectData.reject_reason = this.beatActivityData.find(x => x.id === this.rejectData.reason_id).name;
    this.partialrejectsubmit.emit(this.rejectData);
    this.dialog.closeAll();
  }

  closeDialog(){
    this.dialog.closeAll();
  }

}
