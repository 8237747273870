import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { GridOptions } from "../../../node_modules/@ag-grid-enterprise/all-modules";
import { ApiService} from "../_services/api.service";
import { Router, ActivatedRoute  } from '@angular/router';
import { ColorCellRenderer } from "../colorCell.component";
import { DatePipe } from '@angular/common';
import { LoadingstateComponent} from "../components/loadingstate/loadingstate.component"

@Component({
  selector: 'app-settle-sale-report',
  templateUrl: './settle-sale-report.component.html',
  styleUrls: ['./settle-sale-report.component.scss']
})
export class SettleSaleReportComponent implements OnInit {

  user;
  res_data;
  user_data = {
    "date":null,
    "status": 'settle',
    "access_token": null,
    "url": null,
    "last_date": null
  };

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public title_name: string = "DC LIST";
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public report_flag;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef:ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe) {

    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.user_data.date = this.myDate[0];
    this.user_data.last_date = this.myDate[1];
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.report_flag = this.route.snapshot.params["report_flag"];

    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.rawsalereport();

    this.gridOptions = <GridOptions>{ rowHeight: 28,
      rowStyle: { 'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left' },
      rowSelection: 'single'};

  }

  rawsalereport() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    let rowdata = [];
    let loop_data = [];


    this.res_data = this.apiService.rawSaleReport(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status == 200) && (res['results'].data.length > 0)) {
          res['results'].data.forEach(function (data) {
            var pushData = {
              "Outlet": data.outlet,
              "User": data.user,
              "Outlet CODE": data.outlet_code,
              "Product Name": data.product_name,
              "Product CODE": data.product_code,
              "Line ID": data.line_id,
              "Outlet_ID": data.outlet_id,
              "Seq NO": data.sequence,
              "State": data.state,
              "Zone": data.zone,
              "Supplier ID": data.supplier_id,
              "Supplier": data.supplier,
              "Territory": data.territory,
              "Area": data.area,
              "User_ID": data.user_id,
              "Beat": data.beat,
              "Team": data.team,
              "GSTIN": data.retailer_gstin,
              "Order Date": data.order_date,
              "Server Date": data.server_date,
              "Mobile Date": data.mobile_date,
              "Check IN": data.check_in,
              "Check OUT": data.check_out,
              "Return": data.sale_return,
              "Quantity": data.qty,
              "Sale Type": data.type,
              "HSN": data.HSN,
              "Invoice ID": data.invoice_id,
              "Invoice": data.invoice,
              "PriceList": data.pricelist,
              "Product ID": data.product_id,
              "Product Category": data.catgeory,
              "Unit Price": data.ut_price,
              "PO": data.po,
              "GRN": data.grn,
              "Approval Status": data.approved,
              "Price Subtotal": data.price_subtotal,
              "Tax" : data.tax,
              "Amount Tax" : data.amount_tax,
              "CGST" : data.cgst,
              "SGST" : data.sgst,
              "Amount Total" : data.amount_total,
              "Pay Status" : data.pay_status,
              "Payment Mode" : data.mode
            };
            rowdata.push(pushData);
          });
          this.data = rowdata[0];
          this.rowData = rowdata;

          for (var key in this.data) {
            if (this.data.hasOwnProperty(key)) {
              if (key == 'Supplier ID' || key == 'User_ID' ||
                key == 'Product ID') {
                this.columnDefs.push({
                  headerName: key,
                  field: key,
                  sortable: true,
                  filter: false,
                  lockPosition: true,
                  width: 100
                });
              }else if (key == 'Zone' || key == 'Supplier' || key == 'Area' || key == 'Approval Status') {
                this.columnDefs.push({
                  headerName: key,
                  field: key,
                  sortable: true,
                  filter: false,
                  lockPosition: true,
                  width: 150
                });
              }else if (key == 'Seq NO') {
                this.columnDefs.push({
                  headerName: key,
                  field: key,
                  sortable: true,
                  filter: false,
                  lockPosition: true,
                  width: 70
                });
              }else if (key == 'Return' || key == 'Quantity' || key == 'HSN' || key == 'Unit Price' || key == 'Price Subtotal' ||
                key == 'Amount Untaxed' || key == 'Tax' || key == 'Amount Tax' || key == 'CGST' || key == 'SGST' ||
                key == 'Amount Total' || key == 'Pay Status' || key == 'Payment Mode') {
                this.columnDefs.push({
                  headerName: key,
                  field: key,
                  sortable: true,
                  filter: false,
                  lockPosition: true,
                  width: 90
                });
              }else if (key == 'Line ID' || key == 'Outlet_ID' || key == 'Outlet CODE' || key == 'Product CODE') {
                this.columnDefs.push({
                  headerName: key,
                  field: key,
                  sortable: true,
                  filter: false,
                  lockPosition: true,
                  width: 100,
                  pinned: 'left'
                });
              }else if (key == 'Outlet') {
                this.columnDefs.push({
                  headerName: key,
                  field: key,
                  sortable: true,
                  filter: false,
                  lockPosition: true,
                  width: 150,
                  pinned: 'left'
                });
              }else if (key == 'State' || key == 'User' || key == 'Product Category') {
                this.columnDefs.push({
                  headerName: key,
                  field: key,
                  sortable: true,
                  filter: false,
                  lockPosition: true,
                  width: 100
                });
              }else if (key == 'Territory') {
                this.columnDefs.push({
                  headerName: key,
                  field: key,
                  sortable: true,
                  filter: false,
                  lockPosition: true,
                  width: 320
                });
              }else if (key == 'Product Name') {
                this.columnDefs.push({
                  headerName: key,
                  field: key,
                  sortable: true,
                  filter: false,
                  lockPosition: true,
                  width: 250
                });
              }else if (key == 'Invoice' || key == 'PriceList') {
                this.columnDefs.push({
                  headerName: key,
                  field: key,
                  sortable: true,
                  filter: false,
                  lockPosition: true,
                  width: 150
                });
              }else if (key == 'Invoice ID') {
                this.columnDefs.push({
                  headerName: key,
                  field: key,
                  sortable: true,
                  filter: false,
                  lockPosition: true,
                  width: 250,
                });
              }else {
                this.columnDefs.push({ headerName: key, field: key, sortable: true, filter: false, lockPosition: true, })
              }
            }
          }
          this.loaded = true;

        }else {
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");
    // window.addEventListener('resize', function() {
    //   setTimeout(function() {
    //     if (screen.width > 991) {
    //       params.api.sizeColumnsToFit();
    //     }else {
    //       params.columnApi.autoSizeColumns();
    //     }
    //   });
    // });

    this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {

    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable == "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {

    this.user_data.date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.rawsalereport();
  }
}
