<section class="dialog-section" xmlns="http://www.w3.org/1999/html" xmlns="http://www.w3.org/1999/html">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Add Expense Claim</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <form novalidate id="ngForm" #expenseclaim="ngForm">
        <div style="display: flex">
          <div style="flex: 1">
            <span class="detaillabel2">Date</span>
            <div class="date-picker-form">
              <input [selectMode]="'single'"
                     [readonly]="true"
                     [(ngModel)]="expenseDetails.date"
                     [owlDateTimeTrigger]="dt"
                     [owlDateTime]="dt" placeholder="Expense Date"
                     name="expense_date"
                     #expense_date="ngModel"
                     style="display: inline; color: #2D2D2D; font-size: 14px;width: 100%; margin-top: 5px;">
              <div style=" position: absolute;right: 10px;top: 16px;">
                <span style="padding-left: 5px;"><i class="far fa-calendar-alt"></i></span>
              </div>
              <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
            </div>
          </div>
          <div style="flex: 1; margin-left: 15px;">
            <span class="detaillabel2">Claim Type</span>
            <div style="flex: 1; margin-right: 10px;" class="formselectwhitebg">
              <select
                name="claim_type"
                [(ngModel)]="expenseDetails.type"
                #claim_type="ngModel"
                style="width: 100%;margin-top: 5px;">
                <option
                  *ngFor="let item of types"
                  value="{{ item.id }}"
                >
                  {{ item.itemName }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px;">
          <span class="detaillabel2">Amount</span>
          <input
            id="tot_amount"
            class="forminputwhitebg"
            style="display: block; color: #0762AD; font-size: 22px; font-weight: 500;margin-top: 5px;width: 100%;height:45px;"
            type="number"
            name="amount"
            [(ngModel)]="expenseDetails.amount"
            (change)="onAmountChange()"
            #amount="ngModel"
            required
          >
          <div *ngIf="amount.errors && (amount.dirty || amount.touched)">
            <span *ngIf="amount.errors.required" class="text-danger">Amount is required</span>
          </div>
        </div>
        <div style="margin-top: 20px;">
          <span class="detaillabel2">Comments</span>
          <textarea
            class="forminputwhitebg"
            style="display: block; color: #7D7D7D; font-size: 14px;margin-top: 5px;width: 100%;height:100px;"
            type="text"
            name="comments"
            [(ngModel)]="expenseDetails.comments"
            #comments="ngModel"
            placeholder="Enter Comments"
          ></textarea>
        </div>
        <div class="row" style="margin-top:20px;">
          <div class="galleryrowgrid row centeralign">
            <ng-container *ngFor="let tile of expensephotos">
              <div style=" display: inline-block;align-items: center;">
                <img *ngIf="tile.type !== 'application/pdf'" [src]="tile.photos" (click)="showImagePopup(tile.photos)"
                     style="height: 100px;width:100px;margin-right: 8px;margin-bottom: 8px;cursor: pointer"/>
                <img *ngIf="tile.type === 'application/pdf'" src="./../../../assets/pdf_icon.png"
                     (click)="openPDFinNewTab(tile.photos)"
                     style="height: 100px;width:100px;margin-right: 8px;margin-bottom: 8px;cursor: pointer"/>
                <p style="display: block">{{ tile.name }}</p>
              </div>
            </ng-container>
            <div style=" display: inline-flex;align-items: center;">
              <input
                type="file"
                #file
                style="display:none"
                accept="image/png, image/jpeg, application/pdf"
                (change)="handleFileSelect($event)"/>
              <span style="margin-right:5px;display:inline" (click)="file.click()"
              >
                  <img src="assets/upload_picture.svg" width="40px"/></span>
              <span *ngIf="!expensephotos" style=" display: inline-flex;align-items: center;">Upload Picture</span>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="button-row" style="float: right; margin: 15px 30px 15px 0px;">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        class="btn confirm-blue-btn"
        (click)="createExpenseClaim()"
        form="ngForm"
        [disabled]="expenseclaim.form.invalid"
      >
        Save
      </button>
    </div>
  </div>
</section>
