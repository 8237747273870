import {Component, OnInit, ElementRef, Renderer2, ViewChild} from '@angular/core';
import { GridOptions } from "../../../node_modules/@ag-grid-enterprise/all-modules";
import { ApiService} from "../_services/api.service";
import { Router, ActivatedRoute  } from '@angular/router';
import { ColorCellRenderer } from "../colorCell.component";
import { DatePipe } from '@angular/common';
import { LoadingstateComponent} from "../components/loadingstate/loadingstate.component";
import {AllModules} from '@ag-grid-enterprise/all-modules';
import {PrintInvoiceComponent} from "../printInvoice.component";
import {PrintClickRenderer} from "../printClickRenderer.component";
import {DomSanitizer} from "@angular/platform-browser";
import {AppComponent} from "../app.component";
import {SharedService} from "../_services/shared_service";


@Component({
  selector: 'app-collection-report',
  templateUrl: './collection-report.component.html',
  styleUrls: ['./collection-report.component.scss']
})
export class CollectionReportComponent implements OnInit {
  @ViewChild('interPrint') public interPrint: ElementRef;

  user;
  res_data;
  user_data = {
    date: null,
    access_token: null,
    url: null,
    last_date: null,
    offset: null,
    type: null,
    payment_type: null
  };

  public modules = AllModules;

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public orderDetails = {};
  public all_orders = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public context;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public title_name = "LOADIN REPORT";
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public report_flag;
  public first_hide = false;
  public dms_enable = false;
  public API_URL = '';

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              public  domSanitizer: DomSanitizer,
              public appComp: AppComponent,
              public sharedService: SharedService,
              private datePipe: DatePipe) {

    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.user_data.date = this.myDate[0];
    this.user_data.last_date = this.myDate[1];
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.report_flag = this.route.snapshot.params["report_flag"];
    if (localStorage.getItem('dms_module') === 'true' || localStorage.getItem('module_dmsv2') === 'true') {
      this.dms_enable = true;
    }

    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      enableRangeSelection: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      pivotMode: false,
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      autoGroupColumnDef: {
        pinned: 'left'
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      }
    } as GridOptions;

  }

  collectionreport() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    const rowdata = [];
    const loop_data = [];
    const total = 0;
    const datePipe = this.datePipe;
    if (this.dms_enable) {
      this.API_URL = '/api/dms_connects/daily_collection_report';
    } else {
      this.API_URL = '/api/pwa_connects/daily_collection_report';
    }

    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          res['results'].data.forEach(function(data) {
            if (data.payment_type === 'Cr Used') {
              data.payment_type = 'Cr Note';
            } else if (data.payment_type === 'cash_and_credit') {
              data.payment_type = 'Credit With Payment';
            } else if (data.payment_type === 'credit') {
              data.payment_type = 'Credit Without Payment';
            } else if (data.payment_type === 'cash') {
              data.payment_type = 'Cash';
            } else if (data.payment_type === 'cheque') {
              data.payment_type = 'Cheque';
            }
            const pushData = {
              DATE: datePipe.transform((new Date(data.date_delivered + ' UTC')).toString(), 'dd/MM/yyyy'),
              TIME: datePipe.transform((new Date(data.date_delivered + ' UTC')).toString(), 'HH:mm:ss'),
              SALESMAN: data.user,
              BEAT: data.beat,
              OUTLET: data.outlet,
              "OUT ID": data.outlet_id,
              CODE: data.code,
              TYPE: data.type,
              ORDER: data.order,
              INVOICE: data.invoice,
              STATUS: data.p_status,
              "RCPT NO": data.rcpt_no,
              "BILL VALUE": parseFloat(data.total).toFixed(3),
              "PAID AMOUNT": parseFloat(data.paid).toFixed(3),
              PAYMENT: data.payment_type,
              COLLECTED: data.collected_by,
              'P DATE': datePipe.transform((new Date(data.payment_date + ' UTC')).toString(), 'dd/MM/yyyy'),
              'P TIME': datePipe.transform((new Date(data.payment_date + ' UTC')).toString(), 'HH:mm:ss'),
              BALANCE: (parseFloat(data.total) - parseFloat(data.total_paid)).toFixed(0),
              STATE: data.state,
              id: data.id,
              payment_id: data.payment_id
            };
            if (pushData['BALANCE'][0] === '-') { pushData['BALANCE'] = '0'; }
            rowdata.push(pushData);
          });
          this.data = rowdata[0];
          this.rowData = rowdata;

          for (const key in this.data) {
            if (this.data.hasOwnProperty(key)) {
              if (key === "PRINT") {
                this.columnDefs.push({
                  headerName: key, field: key, resizable: true, pinned: 'right', width: 120, sortable: true,
                  headerComponent: "printInvoicecomponent",
                  cellRenderer: "printClickRenderer",
                });
              } else if (key === 'SALESMAN' || key === 'CODE') {
                this.columnDefs.push({
                  headerName: key, field: key, resizable: true, pinned: 'left', filter: true, width: 150, sortable: true,
                });
              } else if (key === "DATE") {
                this.columnDefs.push({
                  headerName: key, field: key, resizable: true, pinned: 'left', filter: true, width: 150, sortable: true,
                  headerCheckboxSelection: true,
                  headerCheckboxSelectionFilteredOnly: true,
                  checkboxSelection: true
                });
              } else if (key === "STATE") {
                this.columnDefs.push({
                  headerName: key, field: key, resizable: true, width: 100, filter: true,
                  cellStyle(params) {
                    if (params.value !== null && params.value !== undefined) {
                      if (params.value === 'draft') {
                        return {'background-color': 'SkyBlue'};
                      } else if (params.value === 'rejected') {
                        return {'background-color': 'LightCoral'};
                      } else if (params.value === 'posted') {
                        return {'background-color': 'MediumSeaGreen'};
                      }
                    }
                  }
                });
              } else if (key === 'OUTLET') {
                this.columnDefs.push({
                  headerName: key, field: key, resizable: true, pinned: 'left', filter: true, width: 250, sortable: true,
                });
              } else if (key === 'ORDER') {
                this.columnDefs.push({
                  headerName: key, field: key, resizable: true, filter: true, width: 250, sortable: true,
                });
              } else if (key === "BILL VALUE" || key === 'BALANCE' || key === 'PAID AMOUNT') {
                this.columnDefs.push({
                  headerName: key, field: key, sortable: true, resizable: true, filter: true, width: 120, cellStyle: {textAlign: "right"},
                });
              }  else if (key === "id" || key === 'payment_id') {
                this.columnDefs.push({
                  headerName: key, field: key,
                  lockPosition: true, hide: true
                });
              } else {
                this.columnDefs.push({
                  headerName: key, field: key, sortable: true, filter: true, lockPosition: true, width: 120,
                  resizable: true, enableRowGroup: true, enablePivot: true, aggFunc: 'sum', enableValue: true
                });
              }
            }
          }
          this.loaded = true;
          this.context = {componentParent: this};

          this.frameworkComponents = {
            printInvoicecomponent: PrintInvoiceComponent,
            printClickRenderer: PrintClickRenderer
          };

        } else {
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }

  onCellClicked(event) {
    if (event.colDef.headerName === 'PRINT') {
      const rows = [{
        payment_id: event.data.payment_id
      }];

      this.printAll(rows);
    }
  }

  printAll(rows) {
    const pay_ids = [];
    this.all_orders = [];
    rows.forEach(van_row => {
      pay_ids.push(van_row['payment_id']);
    });

    const order_data = {
      access_token : this.user_data.access_token,
      url : this.user_data.url,
      data: {
        pay_ids,
      }
    };

    if (this.dms_enable) {
      this.API_URL = '/api/dms_connects/cash_receipt';
    } else {
      this.API_URL = '/api/pwa_connects/cash_receipt';
    }

    this.apiService.post(this.API_URL, order_data)
      .subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
        let orderDetails = [];
        const datePipe = this.datePipe;
        const domSanitizer = this.domSanitizer;
        const inthis = this;
        res['results'].data.forEach(function(data) {
          orderDetails = data;
          orderDetails['label_config'] = {};
          const labels = JSON.parse(localStorage.getItem('custom_labels'));
          labels.forEach(function(lab) {
            orderDetails['label_config'][lab.label] = lab.display;
          });
          orderDetails['invoice_date'] = datePipe.transform((new Date(orderDetails['date_invoice'] + ' UTC')).toString(), 'dd/MM/yyyy');
          orderDetails['payment_date'] = datePipe.transform((new Date(orderDetails['payment_date'] + ' UTC')).toString(), 'dd/MM/yyyy HH:mm:ss');
          if (orderDetails['payment_type'] === 'Cr Used') {
            orderDetails['receipt_type'] = 'Cr Note';
          } else if (orderDetails['payment_type'] === 'cash_and_credit') {
            orderDetails['receipt_type'] = 'Credit With Payment';
          } else if (orderDetails['payment_type'] === 'credit') {
            orderDetails['receipt_type'] = 'Credit Without Payment';
          } else if (orderDetails['payment_type'] === 'cash') {
            orderDetails['receipt_type'] = 'Cash';
          }
          if (orderDetails['logo']) {
            const res_data = inthis.apiService.fetchImage({
              access_token: inthis.user_data.access_token,
              url: inthis.user_data.url,
              data: [{store_fname: orderDetails['logo'], container: "res_partner"}]
            });
            res_data.subscribe(resp => {
                console.log(resp);
                orderDetails['logo'] = inthis.domSanitizer.bypassSecurityTrustUrl("data:image/png;base64, " + resp['result'][0].bill_datas);
                inthis.all_orders.push(orderDetails);
              },
              error => console.log(error)
            );
          } else {
            inthis.all_orders.push(orderDetails);
          }
        });
        this.triggerFalseClick2();
      }
    });
  }

  triggerFalseClick2() {


    const el: HTMLElement = this.interPrint.nativeElement;
    el.click();
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            // params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });

    if (screen.width > 991) {
      // params.api.sizeColumnsToFit();
    } else {
      params.columnApi.autoSizeColumns();
    }

    // this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {

    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {

    this.user_data.date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = this.myDate[0];
    const secondDate = this.myDate[1];
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    if (diffDays > 31) {
      window.alert('Please select 1 Month Data ');
    } else {
      //this.collectionreport();
    }
  }
}
