import {Component} from "@angular/core";
import {BaseUpload} from "../../pagebase/base_upload/base_upload";
import * as XLSX from "xlsx-js-style";

@Component({
  selector: 'app-task-planner-create-upload',
  templateUrl: '../../pagebase/base_upload/base_upload.html',
  styleUrls: ['../../pagebase/base_upload/base_upload.scss']

})

export class TaskPlannerCreateUploadComponent extends BaseUpload {

  quarterPeriodData = [];
  task_types = [];
  task_type: any = {};
  activityCodes = [];
  growthDrivers = [];
  masterData: any = {};

  setViewParams() {
    this.header = 'Task Planner Upload';
    this.sampleText1 = 'DOWNLOAD SAMPLE TEMPLATE';
    this.sampleText2 = 'DOWNLOAD MASTER DATA';
    this.excel_columns = [{C: 'A', H: 'State', V: 'UP', required: true},
      {C: 'B', H: 'Cluster', V: 'UP', required: true},
      {C: 'C', H: 'Category', V: 'MP Whisky', required: true},
      {C: 'D', H: 'Brand', V: 'RCW', required: true},
      {C: 'E', H: 'Channel', V: 'OFF', required: true},
      {C: 'F', H: 'Type', V: 'Planning', required: true},
      {C: 'G', H: 'Task Name', V: 'Table for Everyone', required: true}
    ];

    const inThis = this;
    let count = 7;
    this.task_types = JSON.parse(localStorage.getItem('task_types'));
    this.task_type = this.task_types.find(x => x.id.toString() === this.dialogData.type_id.toString());
    if (this.task_type && this.task_type?.param_json?.config) {
      for (let i = 0; i < this.task_type.param_json.config.length; i++) {
        count++;
        inThis.excel_columns.push({C: inThis.alphabets[i + 7], H: this.task_type.param_json.config[i].name, V: '', required: this.task_type.param_json.config[i].required});
      }
    }
    inThis.excel_columns.push(
      {C: inThis.alphabets[count++], H: 'Description', V: 'GOP', required: false},
      {C: inThis.alphabets[count++], H: 'Quarter', V: 'F24Q2', required: true},
      {C: inThis.alphabets[count++], H: 'Instructions', V: 'Only for Fine Dines & Premium Lounges', required: false}
    );
    this.step1Text = 'Download sample template for reference.';
    this.step2Text = 'Required fields are Cluster, Category, Brand, Channel, Task Name, Growth Driver and Activity Code';
    this.actionText = 'Create a New Task Plan';
    this.baseData = this.sampleData1;
    this.fetchMasterData();
  }

  sampleDownload1() {
    this.downloadSampleData('task_planner_template');
  }

  fetchMasterData() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    this.apiService.post('/api/tasks/fetch_master_data', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.masterData = res.results.data;
          this.dataLoaded = true;
        } else {
          this.toastr.error("Failed to fetch Master Data");
        }
      });
  }

  sampleDownload2() {
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(this.masterData.cluster), "Cluster");
    XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(this.masterData.state), "State");
    XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(this.masterData.channel), "Channel");
    XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(this.masterData.category), "Category");
    XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(this.masterData.brand), "Brand");
    XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(this.masterData.quarter), "Quarter");
    const types = [];
    this.task_types = JSON.parse(localStorage.getItem('task_types'));
    const spl_task_type = this.task_types.filter(x => x.type === 'spl');
    spl_task_type.forEach(type => {
      types.push({name: type.itemname});
    });
    XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(types), "Types");
    this.task_type = this.task_types.find(x => x.id.toString() === this.dialogData.type_id.toString());
    if (this.task_type && this.task_type?.param_json?.config) {
      for (let i = 0; i < this.task_type.param_json.config.length; i++) {
        if (this.task_type.param_json.config[i].type === 'selection') {
          const data = [];
          this.task_type.param_json.config[i].data.forEach(config => {
            data.push({name: config});
          });
          XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(data), this.task_type.param_json.config[i].name);
        }
      }
    }
    XLSX.writeFile(wb, "master_data.xlsx");
  }

  configureGrid() {
    this.API_URL = '/api/tasks/task_plan_upload';
    this.columnDefs = [
      {headerName: 'State', field: 'state', width: 100},
      {headerName: 'Cluster', field: 'cluster', width: 100},
      {headerName: 'Category', field: 'category', width: 100},
      {headerName: 'Brand', field: 'brand', width: 100},
      {headerName: 'Channel', field: 'channel', width: 100},
      {headerName: 'Type', field: 'type', width: 100},
      {headerName: 'Task Name', field: 'name', width: 150},
      {headerName: 'Activity Code', field: 'activity_code', width: 100},
      {headerName: 'Growth Driver', field: 'growth_driver', width: 100},
      {headerName: 'Description', field: 'description', width: 100},
      {headerName: 'Quarter', field: 'quarter', width: 100},
      {headerName: 'Instructions', field: 'instructions', width: 150},
      {headerName: 'Task Code', field: 'code', width: 250}
    ];
  }

  validate() {
    const inThis = this;
    this.validRecords = 0;
    this.errorRecords = 0;
    this.quarterPeriodData = JSON.parse(localStorage.getItem('jc_quarter'));
    this.task_types = JSON.parse(localStorage.getItem('task_types'));
    this.task_type = this.task_types.find(x => x.id.toString() === this.dialogData.type_id.toString());
    const uniqueCodes = new Set();
    let combiset = false;
    const params = [];
    if (inThis.task_type && inThis.task_type?.param_json?.config) {
      inThis.task_type.param_json.config.forEach(param => {
        params.push(param.field);
      });
    }
    this.uploaded_data.forEach(data => {
      const indrow: any = {};
      indrow.description = data['Description'];
      if (!data['Task Name'] || data['Task Name'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Task Name Missing';
      } else {
        indrow.name = data['Task Name'];
      }
      if (!this.task_type) {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Task Type Config Missing';
      } else {
        indrow.type_id = inThis.task_type.id;
      }
      if (!data['Type'] || data['Type'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Task Type Missing';
      } else if (!this.taskTypeValidation(data['Type'])) {
        indrow.type = data['Type'];
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid Type';
      } else {
        const type = this.task_types.find(x => x.itemname === data['Type']);
        indrow.type = type.itemname;
        indrow.type_id = type.id;
      }
      if (!data['State'] || data['State'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'State Missing';
      } else if (inThis.masterData.state && inThis.masterData.state.length > 0 && inThis.masterData.state.find(x => x.name === data['State'])) {
        indrow.state = data['State'];
      } else {
        indrow.state = data['State'];
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid State';
      }
      if (!data['Cluster'] || data['Cluster'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Cluster Missing';
      } else if (inThis.masterData.cluster && inThis.masterData.cluster.length > 0 && inThis.masterData.cluster.find(x => x.name === data['Cluster'])) {
        indrow.cluster = data['Cluster'];
      } else {
        indrow.cluster = data['Cluster'];
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid Cluster';
      }
      if (!data['Category'] || data['Category'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Category Missing';
      } else if (inThis.masterData.category && inThis.masterData.category.length > 0 && inThis.masterData.category.find(x => x.name === data['Category'])) {
        indrow.category = data['Category'];
      } else {
        indrow.category = data['Category'];
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid Category';
      }
      if (!data['Brand'] || data['Brand'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Brand Missing';
      } else if (inThis.masterData.brand && inThis.masterData.brand.length > 0 && inThis.masterData.brand.find(x => x.name === data['Brand'])) {
        indrow.brand = data['Brand'];
      } else {
        indrow.brand = data['Brand'];
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid Brand';
      }
      if (!data['Channel'] || data['Channel'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Channel Missing';
      } else if (inThis.masterData.channel && inThis.masterData.channel.length > 0 && inThis.masterData.channel.find(x => x.name === data['Channel'])) {
        indrow.channel = data['Channel'];
      } else {
        indrow.channel = data['Channel'];
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid Channel';
      }
      if (!data['Quarter'] || data['Quarter'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Quarter Missing';
      } else if (inThis.masterData.quarter && inThis.masterData.quarter.length > 0 && inThis.masterData.quarter.find(x => x.code === data['Quarter'])) {
        indrow.quarter = data['Quarter'];
      } else {
        indrow.quarter = data['Quarter'];
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid Quarter';
      }
      indrow.instructions = data['Instructions'];
      if (inThis.task_type && inThis.task_type?.param_json?.config) {
        inThis.task_type.param_json.config.forEach(param => {
          indrow[param.field] = data[param.name];
          if (param.required) {
            if (!indrow[param.field] || indrow[param.field] === '') {
              indrow.valid = 'Invalid';
              indrow.errorMsg = param.name + ' Missing';
            } else {
              inThis.paramValidate(param, indrow);
            }
          } else {
            inThis.paramValidate(param, indrow);
          }
        });
      }
      if (indrow.valid === 'Invalid') {
        this.errorRecords++;
      } else {
        indrow.valid = 'Valid';
        indrow.partner_type = 'retailer';
        indrow.code = indrow.cluster + '-' + indrow.brand + '-' + indrow.channel + '-' + indrow.activity_code + '-' + indrow.growth_driver + '-' + indrow.quarter;
        let combi = "";
        for (const [key, value] of Object.entries(indrow)) {
          combi += value;
        }
        /*indrow['params'] = [];
        indrow['param_val'] = "";
        if (inThis.task_type && inThis.task_type?.param_json?.config) {
          inThis.task_type.param_json.config.forEach(param => {
            if (indrow[param.field]) {
              indrow['params'].push(param.field);
              indrow['param_val'] += indrow['param_val'] + "," + "config_json->'task_config'->>'" + param.field + "'";
            }
          });
        }
        if (indrow['param_val'] && indrow['param_val'].length > 0) {
          indrow['param_val'] = indrow['param_val'].substring(1, indrow['param_val'].length);
        }*/
        if (uniqueCodes && uniqueCodes.has(combi)) {
          indrow.valid = 'Invalid';
          indrow.errorMsg = 'Duplicate Task';
        } else {
          uniqueCodes.add(combi);
          if (!combiset) {
            indrow['task_params'] = params;
            combiset = true;
          }
          this.validRecords++;
        }
      }
      this.rowData.push(indrow);
    });
    this.totalRecords = this.rowData.length;
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setRowData(this.rowData);
    }
  }

  paramValidate(param, indrow) {
    if (!indrow[param.field] || indrow[param.field] === '') {
      return;
    } else if (param.type) {
      if (param.type === 'integer' && !this.numPattern.test(indrow[param.field])) {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid ' + param.name + ' ' + param.type + ' format';
      } else if (param.type === 'decimal' && !this.floatPattern.test(indrow[param.field])) {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid ' + param.name + ' ' + param.type + ' format';
      } else if (param.type === 'selection' && param.data && !param.data.includes(indrow[param.field])) {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid ' + param.name;
      } else if (param.type === 'date') {
        indrow[param.field] = this.sharedService.convertExcelStringToDate(indrow[param.field]);
        if (!indrow[param.field] || indrow[param.field] === '') {
          indrow.valid = 'Invalid';
          indrow.errorMsg = 'Invalid ' + param.name;
        }
      }
    } else {
      return;
    }
  }

  taskTypeValidation(type) {
    let found = false;
    for (const key in this.task_types) {
      if (this.task_types[key]['itemname'] === type && this.task_types[key]['type'] === 'spl') {
        found = true;
      }
    }
    return found;
  }
}
