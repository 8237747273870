import {Component, ElementRef, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "../../../../../_services/api.service";
import {SharedService} from "../../../../../_services/shared_service";
import {ToastrService} from "ngx-toastr";
import {DatePipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {LocalforageService} from "../../../../../_services/localforage.service";
import {DomSanitizer} from "@angular/platform-browser";
import {AppComponent} from "../../../../../app.component";
import {ImagePopupComponent} from "../../../../../components/image_popup/image_popup.component";

@Component({
  selector: 'app-add-claim-expense-popup',
  templateUrl: './add_claim_expense_popup.component.html'
})

export class AddClaimExpensePopupComponent implements OnInit {

  expenseDetails: any = {};
  types = [];
  expensephoto = null;
  expensephotos = [];
  expensephotodata = null;
  expensephotodatas = [];
  expenseFileData = {};

  constructor(public dialog: MatDialog,
              public apiService: ApiService,
              public sharedService: SharedService,
              public toastr: ToastrService,
              public elRef: ElementRef,
              public datePipe: DatePipe,
              protected route: ActivatedRoute,
              protected _localCacheService: LocalforageService,
              public router: Router,
              public domSanitizer: DomSanitizer,
              public appComponent: AppComponent,
              public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.types = [{id: 1, itemName: 'Dsr Claim'}];
    this.expenseDetails.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  changeDate(dt) {

  }

  onAmountChange() {

  }

  handleFileSelect(evt) {
    // console.log(evt);
    const files = evt.target.files;
    const file = files[0];
    this.expenseFileData = {};
    if (files && file) {
      const reader = new FileReader();
      this.expenseFileData['name'] = file.name;
      this.expenseFileData['type'] = file.type;
      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt, file) {
    const binaryString = readerEvt.target.result;
    const base64textString = btoa(binaryString);
    this.expensephotodata = base64textString;
    this.expensephotodatas.push({
      name: this.expenseFileData['name'],
      type: this.expenseFileData['type'],
      photodata: base64textString
    });
    console.log(this.expensephotos);
    if (this.expenseFileData['type'] === 'application/pdf') {
      this.expensephoto = this.domSanitizer.bypassSecurityTrustUrl('data:application/pdf;base64, ' + base64textString);
    } else {
      this.expensephoto = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + base64textString);
    }
    this.expensephotos.push({
      name: this.expenseFileData['name'],
      type: this.expenseFileData['type'],
      photos: this.expensephoto
    });
  }

  showImagePopup(image) {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: '100vw',
        height: '100vh',
        maxWidth: '70%',
        maxHeight: '95%',
        data: {
          store_fname: '',
          photo: image,
          container: 'expense'
        }
      };

      const creatediaeref = this.dialog.open(ImagePopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: '100vw',
        height: '100vh',
        maxWidth: '90%',
        maxHeight: '65%',
        data: {
          store_fname: '',
          photo: image,
          container: 'expense'
        }
      };

      const creatediaeref = this.dialog.open(ImagePopupComponent, config);
    }

  }

  openPDFinNewTab(pdf) {
    if (pdf && pdf !== 'null') {
      let pdfSrc = pdf.toString();
      pdfSrc = pdfSrc.replace('SafeValue must use [property]=binding: data:application/pdf;base64, ', '');
      pdfSrc = pdfSrc.replace(' (see http://g.co/ng/security#xss)', '');
      let byteCharacters;
      if (pdfSrc === "null") {
        console.log(pdf);
        this.toastr.error("Invalid PDF");
      } else {
        try {
          byteCharacters = atob(pdfSrc);
        } catch (e) {
          console.log(pdf);
          this.toastr.error("Invalid PDF");
        }
        if (byteCharacters) {
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const file = new Blob([byteArray], {type: 'application/pdf'});
          const blobUrl = URL.createObjectURL(file );
          window.open(blobUrl);
        }
      }
    } else {
      console.log(pdf);
      this.toastr.error("Invalid PDF");
    }

  }

  createExpenseClaim() {

  }


}
