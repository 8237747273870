import {Component, ElementRef, OnInit, Renderer2} from "@angular/core";
import {MenuMasterList} from "./menuMaster";
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import {ApiService} from "../../_services/api.service";
import {SharedService} from "../../_services/shared_service";

@Component({
  selector: 'app-sidebar-v2',
  templateUrl: './sidebarv2.component.html',
  styleUrls: ['./sidebarv2.component.scss']
})


export class SidebarV2Component implements OnInit {

  menuList = [];
  constructor(private elRef: ElementRef,
              private renderer: Renderer2,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private apiService: ApiService,
              public sharedService: SharedService) {
  }

  ngOnInit(): void {
    this.sharedService.prepareMenuList();
    console.log(this.sharedService.menuList);
  }

  goToDashboard() {
    localStorage.setItem('my_date', null);
    if (this.sharedService.role_features) {
      if (this.sharedService.ACLcheck('feature_custom_dashboard')) {
        this.router.navigate([this.apiService.redirect_url + '/dash']);
      } else {
        this.router.navigate([this.apiService.redirect_url + '/boards/board_one']);
      }
    } else {
      this.router.navigate([this.apiService.redirect_url + '/boards/board_one']);
    }
    const menuList = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
    this.clearActive(menuList);
    this.renderer.addClass(document.getElementById('dashboard'), 'active');
    $("#img_dashboard").attr('src', this.getIcon("dashboard_selected"));
  }

  gotoReports() {
    this.router.navigate([this.apiService.redirect_url + '/reports']);

    const menuList = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
    this.clearActive(menuList);
    this.renderer.addClass(document.getElementById('report'), 'active');
    this.renderer.addClass( document.getElementById('a_report'), 'active');
    $("#img_report").attr('src', this.getIcon("report_selected"));
  }

  changeActive(route) {
    let activeID = '';
    let type = '';
    let menuItem: any = {};
    let subMenuItem: any = {};

    for (let i = 0; i < this.sharedService.menuList.length; i++) {
      if (this.sharedService.menuList[i].route && this.sharedService.menuList[i].route === route) {
        menuItem = this.sharedService.menuList[i];
        type = 'menu';
        break;
      }
      if (this.sharedService.menuList[i].sub_menu && this.sharedService.menuList[i].sub_menu.length > 0) {
        for (let j = 0; j < this.sharedService.menuList[i].sub_menu.length; j++) {
          if (this.sharedService.menuList[i].sub_menu[j].route && this.sharedService.menuList[i].sub_menu[j].route === route) {
            subMenuItem = this.sharedService.menuList[i].sub_menu[j];
            menuItem = this.sharedService.menuList[i];
            type = 'submenu';
            break;
          }
        }
      }
    }

    if (type === 'menu') {
      route = menuItem.route;
      activeID = this.constructID(menuItem.name);
    } else {
      route = subMenuItem.route;
      activeID = this.constructID(menuItem.name + '_' + subMenuItem.name);
    }
    if (route) {
      this.router.navigate([this.apiService.redirect_url + route]);
    }
    const menuList = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
    this.clearActive(menuList);
    //this.clearActive(subMenuItem);
    this.setActive(menuList, menuItem, subMenuItem, activeID, type);
  }

  setActive(menuList, menuItem, subMenuItem, activeID, type) {
    const inThis = this;
    menuList.forEach(item => {
      if (item.id === activeID) {
        inThis.renderer.addClass(item, 'active');
        inThis.renderer.addClass( document.getElementById('a_' + item.id), 'active');
        if (subMenuItem.icon && subMenuItem.icon !== '') {
          $("#img_" + item.id).attr('src', inThis.getIcon(subMenuItem.icon + "_selected"));
        }
      }
    });
    if (type === 'submenu') {
      menuList.forEach(item => {
        if (item.id === inThis.constructID(menuItem.name)) {
          inThis.renderer.addClass(item, 'active');
          inThis.renderer.addClass( document.getElementById('a_' + item.id), 'active');
          $("#img_" + item.id).attr('src', inThis.getIcon(menuItem.icon + "_selected"));
        }
      });
    }
  }
  goToRoute(menuItem, subMenuItem, type) {
    console.log(type);
    let route = '';
    let activeID = '';
    if (type === 'menu') {
      route = menuItem.route;
      activeID = this.constructID(menuItem.name);
    } else {
      route = subMenuItem.route;
      activeID = this.constructID(menuItem.name + '_' + subMenuItem.name);
    }
    if (route) {
      this.router.navigate([this.apiService.redirect_url + route]);
    }
    const menuList = this.elRef.nativeElement.querySelectorAll('.sidebar ul li');
    this.clearActive(menuList);
    //this.clearActive(subMenuItem);
    const inThis = this;
    menuList.forEach(item => {
      if (item.id === activeID) {
        inThis.renderer.addClass(item, 'active');
        inThis.renderer.addClass( document.getElementById('a_' + item.id), 'active');
        if (subMenuItem.icon && subMenuItem.icon !== '') {
          $("#img_" + item.id).attr('src', inThis.getIcon(subMenuItem.icon + "_selected"));
        }
      }
    });
    if (type === 'submenu') {
      menuList.forEach(item => {
        if (item.id === inThis.constructID(menuItem.name)) {
          inThis.renderer.addClass(item, 'active');
          inThis.renderer.addClass( document.getElementById('a_' + item.id), 'active');
          $("#img_" + item.id).attr('src', inThis.getIcon(menuItem.icon + "_selected"));
        }
      });
    }
  }

  constructID(name) {
    return name.toString().toLowerCase().replace(" ", "_");
  }
  clearActive(menuList) {
    const inThis = this;
    menuList.forEach(item => {
      inThis.renderer.removeClass(item, 'active');
      inThis.renderer.removeClass( document.getElementById('a_' + item.id), 'active');
      if ( $("#img_" + item.id).attr('src')) {
        $("#img_" + item.id).attr('src', $("#img_" + item.id).attr('src').replace('_selected',''));
      }
    });
  }

  getIcon(img) {
    return './../../../assets/menu/' + img + '.svg';
  }
}
