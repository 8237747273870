import {Component, OnInit, ElementRef, Renderer2, ViewChild} from '@angular/core';
import { GridOptions } from "../../../node_modules/@ag-grid-enterprise/all-modules";
import { ApiService} from "../_services/api.service";
import { Router } from '@angular/router';
import { ColorCellRenderer } from "../colorCell.component";
import { DatePipe } from '@angular/common';
import { LoadingstateComponent} from "../components/loadingstate/loadingstate.component";
import { DateTimeAdapter } from 'ng-pick-datetime';

@Component({
  selector: 'app-po2',
  templateUrl: './po2.component.html',
  styleUrls: ['./po2.component.scss']
})
export class Po2Component implements OnInit {
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;

  user;
  res_data;
  user_data = {
      "date":null,
      "po": null,
      "access_token": null,
      "url": null,
      "offset": null,
    "partner_id": null
  };

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  private gridApi2;
  private gridColumnApi2;
  public columnDefs2 = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public rowData = [];
  public rowData2 = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public gridOptions2: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public data2 = [];
  public loaded2 = false;
  public preloader2 = false;
  public empty_state2 = false;
  public styleGrid;
  public myDate;
  public collapse_disable;
  public orderDetails = {};
  public payment_enable = true;

  constructor(private apiService: ApiService,
              private router: Router,
              private elRef:ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              private dateTimeAdapter: DateTimeAdapter<any>) {

    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.user_data.date = localStorage.getItem('my_date');
    if (this.user_data.date == null || this.user_data.date == "null") {
      this.user_data.date = this.myDate;
      localStorage.setItem('my_date', this.myDate);
    }else {
      this.myDate = this.user_data.date;
    }
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.po = JSON.parse(localStorage.getItem('purchase_params'))['PO'];
    this.user_data.partner_id = JSON.parse(localStorage.getItem('purchase_params')).out_id;
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');

    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    dateTimeAdapter.setLocale('en-IN');

    this.apiPoList();

    this.gridOptions = <GridOptions>{ rowHeight:35,
                                      rowStyle: { 'text-align': 'left', 'padding': '20px 0px' },
                                      rowSelection: 'single',
                                      suppressHorizontalScroll: true
                                    };

    this.gridOptions2 = <GridOptions>{ rowHeight:35,
      rowStyle: { 'text-align': 'left', 'padding': '20px 0px' },
      rowSelection: 'single'
    };

  }

  apiPoList() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    let rowdata = [];
    let rowdata2 = [];


    this.res_data = this.apiService.getpoDetails(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status == 200)) {
          res['results'].data.prod_data.forEach(function (data) {
              rowdata.push({
                "PRODUCT": data.product,
                "ORDER QTY": data.ordered,
                "DELIVERED QTY": data.delivered
              });
          });
          res['results'].data.inv_data.forEach(function (data) {
              rowdata2.push({
                  "DATE": data.sale_date,
                  "INVOICE": data.invoice_no,
                  "OUTLET": data.outlet,
                  "CODE": data.code,
                  "VAN": data.van,
                  "VALUE": data.amount_total,
                "order_id": data.id
                });
          });
          this.data = rowdata[0];
          this.rowData = rowdata;

          this.data2 = rowdata2[0];
          this.rowData2 = rowdata2;

          for (var key in this.data) {
            if (this.data.hasOwnProperty(key)) {
                if (key == "DC") {
                  this.columnDefs.push({
                    headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true,
                    cellRenderer: function (params) {
                      var displayElem = '<b>' + params.value + '</b>';
                      return displayElem;
                    },
                  });

                }else {
                  this.columnDefs.push({
                    headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true,
                  });
                }
            }
          }
          this.loaded = true;

          for (var key in this.data2) {
            if (this.data2.hasOwnProperty(key)) {
              if (key == "DC") {
                this.columnDefs2.push({
                  headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true,
                  cellRenderer: function (params) {
                    var displayElem = '<b>' + params.value + '</b>';
                    return displayElem;
                  },
                });

              }else if (key == 'order_id') {
                this.columnDefs2.push({
                  headerName: key, field: key, sortable: true, hide: true, unSortIcon: true, lockPosition: true,
                });
              }else {
                this.columnDefs2.push({
                  headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true,
                });
              }
            }
          }
          this.loaded2 = true;

          this.frameworkComponents = {
            colorCellRenderer: ColorCellRenderer
          };

        }else {
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }

  triggerFalseClick() {

    let el: HTMLElement = this.myDiv.nativeElement;
    el.click();
  }

  onCellClicked(event) {


      let order_data = {
        "access_token": this.user_data.access_token,
        "url": this.user_data.url,
        "order_id": [event.data['order_id']]
      };

      this.res_data = this.apiService.getOrderDetail(order_data);

      this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].status == 200) && (res['results'].data.length > 0)) {
            this.orderDetails = res['results'].data[0];
            this.orderDetails['order_date'] = this.datePipe.transform((new Date(this.orderDetails['order_date'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss')
            if (this.orderDetails['date_delivered'] != null) this.orderDetails['date_delivered'] = this.datePipe.transform((new Date(this.orderDetails['date_delivered'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss')
            if (this.orderDetails['pay_items'].length == 0) {
              this.payment_enable = false;
            } else {
              let datePipe = this.datePipe;
              this.orderDetails['pay_items'].forEach(function (pay_item) {
                pay_item['payment_date'] = datePipe.transform((new Date(pay_item['payment_date'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
              });
              this.payment_enable = true;
            }
          } else {
            this.orderDetails = {};
            this.payment_enable = false;
          }
          this.triggerFalseClick();
          // }
        },
        error => {
          console.log(error);
        }
      );


  }



  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    this.preloader = false;
    this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        }else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    }
    else {
      this.gridColumnApi.autoSizeColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onGridReady2(params) {
    this.gridOptions2.api.showLoadingOverlay();

    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData2);
    this.preloader2 = false;
    this.gridApi2.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        }else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions2.api.sizeColumnsToFit();
    }
    else {
      this.gridColumnApi2.autoSizeColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {

    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable == "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {

    this.user_data.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    localStorage.setItem('my_date',  this.user_data.date);
    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.apiPoList();
  }
}
