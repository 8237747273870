<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Credit Note</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div>
        <span class="detaillabel2">Customer</span>
        <div class="formselect">
          <ng-select [items]="customerList"
                     bindLabel="name"
                     bindValue="id"
                     (ngModelChange)="customerChangeEvent($event)"
                     [(ngModel)]="activeObject.partner_id">
            >
          </ng-select>
        </div>
        <div class="cardbox" style="margin-top: 15px;width: 100%" *ngIf="activeObject.partner_id > 0">
          <div class="row">
            <div class="col-md-12 pad0">
              <div class="col-md-4 pl-0 pr-30">
                <label class="detaillabel">Code</label>
                <span class="detaillabel2" style="display: block;">{{activeObject.customer_code}}</span>
              </div>
              <div class="col-md-4 pl-0 pr-30">
                <label class="detaillabel">Outstanding</label>
                <span class="detaillabel2" style="display: block;">{{activeObject.balance}}</span>
              </div>
              <div class="col-md-4 pl-0 pr-30">
                <label class="detaillabel">Credits Available</label>
                <span class="detaillabel2" style="display: block;">{{activeObject.credit}}</span>
              </div>
            </div>
            <div class="col-md-12 pad0" style="margin-top: 10px;">
              <div class="col-md-4 pl-0 pr-30">
                <label class="detaillabel">GSTIN</label>
                <span class="detaillabel2" style="display: block;">{{activeObject.customer_gst_id}}</span>
                <div *ngIf="gst_verified">
                  <span style="color:#379862; font-weight: 500;">Verified</span>
                  <span style="margin-left:5px;display:inline;height:12px;width:12px;" >
                 <img style="margin-bottom: 2px;"  src="assets/tickteal.svg" width="10px" />
                            </span>
                </div>

              </div>
              <div class="col-md-8 pl-0 pr-30">
                <label class="detaillabel">Address</label><br>
                <span class="detaillabel2">{{activeObject.customer_address}}</span>
              </div>

            </div>
          </div>
        </div>


        <div style="margin-top: 20px;">
          <span class="detaillabel2">Credit Note Amount</span>
          <input
            class="forminputwhitebg"
            style="display: block; color: #0762AD; font-size: 14px; font-weight: 500;margin-top: 5px;width: 100%;"
            type="text"
            name="amount"
            [(ngModel)]="credit_note_amount"
            #amount="ngModel"
            placeholder="Enter Credit Amount"
          >
        </div>
        <div style="margin-top: 20px;">
          <span class="detaillabel2">Comments</span>
          <textarea
            class="forminputwhitebg"
            style="display: block; color: #7D7D7D; font-size: 14px;margin-top: 5px;width: 100%;height:100px;"
            type="text"
            name="narration"
            [(ngModel)]="credit_note_narration"
            #narration="ngModel"
            placeholder="Enter Comments"
          ></textarea>
        </div>
        <div class="fill">

        </div>
      </div>
    </div>
    <div class="button-row" style="float: right; margin: 15px 30px 15px 0px;">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        class="btn confirm-blue-btn"
        (click)="onCreditNoteSaveClick()"
      >
        Save
      </button>
    </div>
  </div>
</section>
