import {Component, OnInit, ElementRef, Renderer2, ViewChild} from '@angular/core';
import { GridOptions } from "../../../node_modules/@ag-grid-enterprise/all-modules";
import { ApiService} from "../_services/api.service";
import { SharedService } from "../_services/shared_service";
import { Router } from '@angular/router';
import { ColorCellRenderer } from "../colorCell.component";
import { DatePipe } from '@angular/common';
import { LoadingstateComponent} from "../components/loadingstate/loadingstate.component";
import { DateTimeAdapter } from 'ng-pick-datetime';
import * as XLSX from "xlsx-js-style";
import {DomSanitizer} from "@angular/platform-browser";
import {PrintInvoiceComponent} from "../printInvoice.component";
import {PrintClickRenderer} from "../printClickRenderer.component";

@Component({
  selector: 'app-receivedpo',
  templateUrl: './po_returns.component.html',
  styleUrls: ['./po_returns.component.scss']
})
export class Po_returnsComponent implements OnInit {
  @ViewChild('interPrint') public interPrint:ElementRef;

  user;
  res_data;
  user_data = {
      "date":null,
      "last_date": null,
      "dc_id": null,
      "access_token": null,
      "url": null,
      "offset": null,
      "loadout_data": null,
      "dc_centers": [],
      ref: null
  };

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public context;
  public all_orders = [];
  public orderDetails = {};
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public uploadCaption = ["", 0];
  public fileUploaded: File;
  public storeData: any;
  public worksheet: any;
  public uploading = false;
  public upload_loader = false;
  public upload_enable = false;
  public upload_po_data;
  public po_data = [];
  public dcs = [];
  private a = ['Zero ','One ','Two ','Three ','Four ', 'Five ','Six ','Seven ','Eight ','Nine ','Ten ','Eleven ','Twelve ','Thirteen ',
    'Fourteen ','Fifteen ','Sixteen ','Seventeen ','Eighteen ','Nineteen '];
  private b = ['', '', 'Twenty ','Thirty ','Forty ','Fifty ', 'Sixty ','Seventy ','Eighty ','Ninety '];


  constructor(private apiService: ApiService,
              private sharedService: SharedService,
              private router: Router,
              private elRef:ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              public  domSanitizer: DomSanitizer,
              private dateTimeAdapter: DateTimeAdapter<any>) {

    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.user_data.date = this.myDate[0];
    this.user_data.last_date = this.myDate[1];
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.dc_id = JSON.parse(localStorage.getItem('user')).partner_id;
    this.user_data.dc_centers = JSON.parse(localStorage.getItem('user')).dc_centers;
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    if (this.sharedService.gotoGrv.active) {
      this.user_data.ref = this.sharedService.gotoGrv.po;
      this.sharedService.gotoGrv.active = false;
      this.sharedService.gotoGrv.po = '';
    } else {
      this.user_data.ref = null;
    }

      localStorage.setItem('user_data', JSON.stringify(this.user_data));

    dateTimeAdapter.setLocale('en-IN');

    this.dcs = JSON.parse(localStorage.getItem('all_dcs'));

    this.apiDcPoList();

    this.gridOptions = <GridOptions>{ rowHeight:35,
                                      rowStyle: { 'text-align': 'left', 'padding': '20px 0px' },
                                      rowSelection: 'multiple',
                                      suppressAggFuncInHeader: true,
                                      pivotMode: false,
                                      groupHideOpenParents: true,
                                      groupDefaultExpanded: 9,
                                      groupMultiAutoColumn: true,
                                      autoGroupColumnDef: {
                                        pinned: 'left'
                                      }
                                    };

  }

  inWords(num) {
    if ((num = num.toString()).length > 9) return 'overflow';
    let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';

    str += (n[1] != '00') ? (this.a[Number(n[1])] || this.b[n[1][0]] + ' ' + this.a[n[1][1]]) + 'Crore ': '';
    str += (n[2] != '00') ? (this.a[Number(n[2])] || this.b[n[2][0]] + ' ' + this.a[n[2][1]]) + 'Lakh ': '';
    str += (n[3] != '00') ? (this.a[Number(n[3])] || this.b[n[3][0]] + ' ' + this.a[n[3][1]]) + 'Thousand ': '';
    str += (n[4] != '0') ? (this.a[Number(n[4])] || this.b[n[4][0]] + ' ' + this.a[n[4][1]]) + 'Hundred ': '' ;
    if (this.a[Number(n[5])]) {
      str += this.a[Number(n[5])];
    }else {
      if (this.a[n[5][1]] != 'Zero ') {
        str += this.b[n[5][0]] + ' ' + this.a[n[5][1]];
      }else {
        str += this.b[n[5][0]];
      }
    }
    return str;
  }

  apiDcPoList() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    let rowdata = [];
    let sl_no = 0;
    let datePipe = this.datePipe;

    this.res_data = this.apiService.returnPos(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        let inthis = this;
        if (res.hasOwnProperty('results') && (res['results'].status == 200)) {
          //this.dcs = res['results'].dcs;
          if (res['results'].data.length > 0) {
            res['results'].data.forEach(function (data) {
              sl_no = sl_no + 1;
              rowdata.push({
                "Sl_NO": sl_no,
                "GRV": data.name,
                "PO": data.po,
                "ORDER DATE": datePipe.transform(data.date, 'dd/MM/yyyy'),
                "CUSTOMER": data.outlet,
                "SUPPLIER": data.supplier,
                "VALUE": parseFloat(data.value).toFixed(inthis.apiService.decimalPolicy),
                "STATUS": data.state,
                "PRINT": '',
                "dc_id": data.dc_id,
                "id": data.id
              });
            });
            this.data = rowdata[0];
            this.rowData = rowdata;

            for (var key in this.data) {
              if (this.data.hasOwnProperty(key)) {
                if (key == "STATUS") {
                  this.columnDefs.push({
                    headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true,
                    cellStyle: function (params) {
                      if (params.value != null && params.value != undefined) {
                        if (params.value == 'draft') {
                          return {'background-color': 'SkyBlue'};
                        } else if (params.value == 'cancel') {
                          return {'background-color': 'LightCoral'};
                        } else if (params.value == 'confirmed') {
                          return {'background-color': 'MediumSeaGreen'};
                        }
                      }
                    }
                  });

                } else if (key == 'dc_id' || key == 'id') {
                  this.columnDefs.push({
                    headerName: key, field: key, hide: true, filter: false, unSortIcon: true, lockPosition: true,
                  });
                } else if (key == 'Sl_NO') {
                  this.columnDefs.push({
                    headerName: key, field: key, sortable: true, filter: true, resizable: true, lockPosition: true,
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true,
                    checkboxSelection: true                  });
                } else if (key == "PRINT") {
                  this.columnDefs.push({
                    headerName: key, field: key, resizable: true,pinned:'right',
                    cellRenderer: "printClickRenderer",
                    headerComponent: "printInvoicecomponent",
                  });
                } else {
                  this.columnDefs.push({
                    headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true,
                  });
                }
              }
            }
            this.loaded = true;
            this.context = { componentParent: this };

            this.frameworkComponents = {
              colorCellRenderer: ColorCellRenderer,
              printInvoicecomponent: PrintInvoiceComponent,
              printClickRenderer: PrintClickRenderer
            };
          }else {
            this.preloader = false;
            this.empty_state = true;
          }

        }else {
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }

  printAll(rows) {
    let order_ids = [];
    rows.forEach(van_row => {
      order_ids.push(van_row['id']);
    });

    let order_data = {
      "access_token": this.user_data.access_token,
      "url": this.user_data.url,
      "order_id": order_ids,
      "offset": this.user_data.offset
    };

    this.res_data = this.apiService.getPEDetails(order_data);

    this.res_data.subscribe(res => {
      console.log(res);
      let orderDetails = [];
      let datePipe = this.datePipe;
      let domSanitizer = this.domSanitizer;
      let inthis = this;
      if (res.hasOwnProperty('results') && (res['results'].status == 200) && (res['results'].data.length > 0)) {
        res['results'].data.forEach(function(data) {
          orderDetails = data;
          orderDetails['label_config'] = {};
          orderDetails['date'] = datePipe.transform(orderDetails['date'], 'yyyy-MM-dd');
          let labels = orderDetails['labels'];
          labels.forEach(function (lab) {
            orderDetails['label_config'][lab.label] = lab.display;
          });
          var amount = orderDetails['amount_total'].toString().split('.');
          if (amount.length > 1 && parseInt(amount[1],10) > 0) {
            orderDetails['amount_in_words'] = inthis.inWords(Math.abs(parseInt(amount[0],10)));
            orderDetails['amount_in_words'] = orderDetails['label_config']['PrimaryCurrency'] + ' ' + orderDetails['amount_in_words'] + ' And ' + orderDetails['label_config']['SecondaryCurrency'] + ' ' + inthis.inWords(Math.abs(parseInt(amount[1],10))) +  ' Only'
          }else {
            orderDetails['amount_in_words'] = orderDetails['label_config']['PrimaryCurrency'] + ' ' + inthis.inWords(Math.abs(parseInt(amount[0],10))) + ' Only';
          }
          orderDetails['customer_skip'] = true;
          if (orderDetails['logo']) orderDetails['logo'] = domSanitizer.bypassSecurityTrustUrl("data:image/png;base64, " + orderDetails['logo']);
          inthis.all_orders.push(orderDetails);
        });
        this.triggerFalseClick2();
      }
    });
  }

  onCellClicked(event) {

    var column = event.column.getColId();
    if (column == 'PRINT') {
      let order_data = {
        "access_token": this.user_data.access_token,
        "url": this.user_data.url,
        "order_id": [event.data.id],
        "offset": this.user_data.offset
      };

      this.res_data = this.apiService.getPEDetails(order_data);

      this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status == 200) && (res['results'].data.length > 0)) {
          this.orderDetails = res['results'].data[0];
          let inthis = this;
          this.orderDetails['label_config'] = {};
          this.orderDetails['date'] = this.datePipe.transform(this.orderDetails['date'], 'yyyy-MM-dd');
          let labels = this.orderDetails['labels'];
          labels.forEach(function(lab) {
            inthis.orderDetails['label_config'][lab.label] = lab.display;
          });
          var amount = this.orderDetails['amount_total'].toString().split('.');
          if (amount.length > 1 && parseInt(amount[1],10) > 0) {
            this.orderDetails['amount_in_words'] = this.inWords(Math.abs(parseInt(amount[0],10)));
            this.orderDetails['amount_in_words'] = this.orderDetails['label_config']['PrimaryCurrency'] + ' ' + this.orderDetails['amount_in_words'] + ' And ' + this.orderDetails['label_config']['SecondaryCurrency'] + ' ' + this.inWords(Math.abs(parseInt(amount[1],10))) +  ' Only'
          }else {
            this.orderDetails['amount_in_words'] = this.orderDetails['label_config']['PrimaryCurrency'] + ' ' + this.inWords(Math.abs(parseInt(amount[0],10))) + ' Only';
          }
          this.orderDetails['customer_skip'] = true;
          if (this.orderDetails['logo']) this.orderDetails['logo'] = this.domSanitizer.bypassSecurityTrustUrl("data:image/png;base64, "+this.orderDetails['logo']);
          this.all_orders = [this.orderDetails];
          this.triggerFalseClick2();
        }
      });
    }else {
      this.sharedService.new = false;
      this.sharedService.mark_receieve = false;
      this.sharedService.is_receipt = true;
      this.sharedService.is_return = false;
      this.sharedService.order_id = event.data.id;
      this.sharedService.delivered_po = false;
      this.router.navigate([this.apiService.redirect_url+"/new_po"]);
    }
  }

  triggerFalseClick2() {


    let el: HTMLElement = this.interPrint.nativeElement;
    el.click();
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        }else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    }
    else {
      this.gridColumnApi.autoSizeColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  newOrderCreation () {
    this.sharedService.po_dc_id = this.dcs;
    this.sharedService.new = true;
    this.sharedService.is_return = true;
    this.sharedService.flag = 'new';
    this.sharedService.is_receipt = false;
    this.sharedService.mark_receieve = false;
    this.sharedService.order_type = 'return';
    this.sharedService.delivered_po = false;
    this.router.navigate([this.apiService.redirect_url+"/new_po"]);
  }

  ngOnInit() {

    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable == "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {

    this.user_data.date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    this.user_data.ref = null;
    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.apiDcPoList();
  }
}
