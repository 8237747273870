import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

// backspace starts the editor on Windows
const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';

@Component({
  selector: 'app-numeric-cell',
  template: `
    <input *ngIf="editMode && showUOM && !isPinnedBottomRow"
           #input
           [(ngModel)]="value"
           class="custom-inputbox"
    />`,
})

export class UOMProductCellRendererComponent implements ICellRendererAngularComp {
  private params: any;
  public value!: number;
  public editMode = false;
  public context;
  public packUOM;
  packSize = 0;
  attributeJson;
  showUOM;
  isPinnedBottomRow = false;
  packDisplay = '';

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.data[params.colDef.field];
    if (params.colDef.field === 'units') {
      this.showUOM = true;
      this.packSize = 1;
    } else if (params.data && params.data[params.colDef.field + '_factor_inv'] && params.data[params.colDef.field + '_factor_inv'] > 1) {
      this.showUOM = true;
      this.packSize = params.data[params.colDef.field + '_factor_inv'];
    } else {
      this.showUOM = false;
    }
    if (params && params.node && params.node.rowPinned === 'bottom') {
      this.isPinnedBottomRow = true;
    } else {
      this.isPinnedBottomRow = false;
    }
    // if (params && params.data && params.data.attribute_json) {
    //   if (params.data.attribute_json.constructor !== Object) {
    //     try {
    //       this.attributeJson = JSON.parse(params.data.attribute_json);
    //     } catch (e) {
    //     }
    //   } else {
    //     this.attributeJson = params.data.attribute_json;
    //   }
    //   if (this.attributeJson && this.attributeJson.constructor === Object) {
    //     if (this.attributeJson.hasOwnProperty('alt_uom_name')) {
    //       this.editMode = true;
    //       this.packUOM = this.attributeJson.alt_uom_name;
    //     }
    //     if (this.attributeJson.hasOwnProperty('alt_uom_size')) {
    //       this.packSize = this.attributeJson.alt_uom_size;
    //     } else {
    //       this.packSize = 0;
    //     }
    //   }
    // }
    // this.params.data.packSize = this.packSize;
    // if (this.packSize && this.packSize > 0) {
    //   this.packDisplay = this.packUOM + ' (' + this.packSize + ' units)';
    // } else {
    //   this.packDisplay = this.packUOM;
    // }
    if (this.showUOM) {
      this.editMode = true;
    }
    if (!this.params.data.price_unit || this.params.data.price_unit === 0) {
      this.editMode = false;
    }
  }

  refresh(): boolean {
    return false;
  }
}
