import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {SharedService} from "../../../_services/shared_service";
import {DMSBasePage} from "../../../pagebase/components/DMSBasePage";
import {DatePipe} from "@angular/common";
import {LocalforageService} from "../../../_services/localforage.service";
import {ErrorHandler} from "../../../error/error-handler";
import {AppComponent} from "../../../app.component";

@Component({
  selector: 'app-customer-create-popup',
  templateUrl: './customer_create_popup.component.html',
  styleUrls: ['./customer_create_popup.component.scss']

})

export class CustomerCreatePopupComponent extends DMSBasePage implements OnInit {
  @Output() refreshPageAction: EventEmitter<any> = new EventEmitter<any>();

  territorySupplierList = [];
  territoriesList = [];

  activeObject = {
    id: 0,
    name: '',
    code: '',
    territory_id: undefined,
    beat_plan_id: undefined,
    credit_limit: 0,
    email: '',
    contact_name: '',
    mobile: '',
    gst_id: '',
    note: '',
    retailer_type: undefined,
    street: '',
    street2: '',
    city: '',
    zip: '',
    class: 'b',
    state_id: undefined,
    zone_id: undefined,
    credit_type_id: undefined,
    customer: true,
    supplier: false,
    payment_mode: 'cash',
    balanceType: 'outstanding',
    balance: 0,
    supplier_id: 0
  };

  context: any;
  showDetail = false;
  enterCodeManually = false;

  public customerTypeList = [];
  public creditTypeList =  [{itemName: "Cash", id: "cash"}, {itemName: "Credit", id: "cash_and_credit"}, {itemName: "Only Credit", id: "credit"}];
  public  stateList = [];
  public balanceTypeList = [{itemName: "Outstanding", id: "outstanding"}, {itemName: "Credits", id: "credits"}];

  constructor(public dialog: MatDialog,
              public apiService: ApiService,
              public sharedService: SharedService,
              public toastr: ToastrService,
              public elRef: ElementRef,
              public datePipe: DatePipe,
              protected route: ActivatedRoute,
              protected _localCacheService: LocalforageService,
              public router: Router,
              public domSanitizer: DomSanitizer,
              public appComponent: AppComponent,
              public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    super(apiService, sharedService, elRef, datePipe, _localCacheService, toastr, route, dialog, router, domSanitizer, appComponent);


    this.loadMasterDataForCustomerCreate(() => {


    });
  }

  fetch_partner(partner_id) {

  }

  ngOnInit(): void {

  }

  loadMasterDataForCustomerCreate(callback) {
    this.customerTypeList = JSON.parse(localStorage.getItem('all_out_types'));
    this.beatList = JSON.parse(localStorage.getItem('terr_beats'));
    this.stateList = JSON.parse(localStorage.getItem('user_states'));
    this.territoriesList = JSON.parse(localStorage.getItem('all_territories'));

  }

  async beatChangeEvent(selected_beat_id) {
    if (selected_beat_id) {
      this.territorySupplierList = [];
      this.activeObject.supplier_id = 0;
      const beat = this.beatList.find(b => b.id === selected_beat_id);
      if (beat) {
        this.activeObject.territory_id = beat.territory_id;
        const territory = this.territoriesList.find(x => x.id === beat.territory_id);
        if (territory && territory.distributor_id) {
          const supplierObj = await this.GetObject('/api/pwa_dms_connects/get_supplier_detail_for_dms', territory.distributor_id);
          if (supplierObj && supplierObj.partner_type === 'supplier') {
            this.activeObject.supplier_id = supplierObj['id'];
            this.territorySupplierList.push({id: supplierObj['id'], name: supplierObj['name']});
          }
        }
      }
    }
  }

  typeChange(event) {
    // this.newRetailer.retailer_type = event[0].id;
  }

  changeDate(dt) {

  }

  CreditTypeChange(event) {

  }

  closeDialog() {
    this.dialog.closeAll();
  }

  enterCodeManualClick() {
    this.enterCodeManually = true;
  }

  onCodeKeyDown(event) {

  }

  showDetails() {
    //this.showDetail.emit(true);
    this.dialogRef.updateSize('550px', '860px');
    this.showDetail = true;
  }

  saveCustomer() {
    // const supplier = this.getDefaultSupplier();
    // if (!supplier) {
    //   return;
    // } else {
    //   this.activeObject.territory_id = supplier.territory_id;
    // }
    if (!this.activeObject.beat_plan_id) {
      this.toastr.error('Please select a beat!');
      return false;
    }
    if (!this.activeObject.territory_id) {
      this.toastr.error('Unable to find territory for the beat');
      return false;
    }
    if (this.activeObject.balance > 0 && this.territorySupplierList.length !== 1) {
      this.toastr.error('Default supplier is a must to create balance record');
      return false;
    }
    this.userContext['activeObject'] = this.activeObject;
    this.apiService.post('/api/pwa_admin_connects/save_customer', this.userContext)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && res.results.status === 200) {

            if (res.results.data.length > 0) {
              this.toastr.success('Customer Created');
              this.refreshPageAction.emit(true);
              this.closeDialog();
            }

          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        error => {
          return this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
        });

  }

  onCancelClick() {

  }
}
