import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { GridOptions } from "../../../node_modules/@ag-grid-enterprise/all-modules";
import { ApiService} from "../_services/api.service";
import { Router, ActivatedRoute  } from '@angular/router';
import { ColorCellRenderer } from "../colorCell.component";
import { DatePipe } from '@angular/common';
import { LoadingstateComponent} from "../components/loadingstate/loadingstate.component"

@Component({
  selector: 'app-ti-productivity-report',
  templateUrl: './ti-dashboard-report.component.html',
  styleUrls: ['./ti-dashboard-report.component.scss']
})
export class TiDashboardReportComponent implements OnInit {

  user;
  res_data;
  user_data = {
    "start_date":null,
    "access_token": null,
    "url": null,
    "end_date": null,
    "offset": null
  };

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public title_name: string = "DC LIST";
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public report_flag;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef:ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe) {

    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.user_data.start_date = this.myDate[0];
    this.user_data.end_date = this.myDate[1];
    this.user_data.offset = -1 * (new Date(this.user_data.start_date)).getTimezoneOffset();
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.report_flag = this.route.snapshot.params["report_flag"];

    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.tireport();

    this.gridOptions = <GridOptions>{ rowHeight: 28,
      rowStyle: { 'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left' },
      rowSelection: 'single',
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      pivotMode: false,
      autoGroupColumnDef: {
        pinned: 'left'
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      }};

  }

  tireport() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    let rowdata = [];
    let datePipe = this.datePipe;
    let return_qty_perc;
    let return_sale_perc;
    let replace_qty_perc;
    let replace_sale_perc;


    this.res_data = this.apiService.tiReport(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        let inthis = this;
        if (res.hasOwnProperty('results') && (res['results'].status == 200) && (res['results'].data.length > 0)) {
          res['results'].data.forEach(function (data) {
            return_qty_perc = parseFloat(((parseInt(data.ret_qty,10)/parseInt(data.sale_qty,10))*100).toFixed(inthis.apiService.decimalPolicy));
            return_sale_perc = parseFloat(((parseFloat(data.ret_sale)/parseFloat(data.gross_amt))*100).toFixed(inthis.apiService.decimalPolicy));
            if (isNaN(return_qty_perc) || !isFinite(return_qty_perc))  return_qty_perc = 0;
            if (isNaN(return_sale_perc) || !isFinite(return_sale_perc)) return_sale_perc = 0;
            replace_qty_perc = parseFloat(((parseInt(data.dmg_qty,10)/parseInt(data.sale_qty,10))*100).toFixed(inthis.apiService.decimalPolicy));
            replace_sale_perc = parseFloat(((parseFloat(data.dmg_sale)/parseFloat(data.gross_amt))*100).toFixed(inthis.apiService.decimalPolicy));
            if (isNaN(replace_qty_perc) || !isFinite(replace_qty_perc))  replace_qty_perc = 0;
            if (isNaN(replace_sale_perc) || !isFinite(replace_sale_perc)) replace_sale_perc = 0;
            var pushData = {
              "DC": data.name,
              "TI": data.ti,
              "Target": parseInt(data.target,10),
              "Sales QTY": parseInt(data.sale_qty,10),
              "Net Sale": parseFloat(data.gross_amt),
              "Replace QTY": parseInt(data.dmg_qty,10),
              "Replace% (QTY)": replace_qty_perc,
              "Return QTY": parseInt(data.ret_qty,10),
              "Return% (QTY)": return_qty_perc,
              "Replace": parseFloat(data.dmg_sale),
              "Replace% (Amt)": replace_sale_perc,
              "Return": parseFloat(parseFloat(data.ret_sale).toFixed(3)),
              "Return% (Amt)": return_sale_perc
            };

            rowdata.push(pushData);
          });

          this.data = rowdata[0];
          this.rowData = rowdata;

          for (var key in this.data) {
            if (this.data.hasOwnProperty(key)) {
                this.columnDefs.push({
                  headerName: key, field: key, sortable: true, filter: true, lockPosition: true, resizable: true,width:100,
                  enableRowGroup: true, enablePivot: true, aggFunc: 'sum', enableValue: true
                });
            }
          }
          this.loaded = true;

        }else {
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.setRowData(this.rowData);
    this.preloader = false;

    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    }
    else {
      this.gridColumnApi.autoSizeColumns();
    }
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        }else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    // this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {

    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable == "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {

    this.user_data.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    var oneDay = 24 * 60 * 60 * 1000;
    var firstDate = this.myDate[0];
    var secondDate = this.myDate[1];
    var diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    if (diffDays > 31) {
      window.alert('Please select 1 Month Data ');
    }else {
      this.tireport();
    }
  }
}
