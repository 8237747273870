import {Component} from "@angular/core";
import {BaseUpload} from "../../../pagebase/base_upload/base_upload";

@Component({
  selector: 'app-stock-upload',
  templateUrl: '../../../pagebase/base_upload/base_upload.html',
  styleUrls: ['../../../pagebase/base_upload/base_upload.scss']

})

export class StockUploadComponent extends BaseUpload {

  setViewParams() {
    this.API_URL = '/api/pwa_dms_connects/stock_upload';
    this.header = 'Stock Upload';
    this.sampleText1 = 'DOWNLOAD ALL PRODUCT LIST';
    this.sampleText2 = 'DOWNLOAD TERRITORY LINKED PRODUCTS';
    this.excel_columns = [{C: 'A', H: 'Product Code', V: 'PC001', required: true},
      {C: 'B', H: 'Product Name', V: 'SWater bottle- 500 ml', required: false},
      {C: 'C', H: 'Current Stock', V: '100', required: false},
      {C: 'D', H: 'Pack Name', V: 'Case', required: true},
      {C: 'E', H: 'Pack', V: '4', required: true},
      {C: 'F', H: 'Pack Size', V: '100', required: true},
      {C: 'G', H: 'Units', V: '10', required: true},
      {C: 'H', H: 'New Stock', V: '410', required: true},
      {C: 'I', H: 'Reason for Deviation', V: 'Incorrect data recorded', required: true},
    ];
    this.step1Text = 'Download all product lists or territory-linked product lists for reference.';
    this.step2Text = 'Required fields are product code, pack, Units, pack name, pack size, new stock and reason for deviation.';
    this.actionText = 'Add or edit stock info in the template';
  }

  async fetchBaseData() {
    const res = await this.apiService.postPromise('/api/pwa_dms_connects/get_supplier_live_stock', this.userData);
    if (res.hasOwnProperty('results') && (res.results.status === 200)) {
      this.dataLoaded = true;
      this.baseData = res.results.data;
      this.processTerritoryProductData(res.results.data);
    } else {
      this.toastr.error('Failed to fetch Upload Master Data');
      this.dialog.closeAll();
    }
  }

  sampleDownload1() {
    this.apiService.post('/api/product_products/getProductsListPWA', this.userData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            const productData = this.processProductData(res.results.data);
            this.downloadExcel(productData, 'all_products');
          } else {
            this.preloader = false;
            //this.empty_state = true;
          }
        },
        errorMsg => {
          console.log(errorMsg);
          this.preloader = false;
        });
  }

  sampleDownload2() {
    this.apiService.post('/api/pwa_dms_connects/get_supplier_live_stock', this.userData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            const productData = this.processTerritoryProductData(res.results.data);
            this.downloadExcel(productData, 'territory_products');
          } else {
            this.preloader = false;
            //this.empty_state = true;
          }
        },
        errorMsg => {
          console.log(errorMsg);
          this.preloader = false;
        });
  }

  processProductData(products) {
    const productData = [];
    for (const prod of products) {
      const indrow: any = {};
      indrow.category = prod.category;
      indrow.brand = prod.brand;
      indrow.sku_code = prod.sku_code;
      indrow.sku_name = prod.sku_name;
      indrow.sku_seq = prod.sku_seq;
      indrow.description = prod.description;
      indrow.uom = prod.uom;
      indrow.uom_factor = prod.uom_factor;
      indrow.ptd = prod.ptd;
      indrow.ptr = prod.ptr;
      indrow.priority = prod.priority;
      indrow.active = prod.active;
      if (prod.hasOwnProperty('tax_config') && prod.tax_config) {
        indrow.gst = prod.tax_config.gst;
        indrow.vat = prod.tax_config.vat;
        indrow.cess = prod.tax_config.cess;
        indrow.excise = prod.tax_config.excise;
      }
      productData.push(indrow);
    }
    return productData;
  }

  processTerritoryProductData(products) {
    const productData = [];
    for (const prod of products) {
      const indrow: any = {};
      indrow['Product Code'] = prod.default_code;
      indrow['Product Name'] = prod.name;
      indrow['Current Stock'] = prod.stock;
      indrow['Pack Name'] = '';
      indrow['Pack'] = 0;
      indrow['Pack Size'] = 0;
      indrow['Units'] = 0;
      indrow['Reason for Deviation'] = '';
      if (prod.attribute_json) {
        if (prod.attribute_json.constructor !== Object) {
          try {
            prod.attribute_json = JSON.parse(prod.attribute_json);
          } catch (e) {
          }
        }
        if (prod.attribute_json.constructor === Object) {
          if (prod.attribute_json.hasOwnProperty('alt_uom_name')) {
            indrow['Pack Name'] = prod.attribute_json['alt_uom_name'];
          }
          if (prod.attribute_json.hasOwnProperty('alt_uom_size')) {
            indrow['Pack'] = parseInt(prod.attribute_json['alt_uom_size'], 10);
          }
        }
      }
      productData.push(indrow);
    }
    this.baseData = productData;
    return productData;
  }

  configureGrid() {
    this.columnDefs = [
      {headerName: 'Product Code', field: 'product_code', width: 100},
      {headerName: 'Product Name', field: 'product_name', width: 200},
      {headerName: 'Current Stock', field: 'current_stock', width: 120, cellStyle: {textAlign: 'right'}},
      {headerName: 'Pack Name', field: 'pack_name', width: 100},
      {headerName: 'Pack', field: 'pack', width: 100, cellStyle: {textAlign: 'right'}},
      {headerName: 'Units', field: 'units', width: 100, cellStyle: {textAlign: 'right'}},
      {headerName: 'New Stock', field: 'new_stock', width: 100, cellStyle: {textAlign: 'right'}},
      {
        headerName: 'Deviation', field: 'deviation', width: 100,
        cellStyle(params) {
          if (params.data && params.value) {
            if (params.value > params.data['Current Stock']) {
              return {color: '#379862', textAlign: 'right'};
            } else if (params.value < params.data['Current Stock']) {
              return {color: '#E24260', textAlign: 'right'};
            } else {
              return {textAlign: 'right'};
            }
          }
        },
      },
      {headerName: 'Reason for Deviation', field: 'reason', width: 200}
    ];
  }

  validate() {
    const inThis = this;
    this.validRecords = 0;
    this.errorRecords = 0;
    this.rowData = [];
    this.uploaded_data.forEach(row => {
      const found = inThis.baseData.find(x => x['Product Code'] && (x['Product Code'].toString() === row['Product Code'].toString()));
      const indrow: any = {};
      indrow.product_code = row['Product Code'];
      if (found) {
        indrow.product_name = found['Product Name'];
        indrow.current_stock = found['Current Stock'];
        indrow.pack_name = found['Pack Name'];
        indrow.pack_size = found['Pack Size'];
      } else {
        indrow.product_name = row['Product Name'];
        indrow.current_stock = 0;
        indrow.pack_name = row['Pack Name'];
        indrow.pack_size = row['Pack Size'];
      }
      if (indrow.pack_name && indrow.pack_size) {
        indrow.pack_name = indrow.pack_name + ' (' + indrow.pack_size + ' units)';
      }
      indrow.pack = row['Pack'];
      indrow.units = row['Units'];
      indrow.reason = row['Reason for Deviation'];
      if (!indrow['product_code'] || indrow['product_code'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Product Code Missing';
      }
      if (indrow['pack'] === null || indrow['pack'] === undefined || indrow['pack'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Pack Missing';
      }
      if (indrow['pack_size'] === null || indrow['pack_size'] === undefined || indrow['pack_size'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Pack Size Missing';
      }
      if (indrow['units'] === null || indrow['units'] === undefined || indrow['units'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Units Missing';
      }
      if (!this.numPattern.test(indrow['pack'])) {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid Pack';
      } else {
        indrow['pack'] = parseInt(indrow['pack'], 10);
      }
      if (!this.numPattern.test(indrow['pack_size'])) {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid Pack Size';
      } else {
        indrow['pack_size'] = parseInt(indrow['pack_size'], 10);
      }
      if (!this.numPattern.test(indrow['units'])) {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid Units';
      } else {
        indrow['units'] = parseInt(indrow['units'], 10);
      }
      if (indrow.valid === 'Invalid') {
        this.errorRecords++;
      } else {
        indrow.valid = 'Valid';
        indrow.new_stock = ((indrow.pack * indrow.pack_size) + indrow.units);
        indrow.deviation = ((indrow.pack * indrow.pack_size) + indrow.units) - indrow.current_stock;
        if (indrow.current_stock && (indrow.current_stock === indrow.new_stock)) {
          return;
        }
        this.validRecords++;
      }
      inThis.rowData.push(indrow);
    });
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setRowData(this.rowData);
    }
  }
}
