import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'partial-approve-popup',
  templateUrl: './partial_approve_popup.component.html',
  styleUrls: ['./partial_approve_popup.component.scss']

})

export class PartialApprovePopupComponent implements OnInit {

  @Output() partialrapprovesubmit: EventEmitter<any> = new EventEmitter<any>();
  amountExceeded = false;
  exp;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.exp = dialogdata.exp;
  }

  ngOnInit(): void {
  }

  onAmountChange(){
    if(this.exp.app_amount> this.exp.amount)
    {
      this.amountExceeded = true;
    }
    else
      this.amountExceeded = false;
  }

  performAction(){
    this.partialrapprovesubmit.emit(this.exp.app_amount);
    this.dialog.closeAll();
  }

  closeDialog(){
    this.dialog.closeAll();
  }

}
