import {Component, ComponentFactoryResolver, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ApiService} from "../_services/api.service";
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import {GridOptions} from "../../../node_modules/@ag-grid-community/all-modules";
import {AdDirective} from "../ad.directive";

@Component({
  selector: 'app-product-collection-report',
  templateUrl: './product-collection-report.component.html',
  styleUrls: ['./product-collection-report.component.scss']
})
export class ProductCollectionReportComponent implements OnInit {
  @ViewChild(AdDirective, {static: true}) adHost: AdDirective;

  user;
  res_data;
  user_data = {
    start_date: null,
    access_token: null,
    url: null,
    last_date: null,
    offset: null,
    dc_id: 0
  };

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public report_flag;
  public all_dc = [];
  public default = true;
  public pinnedTotalData = [
    {
      DC: null,
      Product: null,
      "Product Code": "TOTAL QTY"
    }];
  public modules = AllModules;
  public dcList = [{id: 0, itemName: 'DC'}];
  public selectedDC = [{id: 0, itemName: 'DC'}];

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              private componentFactoryResolver: ComponentFactoryResolver) {

    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.user_data.start_date = this.myDate[0];
    this.user_data.last_date = this.myDate[1];
    this.user_data.offset = -1 * (new Date(this.user_data.start_date)).getTimezoneOffset();
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.dcList = this.dcList.concat(JSON.parse(localStorage.getItem('all_dcs')));
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'single',
      enableRangeSelection: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      pivotMode: false,
      autoGroupColumnDef: {
        pinned: 'left'
      },
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        enablePivot: true,
        enableValue: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      }
    } as GridOptions;

  }

  dcChange(event) {
    if (event.length > 0) {
      if (event[0].id !== 0) {
        this.user_data.dc_id = event[0].id;
      } else {
        this.user_data.dc_id = 0;
      }
    }
  }

  prodcollectionreport() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    const rowdata = [];
    const datePipe = this.datePipe;
    let qty = 0, cash = 0, credit = 0, rep = 0, ret = 0, grant = 0, gross = 0, out = 0, net = 0, tcs = 0;
    this.res_data = this.apiService.prodCollectionReport(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        const inthis = this;
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          res['results'].data.forEach(data => {
            const pushData = {
              State: data.dc_state,
              DC: data.dc,
              Date: datePipe.transform((data.schedule_date).toString(), 'dd/MM/yy'),
              Product: data.name,
              "Product Code": data.default_code,
              Qty: parseInt(data.qty, 10),
              "Cash Sale": parseFloat(data.cash_sale.toFixed(inthis.apiService.decimalPolicy)),
              "Credit Sale": parseFloat(data.credit_sale.toFixed(inthis.apiService.decimalPolicy)),
              Replace: parseFloat(data.damage_sale),
              "Replace%": parseFloat(data.damage_pct),
              Return: parseFloat(data.return_sale),
              "Return%": parseFloat(data.return_pct),
              "Net Sale": parseFloat(data.net_sale),
              "Gross Sale": parseFloat(data.gross_sale),
              "Grant Total": parseFloat(data.grand_total.toFixed(inthis.apiService.decimalPolicy)),
              "TCS/TDS": parseFloat(data.tcs.toFixed(5)),
              "Total Outlets": parseInt(data.total_outlets, 10)
            };
            qty += parseInt(data.qty, 10);
            cash += parseFloat(data.cash_sale);
            credit += parseFloat(data.credit_sale);
            ret += parseFloat(data.return_sale);
            rep += parseFloat(data.damage_sale);
            net += parseFloat(data.net_sale);
            gross += parseFloat(data.gross_sale);
            grant += parseFloat(data.grand_total);
            tcs += parseFloat(data.tcs);
            out += parseInt(data.total_outlets, 10);
            rowdata.push(pushData);
          });
          this.data = rowdata[0];
          this.rowData = rowdata;
          let retP = (ret / gross) * 100;
          if (ret === 0) {
            retP = 0;
          }
          let repP = (rep / gross) * 100;
          if (rep === 0) {
            repP = 0;
          }
          this.pinnedTotalData[0]['Qty'] = qty;
          this.pinnedTotalData[0]['Cash Sale'] = cash.toFixed(this.apiService.decimalPolicy);
          this.pinnedTotalData[0]['Credit Sale'] = credit.toFixed(this.apiService.decimalPolicy);
          this.pinnedTotalData[0]['Replace'] = rep.toFixed(this.apiService.decimalPolicy);
          this.pinnedTotalData[0]['Replace%'] = repP.toFixed(this.apiService.decimalPolicy);
          this.pinnedTotalData[0]['Return'] = ret.toFixed(this.apiService.decimalPolicy);
          this.pinnedTotalData[0]['Return%'] = retP.toFixed(this.apiService.decimalPolicy);
          this.pinnedTotalData[0]['Net Sale'] = net.toFixed(this.apiService.decimalPolicy);
          this.pinnedTotalData[0]['Gross Sale'] = gross.toFixed(this.apiService.decimalPolicy);
          this.pinnedTotalData[0]['Grant Total'] = grant.toFixed(this.apiService.decimalPolicy);
          this.pinnedTotalData[0]['TCS/TDS'] = tcs.toFixed(this.apiService.decimalPolicy);
          this.pinnedTotalData[0]['Total Outlets'] = out;
          this.gridOptions.pinnedBottomRowData = this.pinnedTotalData;
          for (const key in this.data) {
            if (this.data.hasOwnProperty(key)) {
              if (key === 'Product') {
                this.columnDefs.push({headerName: key, field: key, aggFunc: 'sum'});
              } else if (key === 'DC') {
                this.columnDefs.push({headerName: key, field: key, aggFunc: 'sum'});
              } else if (key === 'Qty' || key === 'Product Code') {
                this.columnDefs.push({headerName: key, field: key, aggFunc: 'sum', cellStyle: {textAlign: "right"}});
              } else if (key === 'Cash Sale') {
                this.columnDefs.push({headerName: key, field: key, aggFunc: 'sum', cellStyle: {textAlign: "right"}});
              } else if (key === 'Credit Sale') {
                this.columnDefs.push({headerName: key, field: key, aggFunc: 'sum', cellStyle: {textAlign: "right"}});
              } else if (key === 'Return') {
                this.columnDefs.push({headerName: key, field: key, aggFunc: 'sum', cellStyle: {textAlign: "right"}});
              } else if (key === 'Return%') {
                this.columnDefs.push({headerName: key, field: key, aggFunc: 'avg', cellStyle: {textAlign: "right"}});
              } else if (key === 'Gross Sale' || key === 'Net Sale') {
                this.columnDefs.push({headerName: key, field: key, aggFunc: 'sum', cellStyle: {textAlign: "right"}});
              } else if (key === 'Total Outlets') {
                this.columnDefs.push({headerName: key, field: key, aggFunc: 'sum', cellStyle: {textAlign: "right"}});
              } else {
                this.columnDefs.push({headerName: key, field: key, aggFunc: 'sum'});
              }
            }
          }
          this.loaded = true;
        } else {
          this.preloader = false;
          this.empty_state = true;
        }
      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }

  run() {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = this.myDate[0];
    const secondDate = this.myDate[1];
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    if (diffDays > 31) {
      window.alert('Please select 1 Month Data ');
    } else {
      this.prodcollectionreport();
    }

  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {
    this.user_data.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
  }
}
