import {Component, ComponentFactoryResolver, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {GridOptions} from "../../../node_modules/@ag-grid-enterprise/all-modules";
import {ApiService} from "../_services/api.service";
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {AdDirective3} from "../ad3.directive";
import {StockClickRendererComponent} from "../stockClickRenderer.component";
import {SharedService} from "../_services/shared_service";
import {AppComponent} from "../app.component";

@Component({
  selector: 'app-livestock-report',
  templateUrl: './livestock-report.component.html',
  styleUrls: ['./livestock-report.component.scss']
})
export class LivestockReportComponent implements OnInit, OnDestroy {
  @ViewChild(AdDirective3, {static: true}) adHost3: AdDirective3;

  user;
  res_data;
  LIMIT = 20000;
  user_data = {
    date: null,
    access_token: null,
    url: null,
    last_date: null,
    flag: true,
    out_type: null,
    my_stock: false,
    limit: 0,
    offset: 0,
    dist_id: null
  };

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public context;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public report_flag;
  public all_types;
  public type_default = true;
  public orderDetails;
  public itemList = [{id: 0, itemName: 'All Types'}];
  public selectedItems = [{id: 0, itemName: 'All Types'}];
  public distList = [{id: 0, name: 'All Distributors'}];
  public selectedDist = [{id: 0, name: 'All Distributors'}];
  public renderComplete = true;
  public batchComplete = 0;
  public batchCount = 0;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              public appComp: AppComponent,
              private sharedService: SharedService,
              private componentFactoryResolver: ComponentFactoryResolver) {

    this.user_data.date = this.myDate[0];
    this.user_data.last_date = this.myDate[1];
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.report_flag = this.route.snapshot.params["report_flag"];
    this.itemList = this.itemList.concat(JSON.parse(localStorage.getItem('all_dist_types')));
    this.user_data.limit = this.LIMIT;
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    this.sharedService.setHeader('Livestock Report');
    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'single',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
    } as GridOptions;
    if (!localStorage.getItem('dist_list')) {
      this.fetchDistributors();
    } else {
      this.distList = this.distList.concat(JSON.parse(localStorage.getItem('dist_list')));
    }
  }

  fetchDistributors() {
    const inData3 = {
      access_token: localStorage.getItem('resfreshToken'),
      supplier_id: JSON.parse(localStorage.getItem('user')).partner_id
    };
    this.apiService.post('/api/dms_connects/get_dms_retailers', inData3)
      .subscribe(resp1 => {
        localStorage.setItem('dist_list', JSON.stringify(resp1['results'].data));
        this.distList = this.distList.concat(resp1['results'].data);
      });
  }

  typeChange(event) {
    if (event.length > 0) {
      if (event[0].id !== 0) {
        this.user_data.out_type = event[0].id;
      } else {
        this.user_data.out_type = null;
      }
    }
  }

  distChange(event) {
    if (event.length > 0) {
      if (event[0].id !== 0) {
        this.user_data.dist_id = event[0].id;
      } else {
        this.user_data.dist_id = null;
      }
    }
  }

  go() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    this.renderComplete = false;
    this.batchComplete = 0;
    this.rowData = [];
    this.livestockreport();
  }

  async livestockreport() {
    const rowdata = [];
    const inthis = this;
    this.res_data = await this.apiService.liveStockReport(this.user_data);
    if (this.res_data.hasOwnProperty('results') && (this.res_data['results'].status === 200)) {
      this.res_data['results'].data.forEach(data => {
        const stock_value = (parseFloat(data.stock) * parseFloat(data.cost_price)).toFixed(inthis.apiService.decimalPolicy);
        const pushData = {
          Territory: data.territory,
          "Territory Code": data.code,
          "Product Code": data.default_code,
          Product: data.name,
          Category: data.category,
          Brand: data.brand,
          "Cost Price": data.cost_price,
          "Live Stock": data.stock,
          "Stock Value": stock_value,
          "Cost History": 'cost',
          "Stock Log": 'stock',
          distributor_id: data.dist_id,
          product_id: data.prod_id,
          territory_id: data.territory_id
        };
        rowdata.push(pushData);

      });
      this.rowData = this.rowData.concat(rowdata);
      this.batchComplete += 1;
      this.batchCount = rowdata.length;
      if (!this.renderComplete && rowdata.length === this.LIMIT) {
        this.user_data.offset += this.LIMIT;
        this.livestockreport();
      } else {
        this.data = rowdata[0];
        for (const key in this.data) {
          if (this.data.hasOwnProperty(key)) {
            if (key === 'Territory Code' || key === 'Product Code' || key === 'Product') {
              this.columnDefs.push({headerName: key, field: key, sortable: true, filter: true, lockPosition: true});
            } else if (key === "Cost Price" || key === 'Live Stock' || key === 'Stock Value') {
              this.columnDefs.push({
                headerName: key, field: key, resizable: true, filter: true, cellStyle: {textAlign: "right"}, sortable: true,
              });
            } else if (key === "Cost History" || key === 'Stock Log') {
              this.columnDefs.push({
                headerName: key, field: key, resizable: true, filter: true,
                cellRenderer: "stockClickRenderer",
              });
            } else if (key === "distributor_id" || key === 'product_id' || key === 'territory_id') {
              this.columnDefs.push({
                headerName: key, field: key, resizable: true, filter: true, hide: true
              });
            } else {
              this.columnDefs.push({headerName: key, field: key, sortable: true, filter: true, lockPosition: true, cellStyle: {textAlign: "right"}});
            }
          }
        }
        this.renderComplete = true;
        this.loaded = true;
        this.context = {componentParent: this};
        this.frameworkComponents = {
          stockClickRenderer: StockClickRendererComponent
        };
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      }

    } else {
      this.preloader = false;
      this.empty_state = true;
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    }
  }

  goToLogs(value, params) {
    this.sharedService.logData.distributor_id = params.distributor_id;
    this.sharedService.logData.product_id = params.product_id;
    this.sharedService.logData.territory_id = params.territory_id;

    if (value === 'stock') {
      this.router.navigate([this.apiService.redirect_url + "/stock_log_entry"]);
    } else if (value === 'cost') {
      this.router.navigate([this.apiService.redirect_url + "/cost_log_entry"]);
    }
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      params.columnApi.autoSizeColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
    this.sharedService.setHeader('Distributor Live Stock');
  }

  ngOnDestroy() {
    this.renderComplete = true;
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {
    this.user_data.date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    this.livestockreport();
  }

}
