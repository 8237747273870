import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {GridOptions} from "../../../node_modules/@ag-grid-enterprise/all-modules";
import {Router} from '@angular/router';
import {ApiService} from "../_services/api.service";
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-eod-vanlist',
  templateUrl: './eod-vanlist.component.html',
  styleUrls: ['./eod-vanlist.component.scss']
})
export class EODVanlistComponent implements OnInit {

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTopRowData;
  public frameworkComponents;
  public rowData = [];
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public passedParams;
  public myDate;
  public get_user_data;
  public styleGrid;
  public collapse_disable;
  public empty_state = false;
  public cardLoader = true;

  res_data;
  user_data = {
    date: null,
    dc_id: null,
    access_token: null,
    url: null,
    offset: null,
    vansales: null
  };

  constructor(private router: Router,
              private apiService: ApiService,
              private datePipe: DatePipe,
              private elRef: ElementRef,
              private renderer: Renderer2) {

    this.get_user_data = JSON.parse(localStorage.getItem('user_data'));

    this.myDate = this.get_user_data.date;

    this.user_data.date = localStorage.getItem('my_date');
    if (this.user_data.date === null || this.user_data.date === "null") {
      this.user_data.date = this.myDate;
      localStorage.setItem('my_date', this.myDate);
    } else {
      this.myDate = this.user_data.date;
    }
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.dc_id = JSON.parse(localStorage.getItem('wflist_params')).dc_id;
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');

    this.apiDcVanList();

    this.gridOptions = {
      rowHeight: 75, headerHeight: 60, rowStyle: {'text-align': 'left', padding: '20px 0px'},
      rowSelection: 'single',
      pinnedBottomRowData: [],
      onRowSelected: params => {
        localStorage.setItem('eod_params', JSON.stringify(params.data));
        localStorage.setItem('row_index', params.rowIndex.toString());
        this.router.navigate([this.apiService.redirect_url + "/eod"]);
      }
    } as GridOptions;
    this.defaultColDef = {
      width: 140,
      resizable: true
    };
  }

  apiDcVanList() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    const rowdata = [];
    let retailer_total = 0;
    let c_o = 0;
    let l_o = 0;
    let uv = 0, pc = 0, vc = 0, dc = 0, cash = 0, credit = 0, t_s = 0, t_q = 0;


    this.res_data = this.apiService.dclistdetail(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          const vansales = [];
          res['results'].data.forEach(data => {
            const pushData = {
              VAN: data.name,
              STATUS: data.status,
              BEAT: data.beat_name,
              "LOADIN QTY": '...',
              "LOADOUT VALUE": '...',
              TC: data.retailer_count,
              VC: data.visited_count,
              UV: data.uv,
              PC: data.productive_calls,
              CASH: '...',
              CREDIT: '...',
              "TOTAL SALES IN VALUE": '...',
              "TOTAL SALES IN QTY": '...',
              van_id: data.van_id,
              vansale_id: data.id,
              true_van_id: data.true_van_id,
              dc_id: data.dc_id
            };
            rowdata.push(pushData);
            vansales.push(data.id);
            if (data.retailer_count != null) {
              retailer_total += parseInt(data.retailer_count, 10);
            }
            if (data.productive_calls != null) {
              pc += parseInt(data.productive_calls, 10);
            }
            if (data.visited_count != null) {
              vc += parseInt(data.visited_count, 10);
            }
            if (data.uv != null) {
              uv += parseInt(data.uv, 10);
            }
            if (data.deviation_count != null) {
              dc += parseInt(data.deviation_count, 0);
            }
          });

          this.gridOptions.pinnedBottomRowData = [{
            VAN: '',
            STATUS: '',
            BEAT: 'TOTAL',
            'LOADIN VALUE': c_o,
            'LOADOUT VALUE': l_o,
            TC: retailer_total,
            PC: pc,
            VC: vc,
            UV: uv,
            DC: dc,
            CASH: cash.toFixed(this.apiService.decimalPolicy),
            CREDIT: credit.toFixed(this.apiService.decimalPolicy),
            'TOTAL SALES IN VALUE': t_s.toFixed(this.apiService.decimalPolicy),
            'TOTAL SALES IN QTY': t_q
          }
          ];

          this.data = rowdata[0];
          this.rowData = rowdata;

          localStorage.setItem('navigation_params', JSON.stringify(this.rowData));

          for (const key in this.data) {
            if (this.data.hasOwnProperty(key)) {
              if (key === 'STATUS') {
                console.log(this.data[key]);
                this.columnDefs.push({
                  headerName: key, field: key, sortable: true, filter: false, lockPosition: true,
                  cellRenderer: params => {
                    let displayElem;
                    if (params.value === null) {
                      params.value = "not started";
                      displayElem = '<div class="wf-top" layout="row">' +
                        '<span class="status-data not-started">' + params.value + '</span>' +
                        '</div>';
                      return displayElem;
                    } else if (params.value === 'complete') {
                      displayElem = '<div class="wf-top" layout="row">' +
                        '<span class="status-data completed">' + params.value + '</span>' +
                        '</div>';
                      return displayElem;
                    } else if (params.value === 'started') {
                      displayElem = '<div class="wf-top" layout="row">' +
                        '<span class="status-data started">' + params.value + '</span>' +
                        '</div>';
                      return displayElem;
                    } else {
                      displayElem = '<div class="wf-top" layout="row">' +
                        '<span class="status-data not-started">' + params.value + '</span>' +
                        '</div>';
                      return displayElem;
                    }
                  },
                  pinnedRowCellRenderer(params) {
                    return params.value;
                  }
                });
              } else {
                if (key === "VAN") {
                  this.columnDefs.push({
                    headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true,
                    cellRenderer(params) {
                      const displayElem = '<b>' + params.value + '</b>';
                      return displayElem;
                    },
                  });

                } else if (key === "BEAT") {
                  this.columnDefs.push({headerName: key, field: key, sortable: true, filter: false, lockPosition: true});
                } else if (key === "van_id" || key === 'dc_id') {
                  this.columnDefs.push({headerName: key, field: key, hide: true});
                } else if (key === "true_van_id") {
                  this.columnDefs.push({headerName: key, field: key, hide: true});
                } else if (key === "vansale_id") {
                  this.columnDefs.push({headerName: key, field: key, hide: true});
                } else if (key === "TOTAL SALES IN QTY") {
                  this.columnDefs.push({headerName: key, field: key, sortable: true, filter: false, hide: true});
                } else {
                  this.columnDefs.push({headerName: key, field: key, sortable: true, filter: false, hide: false});
                }
              }
            }
          }
          this.loaded = true;
          this.user_data.vansales = vansales;
          this.res_data = this.apiService.dclistdetail2(this.user_data);
          this.res_data.subscribe(res1 => {
            console.log(res1);
            const inthis = this;
            let updated_row;
            if (res1.hasOwnProperty('results') && (res1['results'].status === 200) && (res1['results'].data.length > 0)) {
              this.rowData.forEach(each_row => {
                updated_row = res1['results'].data.filter(existed => parseInt(each_row.vansale_id, 10) === parseInt(existed.id, 10));
                if (updated_row.length > 0) {
                  each_row['LOADIN QTY'] = updated_row[0].carryover;
                  each_row['LOADOUT VALUE'] = updated_row[0].loadout_val;
                  each_row['CASH'] = updated_row[0].cash.toFixed(inthis.apiService.decimalPolicy);
                  each_row['CREDIT'] = updated_row[0].credit.toFixed(inthis.apiService.decimalPolicy);
                  each_row['TOTAL SALES IN VALUE'] = updated_row[0].sales.toFixed(inthis.apiService.decimalPolicy);
                  each_row['TOTAL SALES IN QTY'] = updated_row[0].total_qty;

                  if (updated_row[0].carryover != null) {
                    c_o += parseInt(updated_row[0].carryover, 10);
                  }
                  if (updated_row[0].loadout_val != null) {
                    l_o += parseInt(updated_row[0].loadout_val, 10);
                  }
                  if (updated_row[0].cash != null) {
                    cash += parseFloat(updated_row[0].cash);
                  }
                  if (updated_row[0].credit != null) {
                    credit += parseInt(updated_row[0].credit, 10);
                  }
                  if (updated_row[0].sales != null) {
                    t_s += parseFloat(updated_row[0].sales);
                  }
                  if (updated_row[0].total_qty != null) {
                    t_q += parseInt(updated_row[0].total_qty, 10);
                  }
                }
              });
              this.gridOptions.pinnedBottomRowData = [{
                VAN: '',
                STATUS: '',
                BEAT: 'TOTAL',
                'LOADIN VALUE': c_o,
                'LOADOUT VALUE': l_o,
                TC: retailer_total,
                PC: pc,
                VC: vc,
                UV: uv,
                DC: dc,
                CASH: cash.toFixed(this.apiService.decimalPolicy),
                CREDIT: credit.toFixed(this.apiService.decimalPolicy),
                'TOTAL SALES IN VALUE': t_s.toFixed(this.apiService.decimalPolicy),
                'TOTAL SALES IN QTY': t_q
              }
              ];
              this.gridApi.setRowData(this.rowData);
              this.gridApi.setPinnedBottomRowData(this.gridOptions.pinnedBottomRowData);
            }

          }, error => {
            console.log(error);
          });

        } else {
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => console.log(error));
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.setPinnedBottomRowData(this.gridOptions.pinnedBottomRowData);
    params.api.setRowData(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
    this.gridApi.paginationSetPageSize(15);

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid');
  }

  ngOnInit() {
    this.passedParams = JSON.parse(localStorage.getItem('wflist_params'));
    this.passedParams.not_loaded = parseInt(this.passedParams['TOTAL VANS'], 10) - parseInt(this.passedParams['ordered_vans'], 10);
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
    this.cardLoader = false;
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {

    this.user_data.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    localStorage.setItem('my_date', this.user_data.date);
    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.apiDcVanList();
  }

  changeStatus(event) {

    this.loaded = false;
    const filtStatus = event.itemName;
    if (filtStatus === 'Status All') {
      this.gridOptions.api.setPinnedBottomRowData(this.gridOptions.pinnedBottomRowData);
      this.gridOptions.api.setRowData(this.rowData);
      this.loaded = true;
    } else {
      let retailer_total = 0;
      let c_o = 0;
      let l_o = 0;
      let pc = 0, vc = 0, dc = 0, cash = 0, credit = 0, t_s = 0, t_q = 0;
      const changedData = [];
      this.rowData.forEach(row => {
        if (row['STATUS'].status === filtStatus) {
          changedData.push(row);
          retailer_total += parseInt(row['NO OF RETAILER'], 10);
          c_o += parseInt(row['LOADIN VALUE'], 10);
          l_o += parseInt(row['LOADOUT VALUE'], 10);
          pc += parseInt(row['PC'], 10);
          vc += parseInt(row['VC'], 10);
          dc += parseInt(row['DC'], 0);
          cash += parseFloat(row['CASH']);
          credit += parseFloat(row['CREDIT']);
          t_s += parseFloat(row['TOTAL SALES IN VALUE']);
          t_q += parseInt(row['TOTAL SALES IN QTY'], 10);
        }
      });

      const bottomRow = [{
        VAN: '',
        STATUS: '',
        BEAT: 'TOTAL',
        'NO OF RETAILER': retailer_total,
        'LOADIN VALUE': c_o,
        'LOADOUT VALUE': l_o,
        PC: pc,
        VC: vc,
        DC: dc,
        CASH: cash,
        CREDIT: credit,
        'TOTAL SALES IN VALUE': t_s,
        'TOTAL SALES IN QTY': t_q,
      }
      ];

      this.gridOptions.api.setPinnedBottomRowData(bottomRow);

      this.gridOptions.api.setRowData(changedData);
      this.loaded = true;
    }
  }

  changeQty(params) {

    if (params.itemName === 'Sales in Qty') {
      this.gridOptions.columnApi.setColumnsVisible(["TOTAL SALES IN VALUE"], false);
      this.gridOptions.columnApi.setColumnsVisible(["TOTAL SALES IN QTY"], true);
    } else if (params.itemName === 'Sales in Value') {
      this.gridOptions.columnApi.setColumnsVisible(["TOTAL SALES IN VALUE"], true);
      this.gridOptions.columnApi.setColumnsVisible(["TOTAL SALES IN QTY"], false);
    }
  }

}
