import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ICellEditorParams } from "@ag-grid-enterprise/all-modules";
import {ICellEditorAngularComp, ICellRendererAngularComp} from "@ag-grid-community/angular";

// backspace starts the editor on Windows
const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';

@Component({
  selector: 'app-numeric-cell',
  template: `<input *ngIf="editMode"
    #input
    [(ngModel)]="value"
    class="custom-inputbox-grid"
  /><span *ngIf="!editMode"  class="element-req">{{value}}</span>`,
})
export class NumericEditorComponent implements ICellRendererAngularComp {
  private params: any;
  public value!: number;
  public highlightAllOnFocus = true;
  private cancelBeforeStart = false;
  public editMode = false;
  public context;

  @ViewChild('input', { read: ViewContainerRef })
  public input!: ViewContainerRef;

  agInit(params: any): void {
    console.log(params.colDef.field);
    this.params = params;
   // this.setInitialState(this.params);
    this.value = params.value;
    // only start edit if key pressed is a number, not a letter
    this.cancelBeforeStart = !!(
      params.charPress && '1234567890'.indexOf(params.charPress) < 0
    );
    if (this.params.context.componentParent) {
      this.context = this.params.context.componentParent;
    } else {
      this.context = this.params?.context?.params?.context?.context?.componentParent;
    }
    if (this.context) {
      this.editMode = this.context.editMode;
      if (params.colDef.field === 'discount' || params.colDef.field === 'disc_value' || params.colDef.field === 'disc_qty') {
        this.discountEditCheck('discount');
        this.discountEditCheck('disc_value');
        this.discountEditCheck('disc_qty');
      }
    }
  }
  discountEditCheck( field) {
    if (this.params.colDef.field === field && this.context.edit_discount_field === field) {
      this.editMode = true;
    } else {
      this.editMode = false;
    }
  }
  refresh(): boolean {
    return false;
  }

/*  setInitialState(params: ICellEditorParams) {
    let startValue;
    let highlightAllOnFocus = true;

    if (params.eventKey === KEY_BACKSPACE) {
      // if backspace or delete pressed, we clear the cell
      startValue = '';
    } else if (params.charPress) {
      // if a letter was pressed, we start with the letter
      startValue = params.charPress;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = params.value;
      if (params.eventKey === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    this.value = startValue;
    this.highlightAllOnFocus = highlightAllOnFocus;
  }

  getValue(): any {
    return this.value;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  // will reject the number if it greater than 1,000,000
  // not very practical, but demonstrates the method.
  isCancelAfterEnd(): boolean {
    return this.value > 1000000;
  }

  onKeyDown(event: any): void {
    if (this.isLeftOrRight(event) || this.isBackspace(event)) {
      event.stopPropagation();
      return;
    }

    if (
      !this.finishedEditingPressed(event) &&
      !this.isKeyPressedNumeric(event)
    ) {
      if (event.preventDefault) { event.preventDefault(); }
    }
    if (this.finishedEditingPressed(event)) {
      this.input.element.nativeElement.blur();
      this.params.context.componentParent.gridApi.tabToNextCell();
    }
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    if (this.editMode) {
      window.setTimeout(() => {
        this.input.element.nativeElement.focus();
        if (this.highlightAllOnFocus) {
          this.input.element.nativeElement.select();

          this.highlightAllOnFocus = false;
        } else {
          // when we started editing, we want the caret at the end, not the start.
          // this comes into play in two scenarios:
          //   a) when user hits F2
          //   b) when user hits a printable character
          const length = this.input.element.nativeElement.value
            ? this.input.element.nativeElement.value.length
            : 0;
          if (length > 0) {
            this.input.element.nativeElement.setSelectionRange(length, length);
          }
        }

        this.input.element.nativeElement.focus();
      });
    }
  }

  private isCharNumeric(charStr: string): boolean {
    return !!/\d/.test(charStr);
  }

  private isKeyPressedNumeric(event: any): boolean {
    const charStr = event.key;
    return this.isCharNumeric(charStr);
  }

  private isBackspace(event: any) {
    return event.key === KEY_BACKSPACE;
  }

  private isLeftOrRight(event: any) {
    return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
  }

  private finishedEditingPressed(event: any) {
    const key = event.key;
    return key === KEY_ENTER || key === KEY_TAB;
  }*/
}
