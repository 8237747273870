import {Component, ComponentFactoryResolver, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {GridOptions} from "../../../node_modules/@ag-grid-enterprise/all-modules";
import {ApiService} from "../_services/api.service";
import {ActivatedRoute, Router} from '@angular/router';
import {CellEditRenderer} from "../editCell.component";
import {DatePipe} from '@angular/common';
import {PinnedCellRenderer} from "../pinnedCellRenderer.component";
import {VanDisplayRendererComponent} from "../vanShow.component";
import {SupplierDropdownComponent} from "../components/supplier_dropdown/supplier-dropdown.component";
import {SuppProdDropdownComponent} from "../components/supp_prod_dropdown/supp-prod-dropdown.component";
import {ColorCellRenderer} from "../colorCell.component";
import {CellEdit2Renderer} from "../editCell2.component";
import {AdDirective2} from "../ad2.directive";
import {AdDirective3} from "../ad3.directive";
import {ToastrService} from "ngx-toastr";
import {CustomtooltipComponent} from "../customtooltip.component";
import {DomSanitizer} from "@angular/platform-browser";
import {SharedService} from "../_services/shared_service";

@Component({
  selector: 'app-prolist',
  templateUrl: './prolist.component.html',
  styleUrls: ['./prolist.component.scss']
})
export class ProlistNewComponent implements OnInit {
  @ViewChild('newLoadout') newLoadout: ElementRef<HTMLElement>;
  @ViewChild(AdDirective2, {static: true}) adHost2: AdDirective2;
  @ViewChild(AdDirective3, {static: true}) adHost3: AdDirective3;
  @ViewChild('interPrint') public interPrint: ElementRef;

  public all_orders = [];
  res_data;
  user_data = {
    loadout_data: null,
    date: null,
    dc_id: null,
    access_token: null,
    "pc_id": null,
    "po_id": null,
    "url": null,
    "order_id": null,
    "dispatch": false,
    "dc_centers": [],
    "supplier_id": null,
    customer_id: 1,
    "pricelist_id": 0,
    "flag": false,
    feature_sug: false
  };
  public sendConfirm = false;
  public dispatch_feature = false;
  public updateConfirm = false;
  public columnDefs = [];
  public columnDefs1 = [];
  public columnDefsPO = [];
  public searchValue;
  public frameworkComponents;
  public context;
  public van_show = true;
  public frameworkComponents1;
  public context1;
  public rowData = [];
  public rowData1 = [];
  public rowDataPO = [];
  public gridOptions: GridOptions;
  public gridOptions1: GridOptions;
  public gridOptionsPO: GridOptions;
  public datavalue;
  public data = [];
  public data1;
  public uploading = false;
  public upload_loader = false;
  public loaded = false;
  public loaded1 = false;
  public loadedPO = false;
  public preloader = false;
  public preloaderPO = false;
  public empty_state = false;
  public empty_statePO = false;
  public prod_loaded = false;
  public productList = [];
  public POproductList = [];
  public passedParams;
  public saveData = [];
  public newprodData = [];
  public saveProdData = [];
  public multiple_loadout = false;
  public disable_send = false;
  public editLoadout = false;
  public stockData1 = {
    "line_data": [],
    "po_id": null
  };
  public stockData2 = {
    "stock_data": [],
    "order_ids": [],
    "po_id": null,
    "status": ""
  };
  public get_user_data;
  public myDate;
  public saveCaption = ["", 0];
  public uploadCaption = ["", 0];
  public all_products = {};
  public styleGrid;
  public colIds;
  public collapse_disable;
  public submit = true;
  public dispatch = false;
  public draft_total = [];
  public pinnedData2 = [
    {
      "VAN": "AVAILABLE QTY"
    }];
  public pinnedData = [{
    "VAN": "PRODUCED QTY"
  },
    {
      "VAN": "AVAILABLE QTY"
    }];
  public lastpinnedData = [
    {
      "VAN": "TOTAL QTY"
    }];
  public KEY_LEFT = 37;
  public KEY_UP = 38;
  public KEY_RIGHT = 39;
  public KEY_DOWN = 40;
  public edit_order_id = null;
  public pop_loader = false;
  public products;
  public status;
  public status_class;
  public product_summary = {VAN: 'REQUESTED'};
  public po_line_data = [];
  public role_features = [];
  public edit_enable = false;
  public edit_notplaced = false;
  public wait_save = true;
  public direct_dispatch = false;
  public loadoutProds = [];
  public loadout_prod = [];
  public van_code;
  public no_po;
  public vans = [];
  public confirmEnable = true;
  public enable_recommented = false;
  public tooltipShowDelay = 0;
  public tooltipHideDelay = 2400000;
  public sugTotal;
  private gridApi;
  private gridApi1;
  private gridApiPO;
  private gridColumnApi;
  private gridColumnApi1;
  private gridColumnApiPO;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  private sideBar;
  private rowHeight;
  apiloaded = false;
  public delivery_status = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private apiService: ApiService,
              private delRef: ElementRef,
              private sharedService: SharedService,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private toastr: ToastrService,
              private componentFactoryResolver: ComponentFactoryResolver,
              private datePipe: DatePipe,
              private domSanitizer: DomSanitizer) {

    this.get_user_data = JSON.parse(localStorage.getItem('user_data'));

    this.myDate = this.get_user_data.date;

    this.user_data.date = this.myDate;
    this.user_data.dc_id = JSON.parse(localStorage.getItem('params')).dc_id;
    this.user_data.supplier_id = this.user_data.dc_id;
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.pc_id = this.get_user_data.pc_id;
    this.user_data.po_id = JSON.parse(localStorage.getItem('params')).po_id;
    this.user_data.dc_centers = JSON.parse(localStorage.getItem('user')).dc_centers;
    this.user_data.url = localStorage.getItem('private_url');
    this.status = localStorage.getItem('po_status')[0].toUpperCase() + localStorage.getItem('po_status').slice(1);

    if (this.sharedService.role_features) {
      if (this.sharedService.ACLcheck('feature_po_edit')) {
        this.edit_enable = true;
      }
      if (this.sharedService.ACLcheck('feature_dispatch')) {
        this.dispatch_feature = true;
      }
      if (this.sharedService.ACLcheck('feature_allow_notplaced_edit')) {
        this.edit_notplaced = true;
      }
      if (this.sharedService.ACLcheck('feature_direct_dispatch')) {
        this.direct_dispatch = true;
      }
      if (this.sharedService.ACLcheck('feature_loadout_multiple')) {
        this.multiple_loadout = true;
      }
      if (this.sharedService.ACLcheck('feature_disable_sendproduction')) {
        this.edit_enable = true;
      }
      if (this.sharedService.ACLcheck('feature_enable_recommented')) {
        this.enable_recommented = true;
        this.user_data.feature_sug = true;
      }
    }

    this.no_po = this.multiple_loadout;
    this.vans = JSON.parse(localStorage.getItem('loadout_vans'));

    this.res_data = this.apiService.supplierProducts(this.user_data);
    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
        this.loadoutProds = res['results'].data;
      }
    }, error => {
      console.log(error);
    });


    this.res_data = this.apiService.getPOStatus(this.user_data);

    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {

        if (res['results'].data.length > 1) {
          var po_status = '';
          res['results'].data.forEach(function(po) {
            if (po.id === JSON.parse(localStorage.getItem('params')).po_id) {
              po_status = po.status[0].toUpperCase() + po.status.slice(1);
            }
          });
        }

        if (po_status === '' || po_status === undefined) {
          this.status = res['results'].data[0].status[0].toUpperCase() + res['results'].data[0].status.slice(1);
        } else {
          this.status = po_status;
        }
        localStorage.setItem('po_status', this.status);

        if (this.status === 'Draft') {
          this.status_class = 'green-status';
        } else if (this.status === 'Completed' || this.status === 'Inprogress') {
          this.status_class = 'grey-status';
        } else {
          this.status_class = 'blue-status';
        }

        if (this.status === 'Processed') {
          this.loaded = false;
          this.preloader = true;
          this.res_data = this.apiService.getProduction(this.user_data);
          this.res_data.subscribe(res => {
              console.log(res);
              if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
                this.po_line_data = res['results'].data;
              }
            },
            error => {
              console.log(error);
            });
          this.sendTOproduction(3);
        } else if (this.status === 'Completed') {
          this.loaded = false;
          this.preloader = true;
          this.sendTOproduction(4);
        } else if (this.status === 'Inprogress') {
          this.loaded = false;
          this.preloader = true;
          this.sendTOproduction(5);
        } else if (this.status === 'Submitted') {
          this.res_data = this.apiService.getProduction(this.user_data);
          this.res_data.subscribe(res => {
              console.log(res);
              if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
                this.po_line_data = res['results'].data;
                this.apiProList(1);
              }
            },
            error => {
              console.log(error);
            });
        } else {
          this.apiProList(0);
        }
      }
    }, error => {
      console.log(error);
    });

    let all_prod = {};

    this.res_data = this.apiService.getProducts(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          res['results'].data.forEach(function(data) {
            all_prod['p_' + data.id] = {
              'name': data.name,
              'product_uom': data.uom_id,
              'cid': data.cid,
              'code': data.code,
              'price': data.price,
              'taxmaster_id': data.taxmaster_id,
              'tax_pct': data.tax_pct,
              'stock': data.stock
            };
          });
          this.all_products = all_prod;
          console.log(this.all_products);
          localStorage.setItem('products', JSON.stringify(this.all_products));
        }
      },
      error => console.log(error));


    this.gridOptions1 = <GridOptions> {
      rowHeight: 35,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      enableRangeSelection: true,
      enableCharts: true,
      chartThemes: ['ag-pastel-dark']
    };


    this.gridOptions = <GridOptions> {
      groupHeaderHeight: 30,
      headerHeight: 60,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left', 'padding': '20px 0px'},
      getRowStyle: this.getRowStyleFunc,
      isRowSelectable: function(rowNode) {
        return ((!rowNode.data.duplicate) && (rowNode.data.dispatch_status === 'new') &&
          (rowNode.data.last_eod === null) && (rowNode.data.last_started === null));
      }
    };

    this.gridOptions.getRowHeight = function(params) {
      var row_ht = 43;
      if (params.data['VAN'] === 'PRODUCED QTY') {
        row_ht = 40;
      } else if (params.data['VAN'] === 'AVAILABLE QTY') {
        row_ht = 60;
      }
      return row_ht;
    };

    this.gridOptionsPO = <GridOptions>{
      rowHeight: 35,
      rowSelection: 'multiple',
      enableRangeSelection: true,
      enableCharts: true,
      chartThemes: ['ag-pastel-dark'],
      rowClassRules: {
        'ag-grid-grey':   (params) => {
          return params.node.rowIndex % 2 === 0;
        },
      }
    };
  }

  getRowStyleFunc(params) {
    var dev = '0px';
    if (params.node.rowIndex !== 0) {
      dev = (-26 - 26 * (params.node.rowIndex - 1)) + 'px';
    }
    if (params.data.ostatus === 'inactive') {
      return {
        'background-color': 'FloralWhite'
      };
    }

    if (params.data.dispatch_status === 'dispatched' || params.data.dispatch_status === 'delivered') {
      return {
        'background-color': 'Gainsboro'
      };
    }
  }

  async apiProList(times) {
    this.apiloaded = false;
    this.columnDefs = [];
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    let rowdata = [];
    let totalData = {VAN: 'REQUESTED', QTY: 0};
    let sugTotal = {VAN: 'SUGGESTED', QTY: 0};
    let product_summary = {VAN: 'REQUESTED'};
    let lastPinned = this.lastpinnedData[0];
    this.draft_total = [];
    let keydata = [];
    let order_ids = [];
    // let order_qty;
    let po_line_data = this.po_line_data;
    let updated_data;
    let save_data = [];
    let newprodData = [];
    let complete = true;
    let prod_categories;
    let exist_vans = {};
    let duplicate = false;
    let active_prods = [];
    let preOrders = [];
    let van_preorder = [];

    let res = await this.apiService.polistNew(this.user_data);
    let all_status = this.status;
    let inthis = this;
    this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
    const all_pro = this.all_products;
    let multiple_loadout = this.multiple_loadout;
    if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
      console.log(res);
      prod_categories = res['results'].category_data;
      preOrders = res['results'].pre_order;

      for (key in this.all_products) {
        keydata.push(key);
        product_summary[key] = 0;
        totalData[key] = 0;
        sugTotal[key] = 0;
        lastPinned[key] = 0;
      }
      res['results'].data.forEach(function(data) {
        if (multiple_loadout) {
          duplicate = false;
        } else {
          if (exist_vans[data.name]) {
            duplicate = true;
          } else {
            duplicate = false;
            exist_vans[data.name] = true;
          }
        }
        if (data.d_status === 'delivered') {
          inthis.delivery_status = true;
        }
        var pushData = {
          duplicate: duplicate,
          VAN: data.name,
          van_code: data.code,
          order_id: data.order_id,
          po: data.po,
          dispatch_status: data.d_status,
          all_status: all_status,
          ostatus: data.ostatus,
          vansale_id: data.vansale_id,
          van_id: data.van_id,
          user_territory_id: data.user_territory_id,
          user_id: data.user_id,
          last_eod: data.last_eod,
          last_started: data.last_started,
          van_status: data.status
        };

        if (data.order_id !== null) {
          order_ids.push(data.order_id);
        }
        if (data.total_qty !== null) {
          product_summary['QTY'] += parseInt(data.total_qty, 10);
        }
        for (const key in all_pro) {

          var active = true;
          if (data.act_prod) {
            active_prods = data.act_prod.filter(function(existed) {
              return (parseInt(key.split('_')[1], 10) === parseInt(existed, 10));
            });
          }
          if (active_prods.length === 0) {
            active = false;
          }

          var splitData;
          let qty = 0;
          let sug = 0;
          let mbq_config;
          let mbq_preorder = 0;
          let mbq_sug = 0;
          let mbq_closing = 0;
          let mbq_channel = [];
          let pre_orders;
          let pre_qty = 0;
          let act_clos = 0;
          let act_total = 0;
          let min = 0;
          let max = 0;
          const find_prod = data.products.filter(function(existed) {
            return (parseInt(key.split('_')[1], 10) === parseInt(existed.product_id, 10));
          });
          van_preorder = preOrders.filter(function(existed) {
            return parseInt(data.van_id, 10) === existed.id;
          });
          if (van_preorder.length > 0 && van_preorder[0].pre_order) {
            van_preorder = van_preorder[0].pre_order;
          } else {
            van_preorder = [];
          }
          if (van_preorder) {
            pre_orders = van_preorder.filter(function(existed) {
              return (parseInt(key.split('_')[1], 10) === parseInt(existed.prod_id, 10));
            });
            if (pre_orders.length > 0 && parseInt(pre_orders[0].qty, 10) !== 0) {
              pre_qty = parseInt(pre_orders[0].qty, 10);
            }
          }
          if (inthis.enable_recommented) {
            if (data.closing) {
              updated_data = data.closing.filter(function(existed) {
                return parseInt(key.split('_')[1], 10) === existed.prod_id;
              });
              if (updated_data.length > 0 && updated_data[0].live_closing) {
                act_clos = parseInt(updated_data[0].live_closing, 10);
              }
            }
            if (!data.mbq_qty) {
              data.mbq_qty = [];
            }
            updated_data = data.mbq_qty.filter(function(existed) {
              return parseInt(key.split('_')[1], 10) === existed.prod_id;
            });
            if (updated_data.length > 0 && parseInt(updated_data[0].qty, 10) !== 0) {
              if (van_preorder) {
                pre_orders = van_preorder.filter(function(existed) {
                  return (parseInt(key.split('_')[1], 10) === parseInt(existed.prod_id, 10));
                });
                if (pre_orders.length > 0 && parseInt(pre_orders[0].qty, 10) !== 0) {
                  pre_qty = parseInt(pre_orders[0].qty, 10);
                }
              }
              mbq_sug = parseInt(updated_data[0].qty, 10);
              mbq_config = updated_data[0].config;
              min = updated_data[0].min;
              max = updated_data[0].max;
              if (mbq_config) {
                if (mbq_config.preorder) {
                  mbq_preorder = parseInt(mbq_config.preorder.qty, 10);
                  if (mbq_config.expected_closing) {
                    mbq_closing = parseInt(mbq_config.expected_closing, 10);
                  }
                }
                if (mbq_config.channel_split && mbq_config.channel_split !== '{}' && Object.keys(mbq_config.channel_split).length > 0) {
                  mbq_channel = mbq_config.channel_split[0];
                }
              }
            }
          }
          if (find_prod.length > 0) {
            if (!active) {
              pushData[keydata[key]] = {
                qty: 0, sug: 0, prvs_qty: 0, active, ord_sug: 0, pre: 0, pre_sug: 0,
                clos_sug: 0, act_clos: 0, act_total, min: 0, max: 0
              };
            } else {
              if (mbq_sug !== 0) {
                if (!data.last_started && data.closing) {
                  if (pre_qty === 0) {
                    if ((mbq_sug - act_clos) > 0) {
                      sugTotal[key] += mbq_sug - act_clos;
                    }
                  } else {
                    if ((mbq_sug - mbq_preorder + pre_qty - act_clos) > 0) {
                      sugTotal[key] += mbq_sug - mbq_preorder + pre_qty - act_clos;
                    }
                  }
                } else {
                  act_clos = 0;
                  if (pre_qty === 0) {
                    if ((mbq_sug - mbq_closing) > 0) {
                      sugTotal[key] += mbq_sug - mbq_closing;
                    }
                  } else {
                    if ((mbq_sug - mbq_preorder + pre_qty - mbq_closing) > 0) {
                      sugTotal[key] += mbq_sug - mbq_preorder + pre_qty - mbq_closing;
                    }
                  }
                }
              }
              let change_color = false;
              if (find_prod[0].state === 'cancel' || find_prod[0].state === 'draft') {
                if (inthis.enable_recommented && mbq_sug !== 0) {
                  if (data.last_started) {
                    if (pre_qty === 0) {
                      qty = mbq_sug - mbq_closing;
                      act_total = mbq_sug - act_clos;
                      min = min - mbq_closing;
                      max = max - mbq_closing;
                    } else {
                      qty = mbq_sug - mbq_preorder + pre_qty - mbq_closing;
                      act_total = mbq_sug - mbq_preorder + pre_qty - act_clos;
                      min = min - mbq_preorder + pre_qty - mbq_closing;
                      max = max - mbq_preorder + pre_qty - mbq_closing;
                    }
                  } else {
                    if (pre_qty === 0) {
                      qty = mbq_sug - act_clos;
                      min = min - act_clos;
                      max = max - act_clos;
                    } else {
                      qty = mbq_sug - mbq_preorder + pre_qty - act_clos;
                      min = min - mbq_preorder + pre_qty - act_clos;
                      max = max - mbq_preorder + pre_qty - act_clos;
                    }
                    act_total = qty;
                  }
                  sug = find_prod[0].ordered_qty;
                } else {
                  qty = find_prod[0].ordered_qty;
                  sug = 0;
                }
                if (qty < 0) {
                  qty = 0;
                }
                if (min < 0) {
                  min = 0;
                }
                if (max < 0) {
                  max = 0;
                }
                if (parseFloat(find_prod[0].ordered_qty) !== parseFloat(String(qty))) {
                  change_color = true;
                }
                pushData[key] = {
                  qty: qty, sug: sug, prvs_qty: find_prod[0].ordered_qty, ordered: find_prod[0].ordered_qty,
                  value: find_prod[0].price_total, active: active, ord_sug: mbq_sug, pre: pre_qty, pre_sug: mbq_preorder,
                  clos_sug: mbq_closing, act_clos: act_clos, act_total, min, max, change_color, channel: mbq_channel
                };
                product_summary[key] += parseInt(String(qty), 10);
                lastPinned[key] += parseInt(String(qty), 10);
                if (times === 2 || times === 4) {
                  var price_subtotal = (parseFloat(all_pro[key].price) * parseInt(String(qty), 10)).toFixed(inthis.apiService.decimalPolicy);
                  save_data.push({
                    'order_id': data.order_id,
                    'product_id': key.split('_')[1],
                    'state': 'done',
                    'qty': qty,
                    'prvs_qty': find_prod[0].ordered_qty,
                    'price_subtotal': price_subtotal,
                    'line_id': find_prod[0].line_id
                  });
                }
              } else if (find_prod[0].state === 'done' || find_prod[0].state === 'sale') {
                if (parseInt(find_prod[0].delivered_qty, 10) < 0) {
                  find_prod[0].delivered_qty = 0;
                }
                if (inthis.enable_recommented && mbq_sug !== 0) {
                  if (data.last_started) {
                    if (pre_qty === 0) {
                      qty = mbq_sug - mbq_closing;
                      act_total = mbq_sug - act_clos;
                      min = min - mbq_closing;
                      max = max - mbq_closing;
                    } else {
                      qty = mbq_sug - mbq_preorder + pre_qty - mbq_closing;
                      act_total = mbq_sug - mbq_preorder + pre_qty - act_clos;
                      min = min - mbq_preorder + pre_qty - mbq_closing;
                      max = max - mbq_preorder + pre_qty - mbq_closing;
                    }
                  } else {
                    if (pre_qty === 0) {
                      qty = mbq_sug - act_clos;
                      min = min - act_clos;
                      max = max - act_clos;
                    } else {
                      qty = mbq_sug - mbq_preorder + pre_qty - act_clos;
                      min = min - mbq_preorder + pre_qty - act_clos;
                      max = max - mbq_preorder + pre_qty - act_clos;
                    }
                    act_total = qty;
                  }
                  if (qty < 0) {
                    qty = 0;
                  }
                  if (min < 0) {
                    min = 0;
                  }
                  if (max < 0) {
                    max = 0;
                  }
                  if (find_prod[0].ordered_qty !== find_prod[0].delivered_qty) {
                    change_color = true;
                  }
                } else {
                  if (find_prod[0].ordered_qty !== find_prod[0].delivered_qty) {
                    change_color = true;
                  }
                }
                pushData[key] = {
                  qty: find_prod[0].delivered_qty, sug: find_prod[0].ordered_qty, prvs_qty: find_prod[0].delivered_qty,
                  ordered: find_prod[0].ordered_qty, value: find_prod[0].price_total, active, ord_sug: mbq_sug, pre: pre_qty, pre_sug: mbq_preorder,
                  clos_sug: mbq_closing, act_clos, act_total, change_color, min, max, channel: mbq_channel
                };
                product_summary[key] += parseInt(find_prod[0].delivered_qty, 10);
                lastPinned[key] += parseInt(find_prod[0].delivered_qty, 10);
                if (times === 2 || times === 4) {
                  var price_subtotal = (parseFloat(all_pro[key].price) * parseInt(find_prod[0].delivered_qty, 10)).toFixed(inthis.apiService.decimalPolicy);
                  save_data.push({
                    'order_id': data.order_id,
                    'product_id': key.split('_')[1],
                    'state': 'done',
                    'qty': find_prod[0].delivered_qty,
                    'prvs_qty': find_prod[0].delivered_qty,
                    'price_subtotal': price_subtotal,
                    'line_id': find_prod[0].line_id
                  });
                }
              }
            }
          } else {
            if (inthis.enable_recommented) {
              let sug_qty = 0;
              if (mbq_sug !== 0) {
                if (data.last_started) {
                  act_clos = 0;
                  if (pre_qty === 0) {
                    sug_qty = mbq_sug - mbq_closing;
                    act_total = mbq_sug - act_clos;
                    min = min - mbq_closing;
                    max = max - mbq_closing;
                  } else {
                    sug_qty = mbq_sug - mbq_preorder + pre_qty - mbq_closing;
                    act_total = mbq_sug - mbq_preorder + pre_qty - act_clos;
                    min = min - mbq_preorder + pre_qty - mbq_closing;
                    max = max - mbq_preorder + pre_qty - mbq_closing;
                  }
                } else {
                  if (pre_qty === 0) {
                    sug_qty = mbq_sug - act_clos;
                    min = min - act_clos;
                    max = max - act_clos;
                  } else {
                    sug_qty = mbq_sug - mbq_preorder + pre_qty - act_clos;
                    min = min - mbq_preorder + pre_qty - act_clos;
                    max = max - mbq_preorder + pre_qty - act_clos;
                  }
                  act_total = sug_qty;
                }
                if (sug_qty < 0) {
                  sug_qty = 0;
                }
                if (min < 0) {
                  min = 0;
                }
                if (max < 0) {
                  max = 0;
                }
                pushData[key] = {
                  qty: sug_qty, sug: 0, prvs_qty: 0, active: active,
                  ord_sug: mbq_sug, pre: pre_qty, pre_sug: mbq_preorder, clos_sug: mbq_closing, act_clos: act_clos, act_total, min, max, channel: mbq_channel
                };
                if (data.order_id) {
                  product_summary[key] += sug_qty;
                  lastPinned[key] += sug_qty;
                }
                sugTotal[key] += sug_qty;
                var date = (new Date().toISOString()).replace('T', ' ');
                date = date.replace('Z', ' ');
                var price_subtotal = (parseFloat(all_pro[key].price) * sug_qty).toFixed(inthis.apiService.decimalPolicy);
                newprodData.push({
                  "order_id": data.order_id,
                  "partner_id": data.van_id,
                  "distributor_id": inthis.user_data.dc_id,
                  "product_id": parseInt(key.split('_')[1], 10),
                  "van_code": data.code,
                  "state": "done",
                  "product_uom": inthis.all_products[key].product_uom,
                  "taxmaster_id": inthis.all_products[key].taxmaster_id,
                  "price_unit": inthis.all_products[key].price,
                  "product_code": inthis.all_products[key].code,
                  "customer_lead": 0,
                  "name": inthis.all_products[key].name,
                  "product_uom_qty": sug_qty,
                  "qty_delivered": sug_qty,
                  "type": 'normal',
                  "price_subtotal": price_subtotal,
                  "price_tax": 0,
                  "price_total": price_subtotal,
                  "create_date": date,
                  "write_date": date,
                  "prvs_qty": 0,
                  "cid": inthis.all_products[key].cid,
                  "po_id": inthis.user_data.po_id,
                  "po_date": inthis.user_data.date
                });
              } else {
                pushData[key] = {
                  qty: 0, sug: 0, prvs_qty: 0, active: active, ord_sug: 0, pre: 0, pre_sug: 0,
                  clos_sug: 0, act_clos: 0, act_sug: 0, act_total: 0, min: 0, max: 0, channel: []
                };
              }
            } else {
              pushData[key] = {
                qty: 0, sug: 0, prvs_qty: 0, active: active, ord_sug: 0, pre: 0, pre_sug: 0,
                clos_sug: 0, act_clos: 0, act_sug: 0, act_total: 0, min: 0, max: 0, channel: []
              };
            }
          }

          updated_data = po_line_data.filter(function(existed) {
            return parseInt(key.split('_')[1], 10) === existed.product_id;
          });
          if (updated_data.length > 0) {
            if (updated_data[0].qty_adjusted === 0) {
              totalData[key] = updated_data[0].qty_ordered;
            } else {
              totalData[key] = updated_data[0].qty_adjusted;
            }
          }
        }
        rowdata.push(pushData);
      });

      this.products = keydata;
      this.product_summary = product_summary;
      this.wait_save = true;
      this.sugTotal = sugTotal;
      this.draft_total.push(this.product_summary);
      this.saveData = save_data;
      this.newprodData = newprodData;
      this.lastpinnedData[0] = lastPinned;


      if (times === 1) {
        this.gridOptions.pinnedTopRowData = [];
        this.gridOptions.pinnedTopRowData.push(totalData);
      } else if (times === 3) {

        for (var i = 0; i < keydata.length; i++) {
          if (this.lastpinnedData[0][keydata[i]] === 0) {
            delete this.lastpinnedData[0][keydata[i]];
            rowdata.forEach(function(data) {
              delete data[keydata[i]];
            });
          }
          keydata.splice(i, 1);
          i -= 1;
        }

        this.gridOptions.pinnedTopRowData = this.lastpinnedData;
        this.products = keydata;

      } else if (times === 4) {
        var cash = 0, qty = 0;
        for (var prod in keydata) {
          let updated_data = [];

          updated_data = this.stockData2.stock_data.filter(function(existed) {
            return parseInt(keydata[prod].split('_')[1], 10) === existed.product_id;
          });

          if (updated_data.length !== 0) {
            updated_data[0].quantity = this.product_summary[keydata[prod]];
          }

          updated_data = this.po_line_data.filter(function(existed) {
            return parseInt(keydata[prod].split('_')[1], 10) === existed.product_id;
          });
          if (updated_data.length > 0) {
            this.pinnedData[0][keydata[prod]] = updated_data[0].qty_adjusted;
          }

          if (this.pinnedData[1][keydata[prod]] === undefined) {
            this.pinnedData[1][keydata[prod]] = 0;
          }

          let pinnedData = this.pinnedData;
          let sum, splitValue;

          if (typeof pinnedData[1][keydata[prod]] === 'string') {
            splitValue = pinnedData[1][keydata[prod]].split(' (');
            pinnedData[1][keydata[prod]] = parseInt(splitValue[0], 10);
          }

          rowdata.forEach(function(row_data) {
            if (row_data.dispatch_status === 'new') {
              complete = false;
              if (typeof pinnedData[1][keydata[prod]] === 'string') {
                splitValue = pinnedData[1][keydata[prod]].split(' (');
                pinnedData[1][keydata[prod]] = splitValue[0];
                sum = parseInt(splitValue[1].split(')')[0], 10);
              } else {
                sum = pinnedData[1][keydata[prod]];
              }
              pinnedData[1][keydata[prod]] = pinnedData[1][keydata[prod]] + " (" + (sum - parseInt(row_data[keydata[prod]].qty, 10)) + ")";
            }
          });

          this.pinnedData = pinnedData;

        }

        if (complete) {
          this.status = 'Completed';
          this.status_class = 'grey-status';
          localStorage.setItem('po_status', this.status);
          this.sendTOproduction(4);
        } else {
          this.gridOptions.pinnedTopRowData = this.pinnedData;
        }
      } else if (times === 0) {
        this.gridOptions.pinnedTopRowData = this.draft_total;
      } else if (this.pinnedData !== null) {
        var cash = 0, qty = 0;
        for (var prod in keydata) {
          let updated_data = [];

          updated_data = this.stockData2.stock_data.filter(function(existed) {
            return parseInt(keydata[prod].split('_')[1], 10) === existed.product_id;
          });

          if (updated_data.length !== 0) {
            updated_data[0].quantity = this.product_summary[keydata[prod]];
          }

          updated_data = this.po_line_data.filter(function(existed) {
            return parseInt(keydata[prod].split('_')[1], 10) === existed.product_id;
          });

          if (this.direct_dispatch) {

            if (this.pinnedData2[0][keydata[prod]] === undefined) {
              this.pinnedData2[0][keydata[prod]] = 0;
            }

            this.pinnedData2[0][keydata[prod]] = this.pinnedData2[0][keydata[prod]] + " (" +
              (parseInt(this.pinnedData2[0][keydata[prod]], 10) -
                parseInt(this.product_summary[keydata[prod]], 10)) + ")";
          } else {
            if (updated_data.length > 0) {
              this.pinnedData[0][keydata[prod]] = updated_data[0].qty_adjusted;
            }

            if (this.pinnedData[1][keydata[prod]] === undefined) {
              this.pinnedData[1][keydata[prod]] = 0;
            }

            this.pinnedData[1][keydata[prod]] = this.pinnedData[1][keydata[prod]] + " (" +
              (parseInt(this.pinnedData[1][keydata[prod]], 10) -
                parseInt(this.product_summary[keydata[prod]], 10)) + ")";
          }
        }

        if (this.status === 'Completed') {
          this.gridOptions.pinnedTopRowData = [];
        } else {
          if (this.direct_dispatch) {
            this.gridOptions.pinnedTopRowData = this.pinnedData2;
          } else {
            this.gridOptions.pinnedTopRowData = this.pinnedData;
          }
        }
        this.stockData2.order_ids = order_ids;
      }

      this.data = rowdata[0];
      this.rowData = rowdata;
      let hide = true;
      let cat;

      for (var categ in prod_categories) {
        cat = prod_categories[categ];
        this.columnDefs.push({
          headerName: cat.category,
          children: []
        });

        for (var prod in cat['products']) {
          if ( this.all_products['p_' + cat['products'][prod]]) {
            this.all_products['p_' + cat['products'][prod]]['categ'] = cat.category;
          }
        }
      }

      let cat_data = [];
      let all_products = this.all_products;

      for (var key in this.data) {
        if (this.data.hasOwnProperty(key)) {
          if (key !== 'last_eod' && key !== 'last_started' && this.data[key] !== null && typeof (this.data[key]) === 'object') {
            cat_data = this.columnDefs.filter(function(existed) {
              return all_products[key].categ === existed.headerName;
            });

            cat_data[0].children.push({
              headerName: all_products[key].name, field: key, sortable: true, resizable: true, width: 90,
              tooltipComponent: 'customtooltipComponent',
              tooltipField: key,
              cellRendererSelector(params) {
                if (params.node.rowPinned) {
                  return {
                    component: PinnedCellRenderer,
                  };
                } else {
                  return {
                    component: CellEditRenderer,
                  };
                }
              },
            });
          } else {
            if (key === "VAN") {
              let status = this.status;
              this.columnDefs.push({
                headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true, width: 150, resizable: true, pinned: 'left',
                headerCheckboxSelection: function(params) {
                  return (status === 'Processed' || status === 'Inprogress');
                },
                headerCheckboxSelectionFilteredOnly: false,
                checkboxSelection: function(params) {
                  return ((!params.data.duplicate) && (status === 'Processed' || status === 'Inprogress') && (params.data.dispatch_status === 'new') &&
                    (params.data.last_eod === null) && (params.data.last_started === null));
                },
                cellRenderer: 'vanDisplayRenderer'
              });

            } else if (key === "order_id" || key === 'duplicate') {
              this.columnDefs.push({
                headerName: key, field: key, hide: true
              });
            } else if (key === "po") {
              this.columnDefs.push({
                headerName: key, field: key, hide: true
              });
            } else if (key === "dispatch_status") {
              this.columnDefs.push({
                headerName: key, field: key, hide: true
              });
            } else if (key === "all_status") {
              this.columnDefs.push({
                headerName: key, field: key, hide: true
              });
            } else if (key === "ostatus") {
              this.columnDefs.push({
                headerName: key, field: key, hide: true
              });
            } else if (key === "vansale_id") {
              this.columnDefs.push({
                headerName: key, field: key, hide: true
              });
            } else if (key === "user_id" || key === "van_code") {
              this.columnDefs.push({
                headerName: key, field: key, hide: true
              });
            } else if (key === "user_territory_id") {
              this.columnDefs.push({
                headerName: key, field: key, hide: true
              });
            } else if (key === "last_eod") {
              this.columnDefs.push({
                headerName: key, field: key, hide: true
              });
            } else if (key === "last_started") {
              this.columnDefs.push({
                headerName: key, field: key, hide: true
              });
            } else if (key === "QTY") {
              hide = (times === 0 || times === 1);
              this.columnDefs.push({
                headerName: key,
                field: key,
                sortable: true,
                filter: false,
                lockPosition: true,
                pinned: 'left',
                hide: hide
              });
            } else if (key === "CASH") {
              hide = (times === 0 || times === 1);
              this.columnDefs.push({headerName: key, field: key, sortable: true, filter: false, hide: hide, lockPosition: true, pinned: 'left',});
            }
          }
        }
      }
      this.loaded = true;
      this.apiloaded = true;
      this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
      this.saveCaption = ["", 0];
      if (times === 2) {
        this.submit = false;
        this.dispatch = true;
      }

      this.context = {componentParent: this};
      this.frameworkComponents = {
        cellEditRenderer: CellEditRenderer,
        pinnedCellRenderer: PinnedCellRenderer,
        vanDisplayRenderer: VanDisplayRendererComponent,
        customtooltipComponent: CustomtooltipComponent
      };

    } else {
      this.preloader = false;
      this.empty_state = true;
      this.apiloaded = true;
    }
  }

  vanList() {
    const viewContainerRef2 = this.adHost2.viewContainerRef;
    viewContainerRef2.clear();

    const componentFactory2 = this.componentFactoryResolver.resolveComponentFactory(SupplierDropdownComponent);

    const componentRef2 = viewContainerRef2.createComponent<SupplierDropdownComponent>(componentFactory2);
    componentRef2.instance.itemList = this.vans;
    componentRef2.instance.statusChange.subscribe(res => {

      if (res && res.length > 0) {
        this.van_code = res[0].code;
      }
    });
  }

  prodList() {
    const viewContainerRef3 = this.adHost3.viewContainerRef;
    viewContainerRef3.clear();

    const componentFactory3 = this.componentFactoryResolver.resolveComponentFactory(SuppProdDropdownComponent);

    const componentRef3 = viewContainerRef3.createComponent<SuppProdDropdownComponent>(componentFactory3);
    componentRef3.instance.itemList = this.loadoutProds[0].products;
    componentRef3.instance.statusChange.subscribe(res => {

      if (res && res.length > 0) {
        this.loadout_prod.push(res[0]);
      }
    });
  }

  onCellClicked(event) {
    var column = event.column.getColId();
    if (this.multiple_loadout && column === 'VAN') {
      this.confirmEnable = true;
      this.editLoadout = true;
      var data = event.data;
      if (data.dispatch_status === 'delivered') {
        this.confirmEnable = false;
      }
      this.rowData1 = [];
      this.edit_order_id = data.order_id;
      for (var key in this.products) {
        if (parseInt(data[this.products[key]].qty, 10) !== 0) {
          this.rowData1.push({
            "PRODUCT": this.all_products[this.products[key]].name,
            "STOCK": this.all_products[this.products[key]].stock,
            "QTY": data[this.products[key]].qty,
            "PRICE": "",
            "code": this.all_products[this.products[key]].code,
            "loadout": true
          });
        }
      }
      if (this.gridApi1) {
        this.gridApi1.setRowData(this.rowData1);
      }
      this.van_code = event.data['van_code'];
      this.van_show = false;
      this.prodList();
      this.productEntry(false);
      let el: HTMLElement = this.newLoadout.nativeElement;
      el.click();
    }
  }

  new_loadout() {
    this.van_show = true;
    this.rowData1 = [];
    this.editLoadout = false;
    this.confirmEnable = true;
    if (this.gridApi1) {
      this.gridApi1.setRowData(this.rowData1);
    }
    this.vanList();
    this.prodList();
    this.productEntry(true);
    let el: HTMLElement = this.newLoadout.nativeElement;
    el.click();
  }

  addProduct() {
    let prod = this.loadout_prod[0];
    this.rowData1.push({
      "PRODUCT": prod.itemName,
      "STOCK": prod.stock,
      "QTY": 0,
      "PRICE": 0,
      "code": prod.code,
      "loadout": true
    });
    this.gridApi1.setRowData(this.rowData1);
    this.loadout_prod = [];
  }

  loadConfirm() {

    this.elRef.nativeElement.querySelector('#overlay').style.display = "block";
    let loadout_data = [];
    let van_code = this.van_code;
    this.user_data.flag = this.multiple_loadout;
    let editable = false;
    if (van_code) {
      let date = this.datePipe.transform(this.user_data.date, 'dd/MM/yyyy');
      if (this.editLoadout) {
        editable = true;
      }
      let edit_order_id = this.edit_order_id;
      this.rowData1.forEach(function(prod) {
        loadout_data.push({
          "VanCode": van_code,
          "LoadoutDate": date,
          "ProductCode": prod.code,
          "Qty": prod['QTY'],
          "editable": editable,
          "order_id": edit_order_id
        });
      });
      if (loadout_data.length > 0) {

        this.user_data.flag = this.multiple_loadout;
        this.user_data.loadout_data = loadout_data;
        if (this.user_data.dc_centers.length === 0) {
          this.user_data.dc_centers.push(this.user_data.dc_id);
        }
        this.res_data = this.apiService.uploadLoadout(this.user_data);
        this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results')) {
            window.alert(res['results'].msg);
            this.apiProList(0);
          }
        }, error => {
          this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
          console.log(error);
          if (error.error.error['msg'] === undefined) {
            window.alert("Failed");
          } else {
            window.alert(error.error.error['msg']);
          }
        });
      } else {
        this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
        window.alert('Enter Qty');
      }
    } else {
      this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
      window.alert('please select van');
    }
  }

  productEntry(new_val) {
    this.loaded1 = false;
    if (new_val) {
      this.rowData1 = [];
    }
    this.data1 = {
      "PRODUCT": "",
      "STOCK": "",
      "QTY": "",
      "PRICE": "",
      "code": "",
      "loadout": null
    };
    for (var key in this.data1) {
      if (this.data1.hasOwnProperty(key)) {
        if (key === 'PRICE' || key === 'code' || key === 'loadout') {
          this.columnDefs1.push({
            headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true, editable: false,
            hide: true
          });
        } else if (key === 'QTY') {
          this.columnDefs1.push({
            headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true, editable: false,
            cellRenderer: "cellEdit2Renderer",
          });
        } else {
          this.columnDefs1.push({
            headerName: key,
            field: key,
            sortable: true,
            filter: false,
            unSortIcon: true,
            lockPosition: true,
            editable: false,
          });
        }
      }
    }
    this.loaded1 = true;
    //this.context = this;
    this.frameworkComponents = {
      colorCellRenderer: ColorCellRenderer,
      cellEdit2Renderer: CellEdit2Renderer
    };
  }

  removeProduct(index) {
    this.rowData1.splice(index, 1);
    this.gridApi1.setRowData(this.rowData1);
  }

  onGridReady1(params) {
    //new
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.ColumnApi;
    params.api.setRowData(this.rowData1);
    this.gridApi1.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions1.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi1.autoSizeColumns();
    }
  }

  onGridReadyPO(params) {
    //new
    this.gridApiPO = params.api;
    this.gridColumnApiPO = params.ColumnApi;
    params.api.setRowData(this.rowDataPO);
    //this.gridApiPO.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptionsPO.api.sizeColumnsToFit();
    } else {
      this.gridColumnApiPO.autoSizeColumns();
    }
  }

  printPicklist() {
    const order_ids = [];
    const paramObject: any = {};
    paramObject.access_token =  localStorage.getItem('resfreshToken');
    paramObject.date = this.get_user_data.date;
    const stockObject = [];
    this.rowData.forEach(row => {
      if (row.dispatch_status === 'dispatched' || row.dispatch_status === 'delivered') {
        if (!paramObject.vansale_id && row.vansale_id) {
          paramObject.vansale_id = row.vansale_id;
          paramObject.van_id = row.van_id;
          paramObject.salesman_id = row.user_id;
        }
        stockObject.push({
          date: this.get_user_data.date,
          vansale_id: row.vansale_id,
          van_id: row.van_id,
          salesman_id: row.user_id
        });
        order_ids.push(row.order_id);
      }
    });
    const order_data = {
      access_token: this.user_data.access_token,
      url: this.user_data.url,
      order_id: order_ids
    };
    const proddata = {};
    this.apiService.post('/api/pwa_connects/get_picklist_print_details', order_data)
      .subscribe(async res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          await this.getPrintDetails(res.results.data);
          const el: HTMLElement = this.interPrint.nativeElement;
          el.click();
        }
      });
  }

  async getPrintDetails(orderdata) {
    const inThis = this;
    let total_qty = 0;
    let total_discount = 0;
    let printDetails = {};
    const all_orders = [];
    for (const data of orderdata) {
      total_qty = 0;
      printDetails = data;
      const tax_breaks = [];
      const taxnotes = [];
      printDetails['bill_no'] = data['vansale_id'];
      printDetails['label_config'] = {};
      const labels = JSON.parse(localStorage.getItem('custom_labels'));
      if (labels) {
        labels.forEach(lab => {
          printDetails['label_config'][lab.label] = lab.display;
        });
      } else {
        printDetails['label_config']['PrimaryCurrency'] = '';
        printDetails['label_config']['SecondaryCurrency'] = '';
        printDetails['label_config']['SupplierLabel'] = 'Supplier';
        printDetails['label_config']['TaxLabel'] = 'GST';
      }
      if (typeof printDetails['tax_notes'] === 'string') {
        printDetails['tax_notes'] = JSON.parse(printDetails['tax_notes']);
      }
      if (printDetails['partner_id'] === printDetails['partner_shipping_id']) {
        printDetails['customer_skip'] = true;
      } else {
        printDetails['customer_skip'] = false;
      }
      printDetails['print_text'] = inThis.domSanitizer.bypassSecurityTrustHtml(printDetails['print_text']);
      let rate = 0;
      let spli;
      printDetails['sale_items'].forEach(sale_item => {
        sale_item['label'] = '[' + sale_item.type + ']';
        const breaks = [];
        sale_item['new_tax'] = 0;
        sale_item['abs_qty'] = Math.abs(sale_item['delivered_qty']);
        total_qty += parseInt(sale_item.delivered_qty, 0);
        total_discount += parseFloat(sale_item.vps_dis);
        sale_item.amount_total = sale_item.unit_price * sale_item['abs_qty'];
        sale_item.amount_total = sale_item.amount_total.toFixed(inThis.apiService.decimalPolicy);
        sale_item.unit_price = sale_item.unit_price.toFixed(inThis.apiService.decimalPolicy);
      });
      printDetails['total_qty'] = total_qty;
      printDetails['discount'] = total_discount.toFixed(inThis.apiService.decimalPolicy);
      printDetails['invoice_date'] = inThis.datePipe.transform((new Date(printDetails['date_delivery'] + ' UTC')).toString(), 'dd/MM/yyyy');
      if (printDetails['logo']) {
        const resp = await inThis.apiService.fetchImageWait({
          access_token: inThis.user_data.access_token,
          url: inThis.user_data.url,
          data: [{store_fname: printDetails['logo'], container: "res_partner"}]
        });
        console.log(resp);
        if (resp['result'].length > 0) {
          printDetails['logo'] = inThis.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + resp['result'][0].bill_datas);
        }
        all_orders.push(printDetails);
      } else {
        all_orders.push(printDetails);
      }
      this.all_orders = all_orders;
      console.log(all_orders);
    }
  }

  deleteOrder(order_id) {
    this.user_data.order_id = order_id;


    this.res_data = this.apiService.delete_order(this.user_data);

    this.res_data.subscribe(res => {
      console.log(res);
      window.alert('deleted successfully');
      if (this.status === 'Processed') {
        this.loaded = false;
        this.preloader = true;
        this.res_data = this.apiService.getProduction(this.user_data);
        this.res_data.subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
              this.po_line_data = res['results'].data;
            }
          },
          error => {
            console.log(error);
          });
        this.sendTOproduction(3);
      } else if (this.status === 'Completed') {
        this.loaded = false;
        this.preloader = true;
        this.sendTOproduction(4);
      } else if (this.status === 'Inprogress') {
        this.loaded = false;
        this.preloader = true;
        this.sendTOproduction(5);
      } else if (this.status === 'Submitted') {
        this.res_data = this.apiService.getProduction(this.user_data);
        this.res_data.subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
              this.po_line_data = res['results'].data;
              this.apiProList(1);
            }
          },
          error => {
            console.log(error);
          });
      } else {
        this.apiProList(0);
      }
    }, error => {
      console.log(error);
      window.alert(error);
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.ColumnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid');
    var columns = params.columnApi.getAllDisplayedVirtualColumns();
    this.colIds = columns.map(function(column) {
      return column.colId;
    });
  }

  tabToNextCell() {

  }

  editCell(params, value, product, new_prod) {
    if (this.status === 'Draft' && new_prod) {
      let primary_prod_data;

      if (params.order_id === null) {
        primary_prod_data = this.newprodData.filter(function(existed) {
          return ((parseInt(product.split('_')[1], 10) === parseInt(existed.product_id, 10)) &&
            (params.van_code === existed.van_code));
        });
      } else {
        primary_prod_data = this.newprodData.filter(function(existed) {
          return ((parseInt(product.split('_')[1], 10) === parseInt(existed.product_id, 10)) &&
            (parseInt(params.order_id, 10) === parseInt(existed.order_id, 10)));
        });
      }

      if (primary_prod_data.length === 0 && parseInt(value, 10) > 0) {
        var price_subtotal = (parseFloat(this.all_products[product].price) * parseInt(value, 10)).toFixed(this.apiService.decimalPolicy);
        var price_tax = 0;
        var price_total = price_subtotal;
        var date = (new Date().toISOString()).replace('T', ' ');
        date = date.replace('Z', ' ');

        this.newprodData.push({
          "order_id": params.order_id,
          "partner_id": params.van_id,
          "distributor_id": this.user_data.dc_id,
          "product_id": parseInt(product.split('_')[1], 10),
          "van_code": params.van_code,
          "state": "done",
          "product_uom": this.all_products[product].product_uom,
          "taxmaster_id": this.all_products[product].taxmaster_id,
          "price_unit": this.all_products[product].price,
          "product_code": this.all_products[product].code,
          "customer_lead": 0,
          "name": this.all_products[product].name,
          "product_uom_qty": parseInt(value, 10),
          "qty_delivered": parseInt(value, 10),
          "type": 'normal',
          "price_subtotal": price_subtotal,
          "price_tax": 0,
          "price_total": price_total,
          "create_date": date,
          "write_date": date,
          "prvs_qty": params[product].qty,
          "cid": this.all_products[product].cid,
          "po_id": this.user_data.po_id,
          "po_date": this.user_data.date
        });
      } else {
        if (parseInt(value, 10) === 0) {
          const index = this.newprodData.indexOf(primary_prod_data[0], 0);
          if (index > -1) {
            this.newprodData.splice(index, 1);
          }
        } else {
          var price_subtotal = (parseFloat(this.all_products[product].price) * parseInt(value, 10)).toFixed(this.apiService.decimalPolicy);
          var price_tax = 0;
          var price_total = price_subtotal;
          primary_prod_data[0].product_uom_qty = parseInt(value, 10);
          primary_prod_data[0].qty_delivered = parseInt(value, 10);
          primary_prod_data[0].prvs_qty = params[product].qty;
          primary_prod_data[0].price_subtotal = price_subtotal;
          primary_prod_data[0].price_tax = price_tax;
          primary_prod_data[0].price_total = price_total;
        }
      }
    } else {
      let primary_prod_data;

      primary_prod_data = this.saveData.filter(function(existed) {
        return ((parseInt(product.split('_')[1], 10) === parseInt(existed.product_id, 10)) &&
          (parseInt(params.order_id, 10) === parseInt(existed.order_id, 10)));
      });

      var price_subtotal = (parseFloat(this.all_products[product].price) * parseInt(value, 10)).toFixed(this.apiService.decimalPolicy);

      if (primary_prod_data.length === 0) {
        this.saveData.push({
          "order_id": params.order_id,
          "product_id": parseInt(product.split('_')[1], 10),
          "qty": parseInt(value, 10),
          "state": "done",
          "prvs_qty": params[product].qty,
          "price_subtotal": price_subtotal
        });
      } else {
        primary_prod_data[0].qty = parseInt(value, 10);
        primary_prod_data[0].prvs_qty = params[product].qty;
        primary_prod_data[0].price_subtotal = (parseFloat(this.all_products[product].price) * parseInt(value, 10)).toFixed(this.apiService.decimalPolicy);
      }

    }


    this.product_summary[product] = parseInt(this.product_summary[product], 10) + (parseInt(value, 10) - parseInt(params[product].qty, 10));


    if (this.status === 'Processed') {

      let updated_data, input_data;

      updated_data = this.stockData2.stock_data.filter(function(existed) {
        return parseInt(product.split('_')[1], 10) === existed.product_id;
      });

      updated_data[0].quantity = (updated_data[0].quantity - params[product].prvs_qty) + parseInt(value, 10);
      params[product].prvs_qty = parseInt(value, 10);

      if (new_prod) {
        updated_data[0].new_prod[params.order_id] = parseInt(value, 10);
      }

      if (this.direct_dispatch) {
        this.gridOptions.pinnedTopRowData[0][product] = parseInt(this.gridOptions.pinnedTopRowData[0][product].toString().split(" (")[0], 10);
        this.gridOptions.pinnedTopRowData[0][product] = this.gridOptions.pinnedTopRowData[0][product] + " (" + (parseInt(this.gridOptions.pinnedTopRowData[0][product], 10) - updated_data[0].quantity) + ")";
        this.gridApi.setPinnedTopRowData(this.gridOptions.pinnedTopRowData);
      } else {
        this.gridOptions.pinnedTopRowData[1][product] = parseInt(this.gridOptions.pinnedTopRowData[1][product].toString().split(" (")[0], 10);
        this.gridOptions.pinnedTopRowData[1][product] = this.gridOptions.pinnedTopRowData[1][product] + " (" + (parseInt(this.gridOptions.pinnedTopRowData[1][product], 10) - updated_data[0].quantity) + ")";
        this.gridApi.setPinnedTopRowData(this.gridOptions.pinnedTopRowData);
      }

    }

    if (this.status === 'Inprogress') {

      let updated_data, input_data;
      updated_data = this.stockData2.stock_data.filter(function(existed) {
        return parseInt(product.split('_')[1], 10) === existed.product_id;
      });
      if (new_prod) {
        updated_data[0].new_prod[params.order_id] = parseInt(value, 10);
      }

      let pinnedData = this.gridOptions.pinnedTopRowData;
      let options = this.gridOptions;
      var focusedCell = options.api.getFocusedCell();
      let editcellvalue;
      let editedValues = this.saveData;
      let splitValue, sum;
      if (typeof pinnedData[1][product] === 'string') {
        splitValue = pinnedData[1][product].split(" (");
        sum = splitValue[0];
      } else {
        sum = pinnedData[1][product];
      }
      this.gridApi.forEachNode(function(node) {


        let cellValue = options.api.getValue(focusedCell.column, node);

        if (node.data.dispatch_status === 'new') {

          editcellvalue = editedValues.filter(function(existed) {
            return ((parseInt(product.split('_')[1], 10) === parseInt(existed.product_id, 10)) &&
              (parseInt(node.data.order_id, 10) === parseInt(existed.order_id, 10)));
          });

          if (editcellvalue.length > 0) {
            editcellvalue = editcellvalue[0].qty;
          } else {
            if (cellValue.ordered === undefined) {
              editcellvalue = cellValue.qty;
            } else {
              editcellvalue = cellValue.ordered;
            }
          }

          sum = parseInt(sum, 10) - parseInt(editcellvalue, 10);

        }

      });
      pinnedData[1][product] = pinnedData[1][product].split(' (')[0] + ' (' + sum + ')';
      this.gridOptions.pinnedTopRowData = pinnedData;
      this.gridApi.setPinnedTopRowData(this.gridOptions.pinnedTopRowData);
    }

  }

  editProdCell(event, params) {

    if (event !== "") {
      let prod_data;

      prod_data = this.stockData1.line_data.filter(function(existed) {
        return parseInt(params.product_id, 10) === existed.product_id;
      });

      prod_data[0].qty_ordered = event;
      prod_data[0].amount = parseInt(event, 10) * prod_data[0].price;
    }

  }

  editProdCell2(event, params) {

    if (event !== "") {
      let prod_data;

      prod_data = this.saveProdData.filter(function(existed) {
        return parseInt(params.product_id, 10) === existed.product_id;
      });

      prod_data[0].quantity = event;

      let updated_data;

      updated_data = this.stockData2.stock_data.filter(function(existed) {
        return parseInt(params.product_id, 10) === existed.product_id;
      });

      updated_data[0].quantity = event;
    }


  }

  confirmProd() {

    let saveData = {};

    this.stockData1.po_id = this.user_data.po_id;

    saveData['access_token'] = this.user_data.access_token;
    saveData['url'] = this.user_data.url;
    saveData['data'] = this.stockData1;
    saveData['dispatch'] = false;

    this.res_data = this.apiService.submitProduction(saveData);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && res['results'].status === 200) {
          this.renderer.addClass(this.elRef.nativeElement.querySelector('.sendpopup'), 'hide');
          this.renderer.removeClass(this.elRef.nativeElement.querySelector('.updatepopup'), 'hide');
          this.saveProdData = [];
          this.status = 'Submitted';
          localStorage.setItem('po_status', this.status);
          this.status_class = "blue-status";
          this.res_data = this.apiService.getProduction(this.user_data);
          this.res_data.subscribe(res => {
              console.log(res);
              if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
                this.po_line_data = res['results'].data;
                this.apiProList(1);
              }
            },
            error => {
              console.log(error);
            });
        }
      },
      error => console.log(error));

  }

  confirmProd2() {

    let saveProdData = {};

    saveProdData['access_token'] = this.user_data.access_token;
    saveProdData['url'] = this.user_data.url;
    saveProdData['data'] = {
      "line_data": this.saveProdData,
      "po_id": this.user_data.po_id
    };

    let saveData = {};

    saveData['access_token'] = this.user_data.access_token;
    saveData['url'] = this.user_data.url;
    saveData['data'] = this.stockData2.stock_data;

    this.res_data = this.apiService.addDcStocks(saveData);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {

          this.res_data = this.apiService.updateProduction(saveProdData);
          this.res_data.subscribe(res => {
              console.log(res);
              if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
                this.renderer.removeClass(this.elRef.nativeElement.querySelector('.updatepopup'), 'hide');
                this.renderer.addClass(this.elRef.nativeElement.querySelector('.submitpopup'), 'hide');
                this.status = 'Processed';
                localStorage.setItem('po_status', this.status);
                this.status_class = "blue-status";
                this.loaded = false;
                this.preloader = true;
                this.sendTOproduction(3);
              }

            },
            error => console.log(error));
        }
      },
      error => console.log(error));

  }

  async save() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = "block";
    this.saveCaption = ["LOADING..", 0];
    let saveData = {};
    let savePassData = {
      "update_data": this.saveData,
      "newprod_data": this.newprodData
    };
    saveData['access_token'] = this.user_data.access_token;
    saveData['url'] = this.user_data.url;
    saveData['data'] = savePassData;


    let resp = await this.apiService.updatePrimary(saveData);
    this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
    if (resp.hasOwnProperty('results') && (resp['results'].status === 200)) {
      this.saveCaption = ["SAVED SUCCESSFULLY ;)", 0];
      await this.apiProList(0);
    } else {
      this.saveCaption = ["SAVE FAILED!", 1];
    }

    this.saveData = [];
    this.newprodData = [];

  }

  sendTOproduction2() {

    this.updateConfirm = false;
    console.log(this.elRef.nativeElement.querySelector('.updatepopup'), this.elRef.nativeElement.querySelector('.submitpopup'));
    this.renderer.addClass(this.elRef.nativeElement.querySelector('.updatepopup'), 'hide');
    this.renderer.removeClass(this.elRef.nativeElement.querySelector('.submitpopup'), 'hide');
    this.sendTOproduction(2);
  }

  async sendTOproduction(times) {

    this.productList = [];
    let list = [];
    let poList = [];
    let rem = 0;
    let req_data = [];
    let save_data = [];
    let saveProdData = [];
    let po_id = this.user_data.po_id;
    let allocated_qty = 0;
    let produced_qty = 0;
    let allocated_cash = 0;
    let produced_cash = 0;
    let prod_calc = {};
    let qty_ordered = 0;
    let qty_adjusted = 0;
    let qty_allocated = 0;
    let product_summary = this.product_summary;
    let date = this.user_data.date;
    this.pop_loader = true;
    this.preloaderPO = true;
    this.stockData1 = {
      "line_data": [],
      "po_id": null
    };
    this.stockData2 = {
      "stock_data": [],
      "order_ids": [],
      "po_id": null,
      "status": ""
    };
    let cid = JSON.parse(localStorage.getItem('user')).cid;


    if (times === 1) {
      this.sendConfirm = false;
      await this.save();
      let inside_this = this;
      // setTimeout(function () {
      inside_this.res_data = inside_this.apiService.getdcStock(inside_this.user_data);
      inside_this.res_data.subscribe(res => {
          console.log(res);
          product_summary = inside_this.product_summary;
          if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
            res['results'].data.forEach(function(data) {

              qty_ordered = product_summary['p_' + data.product_id];
              qty_adjusted = qty_ordered - data.in_stock;
              if (qty_adjusted < 0) {
                qty_adjusted = 0;
              }
              if (data.in_stock === null) {
                data.in_stock = 0;
              }

              list.push({
                "name": data.name,
                "req": qty_ordered,
                "stock": data.in_stock,
                "rem": qty_adjusted,
                "price": data.price.toFixed(inside_this.apiService.decimalPolicy),
                "product_id": data.product_id,
              });

              poList.push({
                "category": data.categ,
                "name": data.name,
                "req": qty_ordered,
                "stock": data.in_stock,
                "rem": qty_adjusted,
                "price": data.price.toFixed(inside_this.apiService.decimalPolicy),
                "product_id": data.product_id,
              });

              req_data.push({
                "price": data.price,
                "product_id": data.product_id,
                "po_id": po_id,
                "distributor_id": data.dc_id,
                "territory_id": localStorage.getItem('dc_territory_id'),
                "stock_date": date,
                "type": 's',
                "qty_ordered": qty_adjusted,
                "cid": cid,
                "amount": qty_adjusted * data.price
              });

              saveProdData.push({
                "product_id": data.product_id,
                "po_id": po_id,
                "quantity": qty_adjusted
              });

            });
            inside_this.productList = list;
            inside_this.POproductList = poList;
            inside_this.stockData1.line_data = req_data;
            inside_this.saveProdData = saveProdData;
            inside_this.prod_loaded = true;
          }
          inside_this.pop_loader = false;
          inside_this.sendConfirm = true;
        },
        error => console.log(error));
    } else if (times === 2) {

      this.res_data = this.apiService.getprodOrder(this.user_data);
      this.res_data.subscribe(res => {
          console.log(res);
          let inthis = this;
          if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
            res['results'].data.forEach(function(data) {

              qty_ordered = data.qty_ordered;
              if (data.in_stock === null) {
                data.in_stock = 0;
              }

              list.push({
                "name": data.name,
                "stock": data.in_stock,
                "rem": qty_ordered,
                "produced": qty_ordered,
                "price": data.price.toFixed(inthis.apiService.decimalPolicy),
                "product_id": data.product_id,
              });

              req_data.push({
                "price": data.price,
                "product_id": data.product_id,
                "po_id": po_id,
                "distributor_id": data.dc_id,
                "territory_id": localStorage.getItem('dc_territory_id'),
                "stock_date": date,
                "type": 'p',
                "quantity": qty_ordered,
                "cid": cid,
                "amount": qty_ordered * data.price
              });

              saveProdData.push({
                "product_id": data.product_id,
                "po_id": po_id,
                "quantity": qty_ordered
              });

              produced_cash += (data.price * data.in_stock);
              produced_qty += data.in_stock;
              prod_calc['p_' + data.product_id] = [data.in_stock, data.price];
            });
            this.productList = list;
            this.stockData1.line_data = req_data;
            this.stockData2.stock_data = req_data;
            this.saveProdData = saveProdData;
            this.prod_loaded = true;
            this.pinnedData[1]['QTY'] = produced_qty;
            this.pinnedData[1]['CASH'] = (produced_cash / 1000).toFixed(this.apiService.decimalPolicy) + 'K';
            for (var key in prod_calc) {
              this.pinnedData[0][key] = prod_calc[key][1];
              this.pinnedData[1][key] = prod_calc[key][0];
            }
          }
          this.pop_loader = false;
          this.updateConfirm = true;
        },
        error => console.log(error));
    } else if (times === 3) {
      this.res_data = this.apiService.getprodOrder(this.user_data);
      this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
            res['results'].data.forEach(function(data) {

              qty_ordered = data.qty_ordered;

              req_data.push({
                "price": data.price,
                "product_id": data.product_id,
                "po_id": po_id,
                "distributor_id": data.dc_id,
                "territory_id": localStorage.getItem('dc_territory_id'),
                "stock_date": date,
                "type": 's',
                "quantity": qty_ordered,
                "cid": cid,
                "amount": qty_ordered * data.price,
                "new_prod": {},
                "taxmaster_id": data.taxmaster_id,
                "tax_pct": data.tax_pct,
                "name": data.name,
                "product_uom": data.product_uom
              });
              prod_calc['p_' + data.product_id] = [data.in_stock, data.price];

            });
            this.stockData2.stock_data = req_data;
            for (var key in prod_calc) {
              if (this.direct_dispatch) {
                this.pinnedData2[0][key] = prod_calc[key][0];
              } else {
                this.pinnedData[0][key] = prod_calc[key][1];
                this.pinnedData[1][key] = prod_calc[key][0];
              }
            }
            this.res_data = this.apiService.getProduction(this.user_data);
            this.res_data.subscribe(res => {
                console.log(res);
                if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
                  this.po_line_data = res['results'].data;
                  this.apiProList(2);
                }
              },
              error => {
                console.log(error);
              });
          }
        },
        error => console.log(error));
    } else if (times === 4) {
      this.res_data = this.apiService.getprodOrder(this.user_data);
      this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
            res['results'].data.forEach(function(data) {
              prod_calc['p_' + data.product_id] = [data.line_id];
            });
            this.lastpinnedData[0]['QTY'] = produced_qty;
            this.lastpinnedData[0]['CASH'] = (produced_cash / 1000).toFixed(this.apiService.decimalPolicy) + 'K';
            for (var key in prod_calc) {
              this.lastpinnedData[0][key] = prod_calc[key][0];
            }
            this.apiProList(3);

          }

        },
        error => console.log(error));

    } else if (times === 5) {
      this.res_data = this.apiService.getprodOrder(this.user_data);
      this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
            res['results'].data.forEach(function(data) {

              qty_ordered = data.qty_ordered;

              req_data.push({
                "price": data.price,
                "product_id": data.product_id,
                "po_id": po_id,
                "distributor_id": data.dc_id,
                "territory_id": localStorage.getItem('dc_territory_id'),
                "stock_date": date,
                "type": 's',
                "quantity": qty_ordered,
                "cid": cid,
                "amount": qty_ordered * data.price,
                "new_prod": {},
                "taxmaster_id": data.taxmaster_id,
                "tax_pct": data.tax_pct,
                "name": data.name,
                "product_uom": data.product_uom
                // "line_id": data.line_id,
                // "product_uom": data.product_uom
              });

              produced_cash += (data.price * data.in_stock);
              produced_qty += data.in_stock;
              prod_calc['p_' + data.product_id] = [data.in_stock, data.price];

            });
            this.stockData2.stock_data = req_data;
            this.pinnedData[1]['QTY'] = produced_qty;
            this.pinnedData[1]['CASH'] = (produced_cash / 1000).toFixed(this.apiService.decimalPolicy) + 'K';
            for (var key in prod_calc) {
              this.pinnedData[0][key] = prod_calc[key][1];
              this.pinnedData[1][key] = prod_calc[key][0];
            }
            this.res_data = this.apiService.getProduction(this.user_data);
            this.res_data.subscribe(res => {
                console.log(res);
                if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
                  this.po_line_data = res['results'].data;
                  this.apiProList(4);
                }
              },
              error => {
                console.log(error);
              });
          }
        },
        error => console.log(error));
    }

  }

  ngOnInit() {

    this.passedParams = JSON.parse(localStorage.getItem('params'));
    this.passedParams.not_placed = parseInt(this.passedParams['TOTAL VANS'], 10) - parseInt(this.passedParams['VANS SETTLED'], 10);
    this.passedParams.perc = ((parseInt(this.passedParams['VANS SETTLED'], 10) / parseInt(this.passedParams.not_placed, 10)) * 100).toFixed(this.apiService.decimalPolicy);
    if (this.passedParams.perc === 'Infinity') {
      this.passedParams.perc = '0';
    }
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');

    this.columnDefsPO = [
      {headerName: "CATEGORY", headerClass: 'myagheader', field: "category", width : 100},
      {headerName: "PRODUCT", headerClass: 'myagheader', field: "name", menuTabs: [], width : 230},
      {headerName: "PRICE", headerClass: 'myagheader', field: "price", menuTabs: [], width : 70, cellStyle: {textAlign: 'right'}},
      {headerName: "REQUESTED", headerClass: 'myagheader', field: "req", menuTabs: [], width : 90, cellStyle: {textAlign: 'right'}},
      {headerName: "SUGGESTED", headerClass: 'myagheader', field: "type", menuTabs: [], width : 90, cellStyle: {textAlign: 'right'}},
      {headerName: "IN STOCK", headerClass: 'myagheader', field: "stock", menuTabs: [], width : 80, cellStyle: {textAlign: 'right'}},
      {headerName: "DAMAGED", headerClass: 'myagheader', field: "type", menuTabs: [], width : 80, cellStyle: {textAlign: 'right'}},
      {headerName: "PRODUCED", headerClass: 'myagheader', field: "type", menuTabs: [], width : 80, cellStyle: {textAlign: 'right'}},
      {headerName: "TOTAL", headerClass: 'myagheader', field: "type", menuTabs: [], width : 80, cellStyle: {textAlign: 'right'}},
    ];
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {
    this.apiloaded = false;
    this.user_data.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');

    this.res_data = this.apiService.getPOStatus(this.user_data);

    this.res_data.subscribe(res => {

        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          this.status = res['results'].data[0].status[0].toUpperCase() + res['results'].data[0].status.slice(1);
          localStorage.setItem('po_status', this.status);
          this.user_data.po_id = res['results'].data[0].id;
          // localStorage.setItem('user_data', JSON.stringify(this.user_data));

          if (this.status === 'Draft') {
            this.status_class = 'green-status';
          } else if (this.status === 'Completed' || this.status === 'Inprogress') {
            this.status_class = 'grey-status';
          } else {
            this.status_class = 'blue-status';
          }

          if (this.status === 'Processed') {
            this.loaded = false;
            this.preloader = true;
            this.res_data = this.apiService.getProduction(this.user_data);
            this.res_data.subscribe(res => {
                console.log(res);
                if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
                  this.po_line_data = res['results'].data;
                }
              },
              error => {
                console.log(error);
              });
            this.sendTOproduction(3);
          } else if (this.status === 'Completed') {
            this.loaded = false;
            this.preloader = true;
            this.sendTOproduction(4);
          } else if (this.status === 'Inprogress') {
            this.loaded = false;
            this.preloader = true;
            this.sendTOproduction(5);
          } else if (this.status === 'Submitted') {
            this.res_data = this.apiService.getProduction(this.user_data);
            this.res_data.subscribe(res => {
                console.log(res);
                if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
                  this.po_line_data = res['results'].data;
                  this.apiProList(1);
                }
              },
              error => {
                console.log(error);
              });
          } else {
            this.apiProList(0);
          }
          this.apiloaded = true;
        } else {
          this.status = 'Not Started';
          this.loaded = false;
          this.empty_state = true;
        }

      },
      error => {
        this.apiloaded = true;
        console.log(error);
      });

  }

  directDispatchCall() {

    let qty_ordered = 0;
    let qty_adjusted = 0;
    let product_summary = this.product_summary;
    let po_id = this.user_data.po_id;
    let date = this.user_data.date;
    let cid = JSON.parse(localStorage.getItem('user')).cid;
    this.user_data.dispatch = this.multiple_loadout;

    this.res_data = this.apiService.getdcStock(this.user_data);
    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
        let req_data = [];
        res['results'].data.forEach(function(data) {
          qty_ordered = product_summary['p_' + data.product_id];

          req_data.push({
            "price": data.price,
            "product_id": data.product_id,
            "po_id": po_id,
            "distributor_id": data.dc_id,
            "territory_id": localStorage.getItem('dc_territory_id'),
            "stock_date": date,
            "type": 's',
            "qty_ordered": qty_ordered,
            "cid": cid,
            "amount": qty_ordered * data.price
          });
        });
        this.stockData1.line_data = req_data;
        let saveData = {};

        this.stockData1.po_id = this.user_data.po_id;

        saveData['access_token'] = this.user_data.access_token;
        saveData['url'] = this.user_data.url;
        saveData['data'] = this.stockData1;
        saveData['dispatch'] = true;

        this.res_data = this.apiService.submitProduction(saveData);
        this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
            this.status = 'Processed';
            localStorage.setItem('po_status', this.status);
            this.status_class = "blue-status";
            this.loaded = false;
            this.preloader = true;
            this.sendTOproduction(3);
          }
        }, error => {
          console.log(error);
        });
      }
    }, error => {
      console.log(error);
    });

  }

  dispatchCall() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = "block";
    this.status = 'Processing';
    this.loaded = false;
    this.preloader = true;
    let saveData = {};
    let rows = this.gridApi.getSelectedRows();
    let nodes = this.gridApi.getSelectedNodes();
    let save_van_data = this.saveData;
    let stockData2 = this.stockData2.stock_data;
    let newstockData2 = {
      "stock_data": [],
      "order_ids": [],
      "po_id": null,
      "mark_delivery": true,
      "primary_data": null
    };

    let flag = true;
    let gridoptions = this.gridOptions.pinnedTopRowData;
    let product_sum = {};
    let primaryData;
    let van_wise_data = [];
    let date = this.user_data.date;
    let products = this.products;
    let all_products = this.all_products;
    let apiServce = this.apiService;
    let res_data;
    let status = this.status;
    let saveCaption = this.saveCaption;
    let status_class = this.status_class;
    let loaded = this.loaded;
    let preloader = this.preloader;
    let sendToProduction = this.sendTOproduction;
    let all_data = [];
    let negative_products = [];

    saveData['access_token'] = this.user_data.access_token;
    saveData['url'] = this.user_data.url;

    if (rows.length !== 0) {
      rows.forEach(van_row => {

        let selected_van;

        newstockData2 = {
          "stock_data": [],
          "order_ids": [],
          "po_id": this.user_data.po_id,
          "mark_delivery": true,
          "primary_data": null
        };


        if (van_row.order_id !== null) {
          newstockData2.order_ids.push(van_row.order_id);
        }

        selected_van = save_van_data.filter(function(existed) {
          return parseInt(van_row.order_id, 10) === existed.order_id;
        });


        selected_van.forEach(function(selected_product) {

          if (product_sum['p_' + selected_product.product_id] === undefined) {
            product_sum['p_' + selected_product.product_id] = 0;
          }

          product_sum['p_' + selected_product.product_id] += parseInt(selected_product.qty, 10);

          let stock_data = stockData2.filter(function(existed) {
            return parseInt(selected_product.product_id, 10) === existed.product_id;
          });

          newstockData2.stock_data.push({
            "product_id": stock_data[0].product_id,
            "po_id": stock_data[0].po_id,
            "distributor_id": stock_data[0].distributor_id,
            "territory_id": stock_data[0].territory_id,
            "stock_date": stock_data[0].stock_date,
            "type": 's',
            "quantity": selected_product.qty,
            "cid": stock_data[0].cid,
            "price": all_products['p_' + stock_data[0].product_id].price,
            "taxmaster_id": all_products['p_' + stock_data[0].product_id].taxmaster_id,
            "tax_pct": all_products['p_' + stock_data[0].product_id].tax_pct,
            "name": stock_data[0].name,
            "product_uom": stock_data[0].product_uom,
            "van_id": van_row.van_id,
            "vansale_id": van_row.vansale_id,
            "user_id": van_row.user_id,
            "user_territory_id": van_row.user_territory_id,
            "order_id": van_row.order_id,
            "new_prod": stock_data[0].new_prod[van_row.order_id],
            "line_id": selected_product.line_id
          });

        });

        newstockData2.stock_data.forEach(function(data) {
          data.quantity = 0 - parseInt(data.quantity, 10);
        });

        let temp = [];

        newstockData2.order_ids.forEach(function(order_id) {
          let updated_orders = save_van_data.filter(function(existed) {
            return parseInt(order_id, 10) === existed.order_id;
          });

          temp = temp.concat(updated_orders);
        });

        primaryData = temp;

        newstockData2.primary_data = {
          'update_data': primaryData,
          'newprod_data': []
        };

        all_data.push(newstockData2);

      });


      if (this.direct_dispatch) {
        this.products.forEach(function(product) {
          if (parseInt(gridoptions[0][product].split(' ')[0], 10) < product_sum[product]) {
            negative_products.push(all_products[product].name);
            flag = false;
          }
        });
      } else {
        this.products.forEach(function(product) {
          if (parseInt(gridoptions[1][product].split(' ')[0], 10) < product_sum[product]) {
            negative_products.push(all_products[product].name);
            flag = false;
          }
        });
      }


      if (flag) {
        saveData['dispatch'] = this.multiple_loadout;

        if (rows.length === 1) {
          saveData['data'] = newstockData2;
          this.res_data = this.apiService.markDelivery(saveData);
        } else {
          saveData['data'] = all_data;
          this.res_data = this.apiService.markDeliveryAll(saveData);
        }

        this.res_data.subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results') && res['results'].status === 200) {
              this.toastr.success('Dispatched Successfully');
              this.saveCaption = ["Dispatched Successfully B)", 0];
              this.status_class = 'grey-status';
              this.status = 'Inprogress';
              localStorage.setItem('po_status', this.status);
              this.sendTOproduction(5);
            } else {
              if (res.hasOwnProperty('results') && res['results'].status === 403 && res['results'].msg === 'Already dispatched') {
                this.status = localStorage.getItem('po_status')[0].toUpperCase() + localStorage.getItem('po_status').slice(1);
                this.saveCaption = ["Dispatch Failed!!!", 1];
                this.loaded = true;
                this.preloader = false;
                this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
              } else {
                this.status = localStorage.getItem('po_status')[0].toUpperCase() + localStorage.getItem('po_status').slice(1);
                this.saveCaption = ["Dispatch Failed!!!", 1];
                this.loaded = false;
                this.preloader = true;
                if (this.status === 'Processed') {
                  this.sendTOproduction(3);
                } else if (this.status === 'Inprogress') {
                  this.sendTOproduction(5);
                }
              }

            }
          },
          error => {
            console.log(error);
            this.status = localStorage.getItem('po_status')[0].toUpperCase() + localStorage.getItem('po_status').slice(1);
            this.saveCaption = ["Dispatch Failed!!!", 1];
            this.loaded = false;
            this.preloader = true;
            if (this.status === 'Processed') {
              this.sendTOproduction(3);
            } else if (this.status === 'Inprogress') {
              this.sendTOproduction(5);
            }
          });
      } else {
        this.status = localStorage.getItem('po_status')[0].toUpperCase() + localStorage.getItem('po_status').slice(1);
        this.saveCaption = ["Dispatch Failed!!!", 1];
        window.alert('Available stock is less.. please verify for following products ' + negative_products);
        negative_products = [];
        this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
        this.loaded = true;
        this.preloader = false;
      }
    } else {
      this.status = localStorage.getItem('po_status')[0].toUpperCase() + localStorage.getItem('po_status').slice(1);
      this.saveCaption = ["Dispatch Failed!!!", 1];
      window.alert('Please select atleast one order');
      this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
      this.loaded = true;
      this.preloader = false;
    }
  }
}



