import {Component, OnInit} from "@angular/core";
import {GetRowIdFunc, GetRowIdParams, GridOptions} from "@ag-grid-enterprise/all-modules";
import {DetailPageBase} from "../../../pagebase/DetailPageBase";
import {ErrorHandler} from "../../../error/error-handler";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {MatDialogConfig} from "@angular/material/dialog";
import {ApproveRejectCommentsPopupComponent} from "../../../components/approve_reject_comments_popup/approve_reject_comments_popup.component";

@Component({
  selector: 'app-claim-detail',
  templateUrl: 'claim_detail.html',
  styleUrls: ['../../../pagebase/pagebase_detail.scss']
})

export class ClaimDetailComponent extends DetailPageBase implements IDetailPage, OnInit {

  show_approve_button = false;
  feature_claim_approve = false;
  totalQuantity = 0;
  totalClaimAmount = 0;
  totalInvoices = 0;
  totalOutlets = 0;
  activetab = 'Summary';

  summary_gridContext: any;
  summary_columnDefs = [];
  summary_gridOptions: GridOptions;
  summary_gridApi: any;
  summary_gridColumnApi: any;
  summary_rowData = [];
  summary_gridStyle: any;

  sale_gridContext: any;
  sale_columnDefs = [];
  sale_gridOptions: GridOptions;
  sale_gridApi: any;
  sale_gridColumnApi: any;
  sale_rowData = [];
  sale_gridStyle: any;

  return_gridContext: any;
  return_columnDefs = [];
  return_gridOptions: GridOptions;
  return_gridApi: any;
  return_gridColumnApi: any;
  return_rowData = [];
  return_gridStyle: any;

  expense_gridContext: any;
  expense_columnDefs = [];
  expense_gridOptions: GridOptions;
  expense_gridApi: any;
  expense_gridColumnApi: any;
  expense_rowData = [];
  expense_gridStyle: any;
  approverConfig: any;
  showApproverDetails = false;
  approverDetails = [];
  claimStatus = '';
  user_role_id;
  user_role_name;
  finalApprovalStage = false;

  ngOnInit(): void {
    // this.setTitle('Return Invoice');
    this._Detail_GET_URL = "/api/pwa_dms_connects/get_claim_detail";
    this.context = {componentParent: this};
    this.sharedService.setHeader('Claim');
    const company_config = JSON.parse(localStorage.getItem('company_config'));
    if (company_config && company_config.length > 0) {
      this.approverConfig = company_config.find(x => x.type === 'Claim Approval');
      if (this.approverConfig && this.approverConfig?.config_json?.config && this.approverConfig.config_json.config.length > 0) {
        this.approverConfig = this.approverConfig?.config_json.config[0].appr_roles;
      }
    }
    this.feature_claim_approve = this.sharedService.ACLcheck('feature_claim_approve');
    this.user_role_id = JSON.parse(localStorage.getItem('user')).role_id;
    this.user_role_name = JSON.parse(localStorage.getItem('user')).role.name;
    this.summary_gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressRowTransform: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        } else {
          if (params.data.a % 2 === 0) {
            return {background: '#F3F6F8'};
          } else {
            return {background: 'white'};
          }
        }
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;

    this.sale_gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
    } as GridOptions;

    this.return_gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
    } as GridOptions;

    this.configureGrid();
    this.readPageParms(params => {
      this.loadPage();
    });
  }

  configureGrid() {

    this.columnDefs = [
      {headerName: 'ID', field: 'id', hide: true},
      {headerName: 'INVOICE NO', field: 'invoice_no', width: 50},
      {headerName: 'PRODUCT', field: 'name', width: 120},
      {headerName: 'CUSTOMER CODE', field: 'c_code', width: 50},
      {headerName: 'CUSTOMER NAME', field: 'c_name', width: 50},
      {headerName: 'QTY', field: 'quantity', width: 40},
      {headerName: 'UOM', field: 'uom_name', width: 40},
      {headerName: 'PRICE', field: 'price_unit', width: 60, valueFormatter: params => this.formatNumber(params.data.price_unit)},
      {headerName: 'DISC%', field: 'discount', width: 50, valueFormatter: params => this.formatNumber(params.data.price_unit)},
      {
        headerName: 'DISC Value', field: 'disc_value', width: 50,
        valueGetter(params) {
          if (params.data.discount) {
            const base_total = parseInt(params.data['quantity'], 10) * parseFloat(params.data['price_unit']);
            return (base_total * parseFloat(params.data['discount']) / 100);
          } else {
            return params.data.discount;
          }
        }
      },
      {headerName: 'SCHEME', field: 'scheme_code', width: 80},
      {headerName: 'SCHEME DIS', field: 'scheme_discount', width: 50, valueFormatter: params => this.formatNumber(params.data.scheme_discount)},
      {headerName: 'SUB TOTAL', field: 'amount', width: 50, valueFormatter: params => this.formatNumber(params.data.amount)},
      {headerName: 'Serial NO', hide: true, width: 100},
      {headerName: '', field: 'delete', lockPosition: true, cellRenderer: 'cellDeleteRenderer', width: 40},
    ];

    this.summary_columnDefs = [
      {
        headerName: "Channel", field: "channel", width: 100,
        /*  rowSpan: params => params.data.rowspan ? params.data.rowspan : 1,
         cellClassRules: { "show-cell": "value !== undefined"}*/
      },
      {headerName: "Category", field: "product_categ", width: 100},
      {
        headerName: "Claim %", field: "claim_pct", width: 40, type: 'rightAligned',
        valueGetter(params) {
          if (params.data && params.data.claim_pct) {
            return parseFloat(params.data.claim_pct).toFixed(2);
          }
        }
      },
      {
        headerName: "Sales", field: "sales", width: 40, aggFunc: 'sum', type: 'rightAligned',
        valueGetter(params) {
          if (params.data && params.data.sales) {
            return parseFloat(params.data.sales).toFixed(2);
          }
        }
      },
      {
        headerName: "Claim Amount", field: "claim", width: 40, aggFunc: 'sum', type: 'rightAligned',
        valueGetter(params) {
          if (params.data && params.data.claim) {
            return parseFloat(params.data.claim).toFixed(2);
          }
        }
      },
      {
        headerName: "Scheme", field: "scheme_discount", width: 40, aggFunc: 'sum', type: 'rightAligned',
        valueGetter(params) {
          if (params.data && params.data.scheme_discount) {
            return parseFloat(params.data.scheme_discount).toFixed(2);
          }
        }
      },
      {
        headerName: "Margin", field: "margin", width: 40, aggFunc: 'sum', type: 'rightAligned',
        valueGetter(params) {
          if (params.data && params.data.margin) {
            return parseFloat(params.data.margin).toFixed(2);
          }
        }
      },
      {
        headerName: "Return", field: "return_amt", width: 40, aggFunc: 'sum', type: 'rightAligned',
        valueGetter(params) {
          if (params.data && params.data.return_amt) {
            return parseFloat(params.data.return_amt).toFixed(2);
          }
        }
      },
      /*{
        headerName: "Expense", field: "expense", width: 40, aggFunc: 'sum', type: 'rightAligned',
        valueGetter(params) {
          if (params.data && params.data.expense) {
            return parseFloat(params.data.expense).toFixed(2);
          }
        }
      },*/
    ];

    this.sale_columnDefs = [
      {
        headerName: "ID", field: "id", width: 20,
        cellRenderer(params) {
          if (params.data) {
            if (params.data.invoice_no === 'TOTAL') {
              const displayElem = '<span style="font-weight: bold">' + params.value + '</span>';
              return displayElem;
            } else {
              return params.value;
            }
          }
        }
      },
      {headerName: "Active", field: "active", width: 30, hide: true},
      {headerName: "Invoice NO", field: "invoice_no", width: 40},
      {headerName: "Date", field: "date_invoice", width: 40},
      {headerName: "Code", field: "code", width: 40},
      {headerName: "Name", field: "name", width: 100},
      {headerName: "Product Code", field: "default_code", width: 40},
      {headerName: "Product Name", field: "product_name", width: 100},
      {headerName: "Scheme ID", field: "scheme_id", width: 30, hide: true},
      {headerName: "Scheme", field: "scheme", width: 120},
      {headerName: "Scheme Start", field: "scheme_start", width: 40, hide: true},
      {headerName: "Scheme End", field: "scheme_end", width: 40, hide: true},
      {headerName: "QTY", field: "quantity", width: 40, aggFunc: 'sum', type: 'rightAligned'},
      {headerName: "Margin", field: "margin", width: 40, aggFunc: 'sum', type: 'rightAligned'},
      {headerName: "Scheme Discount", field: "discount", width: 40, aggFunc: 'sum', type: 'rightAligned'},
      {headerName: "Tot Discount", field: "discount", width: 40, aggFunc: 'sum', type: 'rightAligned'},
      {headerName: "Line Total", field: "price_total", width: 40, aggFunc: 'sum', type: 'rightAligned'},
      {
        headerName: "Type", field: "claim_type", width: 40,
        cellStyle(params) {
          if (params.value) {
            if (params.value === 'Scheme') {
              return {color: '#17A2B8', 'font-weight': 600};
            } else if (params.value === 'Discount') {
              return {color: '#F6A82C', 'font-weight': 600};
            } else {
              return {color: '#757BC8', 'font-weight': 600};
            }
          }
        },
      },
      /* {
         headerName: "", field: "delete", cellStyle: {textAlign: 'right'}, width: 50, show: this.edit,
         cellRenderer: params => {
           if (params.data) {
             if (params.node && params.node.rowPinned === 'bottom') {
               return '';
             } else if (params._self.edit) {
               if (params.data.active !== false) {
                 return ' <span title="Delete" style="color: #E24260; font-size: 12px; font-weight: bold">Delete</span>';
               } else {
                 return ' <span title="Delete" style="color: #0762AD; font-size: 12px; font-weight: bold">Undo</span>';
               }

             } else {
               return "";
             }
           }
         }, cellRendererParams: {
           _self: this
         }

       },*/
    ];

    this.return_columnDefs = [
      {
        headerName: "ID", field: "id", width: 20,
        cellRenderer(params) {
          if (params.data) {
            if (params.data.invoice_no === 'TOTAL') {
              const displayElem = '<span style="font-weight: bold">' + params.value + '</span>';
              return displayElem;
            } else {
              return params.value;
            }
          }
        }
      },
      {headerName: "Active", field: "active", width: 30, hide: true},
      {headerName: "Invoice NO", field: "invoice_no", width: 40},
      {headerName: "Date", field: "date_invoice", width: 40},
      {headerName: "Beat", field: "beat", width: 40},
      {headerName: "Supp Code", field: "supp_code", width: 40},
      {headerName: "Supp Name", field: "supp_name", width: 100},
      {headerName: "Cust Code", field: "code", width: 40},
      {headerName: "Cust Name", field: "name", width: 100},
      {headerName: "Cust City", field: "city", width: 100},
      {headerName: "Cust PIN", field: "zip", width: 100},
      {headerName: "Product Code", field: "default_code", width: 40},
      {headerName: "Product Name", field: "product_name", width: 100},
      {headerName: "Product Category", field: "product_categ", width: 40},
      {headerName: "Qty", field: "quantity", width: 40, aggFunc: 'sum', type: 'rightAligned'},
      {headerName: "Line Total", field: "price_total", width: 40, aggFunc: 'sum', type: 'rightAligned'},
      /* {
         headerName: "", field: "delete", cellStyle: {textAlign: 'right'}, width: 50, show: this.edit,
         cellRenderer: params => {
           if (params.data) {
             if (params.node && params.node.rowPinned === 'bottom') {
               return '';
             } else if (params._self.edit) {
               if (params.data.active !== false) {
                 return ' <span title="Delete" style="color: #E24260; font-size: 12px; font-weight: bold">Delete</span>';
               } else {
                 return ' <span title="Delete" style="color: #0762AD; font-size: 12px; font-weight: bold">Undo</span>';
               }

             } else {
               return "";
             }
           }
         }, cellRendererParams: {
           _self: this
         }

       }*/
    ];

  }

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.id;
  };

  changeActiveTab(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    this.activetab = tab;
  }

  loadPage() {
    this.dataLoaded = false;
    this.totalQuantity = 0;
    this.totalOutlets = 0;
    this.totalInvoices = 0;
    this.totalClaimAmount = 0;
    this.summary_rowData = [];
    this.sale_rowData = [];
    this.return_rowData = [];
    if (this.activeID > 0) {
      this.newMode = false;
      this.editMode = false;
      //   View Mode
      //   Page Can be ready only after detail Object is fetched
      this.loadPageForView(() => {
        this.pageReady = true;
      });
    } else {
      this.newMode = true;
      this.editMode = true;
      //   Page Can be ready only after detail Object is fetched
      //    Throw Error
    }
  }

  loadPageForView(callback) {

    function prepareLineData(lineItems) {
      const rowData = lineItems;
      return rowData;
    }

    this.loadActiveObject(() => {

      if (this.activeObject['supplier_id'] === undefined) {
        this.toastr.error('invalid state: supplier_id not found');
      }

      /*if (this.activeObject && this.activeObject['lines'] && this.activeObject.lines.length > 0) {
        this.rowData = this.activeObject.lines;
        this.gridOptions.api.setRowData(this.rowData);
      } else if (this.activeObject && this.activeObject['invoices'] && this.activeObject.invoices.length > 0) {
        this.rowData = this.activeObject.invoices;
        this.gridOptions.api.setRowData(this.rowData);
      }*/

      if (this.activeObject && this.activeObject['lines'] && this.activeObject.lines.length > 0) {
        this.sale_rowData = this.activeObject.lines.filter(x => x.type === 'out_invoice');
        this.return_rowData = this.activeObject.lines.filter(x => x.type === 'out_refund');
        this.constructSummaryData();
        this.approveCheck();
      }
    });

  }

  action_edit() {

  }

  action_confirm_claim() {

    this.userContext['claim_ids'] = [this.activeID];
    this.apiService.post('/api/pwa_dms_connects/confirm_claims', this.userContext)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && res.results.status === 200) {
            if (res.results.hasOwnProperty('data') && res.results.data.length === 1) {
              this.activeObject['status'] = res.results.data[0]['status'];
              // navigate to invoice.

              this.toastr.success('Record Saved! : ' + this.activeID);
            } else {
              this.toastr.success('Record Saved!');
            }
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        error => {
          this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
        });

  }

  action_cancel() {

  }

  action_approve_claim() {

    this.userContext['claim_id'] = this.activeID;
    this.apiService.post('/api/pwa_dms_connects/approve_claim', this.userContext)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && res.results.status === 200) {
            if (res.results.hasOwnProperty('data') && res.results.data.length === 1) {
              this.activeObject['status'] = res.results.data[0]['status'];
              // navigate to invoice.

              this.toastr.success('Record Saved! : ' + this.activeID);
            } else {
              this.toastr.success('Record Saved!');
            }
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        error => {
          this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
        });
  }

  ConfirmOrders(order_ids, callback) {

  }

  discard() {
    this.editMode = false;
    if (this.activeObject && (!this.activeObject.hasOwnProperty('id') || this.activeObject.id === 0)) {
      history.back();
    } else {
      this.loadPage();
    }
  }

  quickSearch() {
    if (this.activetab === 'Sale') {
      this.sale_gridApi.setQuickFilter(document.getElementById('filter-text-box1')['value']);
    } else if (this.activetab === 'Return') {
      this.return_gridApi.setQuickFilter(document.getElementById('filter-text-box1')['value']);
    } else {
      this.expense_gridApi.setQuickFilter(document.getElementById('filter-text-box1')['value']);
    }
  }

  summaryOnGridReady(params) {
    this.summary_gridOptions.api.showLoadingOverlay();

    this.summary_gridApi = params.api;
    this.summary_gridColumnApi = params.columnApi;
    params.api.setRowData(this.summary_rowData);
    //this.preloader1 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });

    this.summary_gridApi.sizeColumnsToFit();

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(this.summary_gridApi, this.summary_gridColumnApi);
      this.summary_gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  saleOnGridReady(params) {
    this.sale_gridOptions.api.showLoadingOverlay();

    this.sale_gridApi = params.api;
    this.sale_gridColumnApi = params.columnApi;
    params.api.setRowData(this.sale_rowData);
    //this.preloader1 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.columnApi.autoSizeAllColumns();
      });
    });

    this.sale_gridColumnApi.autoSizeAllColumns();

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(params.api, params.columnApi);
      params.api.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  returnOnGridReady(params) {
    this.return_gridOptions.api.showLoadingOverlay();

    this.return_gridApi = params.api;
    this.return_gridColumnApi = params.columnApi;
    params.api.setRowData(this.return_rowData);
    //this.preloader1 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.columnApi.autoSizeAllColumns();
      });
    });

    this.return_gridColumnApi.autoSizeAllColumns();

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(params.api, params.columnApi);
      params.api.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  expenseOnGridReady(params) {

  }

  saleOnCellClicked(event) {
  }

  returnOnCellClicked(event) {
  }

  approveCheck() {
    if (this.activeObject.config_json) {
      this.approverDetails = this.activeObject.config_json.app_dtls;
      if (this.approverDetails && this.approverDetails.length > 0) {
        this.approverDetails.forEach(details => {
          details.app_on = this.datePipe.transform((new Date( details.app_on + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
        });
      }
    } else {
      this.approverDetails = [];
    }
    if (this.approverConfig && this.approverConfig.length > 0) {
      let completed = true;
      let rejected = false;
      if (this.approverDetails && this.approverDetails.length > 0) {
        for (let i = 0; i < this.approverConfig.length; i++) {
          const approved = this.approverDetails.find(x => x.role_id === this.approverConfig[i].role_id);
          if (!approved) {
            completed = false;
            if (this.feature_claim_approve && this.user_role_id ===  this.approverConfig[i].role_id) {
              this.show_approve_button = true;
            } else {
              this.show_approve_button = false;
            }
            this.claimStatus = 'Pending Approval from ' + this.approverConfig[i].role_name;
            if (i === this.approverConfig.length - 1) {
              this.finalApprovalStage  = true;
            } else {
              this.finalApprovalStage = false;
            }
            break;
          } else {
            if (approved.status === 'rejected') {
              rejected = true;
              break;
            }
          }
        }
        if (rejected) {
          this.claimStatus = 'rejected';
        } else if (completed) {
          this.claimStatus = 'approved';
        }
      } else {
        if (this.feature_claim_approve && this.user_role_id ===  this.approverConfig[0].role_id) {
          this.show_approve_button = true;
        } else {
          this.show_approve_button = false;
        }
        this.claimStatus = 'Pending Approval from ' + this.approverConfig[0].role_name;
        if (this.approverConfig.length === 1) {
          this.finalApprovalStage  = true;
        } else {
          this.finalApprovalStage = false;
        }
      }
    } else {
      this.claimStatus = 'pending';
    }
  }

  constructSummaryData() {
    const inThis = this;
    this.summary_rowData = [];
    this.totalInvoices = 0;
    this.totalClaimAmount = 0;
    this.totalQuantity = 0;
    this.totalOutlets = 0;
    const summaryMaster = [];
    if (this.sale_rowData && this.sale_rowData.length > 0) {
      this.sale_rowData.forEach(sale => {
        if (sale.active === false) {

        } else {
          sale.return_amt = 0;
          summaryMaster.push(sale);
        }

      });

    }
    if (this.return_rowData && this.return_rowData.length > 0) {
      this.return_rowData.forEach(returns => {
        if (returns.active === false) {

        } else {
          returns.margin = 0;
          returns.discount = 0;
          returns.scheme_discount = 0;
          returns.return_amt = returns.price_total;
          summaryMaster.push(returns);
        }
      });
    }
    console.log(summaryMaster);
    /*if (summaryMaster && summaryMaster.length > 0) {
      const unique_channels = [...new Set(summaryMaster.map(item => item.channel))];
      console.log(unique_channels);
      if (unique_channels && unique_channels.length > 0) {
        unique_channels.forEach(channel => {
          const channelrows = summaryMaster.filter(x => x.channel === channel);
          const channel_categories = this.sharedService.unique(channelrows, ['category']);
          const channel_categ_master = {
            name: channel,
            categories: []
          };
          channel_categories.forEach(categoryRow => {
            channel_categ_master.categories.push({name: categoryRow['product_categ']});
            const indrow: any = {};
            if (categoryRow['claim_type'] === )

          });
        });
      }
    }*/
    this.totalInvoices = [...new Set(summaryMaster.map(item => item.invoice_no))].length;
    this.totalOutlets = [...new Set(summaryMaster.map(item => item.code))].length;
    const result = [...summaryMaster.reduce((r, o) => {
      const key = o.channel + '-' + o.product_categ;

      const item = r.get(key) || Object.assign({}, o, {
        price_total: 0,
        discount: 0,
        scheme_discount: 0,
        margin: 0,
        return_amt: 0,
        quantity: 0
      });
      item.price_total += o.price_total;
      item.margin += o.margin;
      item.discount += o.discount;
      item.scheme_discount += o.scheme_discount;
      item.return_amt += o.return_amt;
      item.quantity += parseInt(o.quantity, 10);

      return r.set(key, item);
    }, new Map).values()];

    console.log(result);

    if (result && result.length > 0) {
      const unique_channels = [...new Set(result.map(item => item.channel))];
      if (unique_channels && unique_channels.length > 0) {
        let a = 0;
        unique_channels.forEach(channel => {
          const channelrows = result.filter(x => x.channel === channel);
          const tot = channelrows[0].discount + channelrows[0].return_amt;
          inThis.totalClaimAmount += tot;
          inThis.totalQuantity += channelrows[0].quantity;
          inThis.summary_rowData.push({
            a,
            channel: channelrows[0].channel,
            product_categ: channelrows[0].product_categ,
            rowspan: channelrows.length,
            sales: channelrows[0].price_total,
            claim_pct: ((tot) / (channelrows[0].price_total)) * 100,
            claim: tot,
            scheme_discount: channelrows[0].scheme_discount,
            margin: channelrows[0].margin,
            discount: channelrows[0].discount,
            return_amt: channelrows[0].return_amt
          });
          for (let i = 1; i < channelrows.length; i++) {
            const tot1 = channelrows[i].discount + channelrows[i].return_amt;
            inThis.totalClaimAmount += tot1;
            inThis.totalQuantity += channelrows[i].quantity;
            inThis.summary_rowData.push({
              a,
              product_categ: channelrows[i].product_categ,
              sales: channelrows[i].price_total,
              claim_pct: ((tot1) / (channelrows[i].price_total)) * 100,
              claim: tot1,
              scheme_discount: channelrows[i].scheme_discount,
              margin: channelrows[i].margin,
              discount: channelrows[i].discount,
              return_amt: channelrows[i].return_amt
            });
          }
          a++;
        });
      }
    }
    console.log(this.summary_rowData);
    this.dataLoaded = true;
  }

  detailsClick() {
    this.showApproverDetails = !this.showApproverDetails;
  }

  openApproveRejectPopup(type) {
    let title = '';
    let message1 = '';
    let message2 = '';
    let buttonText = '';

    if (type === 'reject') {
      title = "Reject Claim";
      message1 = "Are you sure you want to Reject Claim?";
      message2 = "Please provide a detailed reason for the rejection";
      buttonText = "Reject";
    } else {
      title = "Approve Claim";
      message1 = "Are you sure you want to Approve Claim?";
      message2 = "Please provide Approval Comments";
      buttonText = "Approve";
    }

    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: 'auto',
        maxWidth: "50%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          title,
          message1,
          message2,
          type,
          buttonText
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        if (type === 'reject') {
          this.approveRejectClaim('reject', data);
        } else {
          this.approveRejectClaim('approve', data);
        }
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: 'auto',
        maxWidth: "95%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          title,
          message1,
          message2,
          type,
          buttonText
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        if (type === 'reject') {
          this.approveRejectClaim('reject', data);
        } else {
          this.approveRejectClaim('approve', data);
        }
      });
    }
  }

  approveRejectClaim(state, comments) {
    let status = '';
    if (state === 'reject') {
      status = 'rejected';
    } else {
      status = 'approved';
    }
    const config_object = {
      app_id: JSON.parse(localStorage.getItem('user')).id,
      app_by: JSON.parse(localStorage.getItem('user')).user_name,
      role_id: this.user_role_id,
      role_name: this.user_role_name,
      status,
      comments
    };
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      claim_id: this.activeID,
      final_approve: this.finalApprovalStage,
      config_object
    };
    this.apiService.post('/api/pwa_dms_connects/approve_claim', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success(state.toUpperCase() + " Successfully");
          this.loadPage();
        } else {
          this.toastr.error("Failed to " + state.toString().toUpperCase() + " Claim");
        }
      });
  }
}
