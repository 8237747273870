import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {DeleteComponent} from "../../../deleteCell.component";
import {NgSelectComponent} from "@ng-select/ng-select";
import {CellEditingStartedEvent, CellEditingStoppedEvent, GetRowIdFunc, GetRowIdParams} from "@ag-grid-enterprise/all-modules";
import {NumericEditorComponent} from "../../../pagebase/components/NumericEditorComponent";
import {ErrorHandler} from "../../../error/error-handler";
import {DMSBasePage} from "../../../pagebase/components/DMSBasePage";
import {MatDialogConfig} from "@angular/material/dialog";
import {DeviationOrderPopupComponent} from "../../../components/deviation_order_popup/deviation_order_popup.component";


@Component({
  selector: 'app-grn',
  templateUrl: 'grn.html',
  styleUrls: ['../../../pagebase/pagebase_detail.scss']
})

export class PurchaseInvoiceComponent extends DMSBasePage implements IDetailPage, OnInit {
  @ViewChild('productSelector') productSelector: NgSelectComponent;
  @ViewChild('qty', {read: ElementRef}) qtyElement: ElementRef;
  OPERATION_MODE = 'P';

  dateDisable;
  myDate;
  public addressLibrary;
  public inco_terms_list;

  public priceEdited = false;
  public uomEdited = false;

  public pricelist_id;
  public pricelist_name;
  public partner_tcs;
  public salesman_id;
  public billto_adrr_list;
  public shipto_adrr_list;
  public selected_billto_addr_id;


  selectedProduct;
  selectedUOM_id;
  enteredQty;
  stock;
  order_ref;

  public selectedUser;

  showPlantField = false;
  showIncoTerms = false;
  showTotalWeight = false;
  showTotalMeasure = false;

  plantFieldValue = '';
  incoTermsFieldValue = '';
  totalMeasureFieldValue = '';
  totalWeightFieldValue = '';

  reference_order_mode = false;

  ngOnInit(): void {
    // this.setTitle('Return Invoice');
    this._Detail_GET_URL = "/api/pwa_dms_connects/get_invoice_detail";
    this.context = {componentParent: this};
    this.sharedService.setHeader('Purchase Invoice');

    this.configureGrid();

    this.initialize_default_values();

    this.readPageParms(params => {

      if (params.get('reference_order') === 'true') {

        const ref_order_object = JSON.parse(localStorage.getItem('reference_order'));
        if (ref_order_object !== undefined) {

          this.prepare_referance_object(ref_order_object);
          this.pageReady = true;

        }
      } else {

        if (this.activeID > 0) {
          this.newMode = false;
          this.editMode = false;
          //   View Mode
          //   Page Can be ready only after detail Object is fetched
          this.loadPageForView(() => {
            this.pageReady = true;
          });
        } else {
          this.newMode = true;
          this.editMode = true;
          //   Page Can be ready only after detail Object is fetched
          this.loadMasterDataForEdit(() => {
            this.pageReady = true;
          });
        }
      }
    });
  }

  loadPage() {
    this.loadPageForView(() => {
      this.pageReady = true;
    });
  }

  //Page Initilizefunctions
  prepare_referance_object(ref_order_object) {

    this.activeObject = {...ref_order_object}; //Shallow Copy the reference Object.
    // this.activeObject.order_id = this.activeObject.id;
    // this.activeObject.reference = ref_order_object.name;
    // this.activeObject.supplier_id = ref_order_object.distributor_id;

    this.activeObject.id = 0;
    this.activeObject.name = '';
    this.activeObject['state'] = 'draft';

    this.activeObject.lines = [];
    this.rowData = [];

    ref_order_object.lines.forEach(item => {
      // const ret_items = {...item};
      // ret_items.quantity = item.product_uom_qty;
      // ret_items.uom_id = item.uom_id;
      // ret_items.uom_name = item.uom_name;
      this.rowData.push(item);
    });

    this.activeID = 0;
    this.newMode = false;
    this.editMode = true;
    this.reference_order_mode = true;
  }

  initialize_default_values() {
    this.activeObject.amount_tax = 0;
    this.activeObject.amount_total = 0;
    this.activeObject.amount_untaxed = 0;
    this.activeObject.tax_source = 0;
    this.activeObject.charges = 0;
    this.activeObject.total = 0;
    this.activeObject.discount = 0;
    this.activeObject.line_discount = 0;
    this.activeObject.spcl_discount = 0;
    this.activeObject.date_invoice = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.partner_tcs = 0;
  }

  loadPageForView(callback) {

    function prepareLineData(lineItems) {
      const rowData = [];
      let unique_id = 1;

      if (lineItems) {
        lineItems.forEach(line => {
          line['uid'] = unique_id;
          rowData.push(line);
          unique_id++;
        });
      }
      return rowData;
    }

    this.loadActiveObject(() => {

      if (this.activeObject['partner_id'] === undefined) {
        this.toastr.error('invalid state: partner_id not found');
      }

      if (!this.activeObject['shipping_partner_id'] || this.activeObject['shipping_partner_id'] === 0) {
        this.activeObject['shipping_partner_id'] = this.activeObject['partner_id'];
      }

      if (this.activeObject && this.activeObject['lines'] && this.activeObject.lines.length > 0) {
        this.rowData = prepareLineData(this.activeObject.lines);
        // this.gridOptions.api.setRowData(this.rowData);
      }

      if (this.activeObject.hasOwnProperty('config_json') && this.activeObject.config_json !== null && this.activeObject.config_json !== undefined) {
        if (this.activeObject.config_json.hasOwnProperty('plant') && this.activeObject.config_json.plant !== null && this.activeObject.config_json.plant !== undefined) {
          this.showPlantField = true;
          this.plantFieldValue = this.activeObject.config_json.plant_name;
        }
        if (this.activeObject.config_json.hasOwnProperty('inco_term') && this.activeObject.config_json.inco_term !== null && this.activeObject.config_json.inco_term !== undefined) {
          this.showIncoTerms = true;
          this.incoTermsFieldValue = this.activeObject.config_json.inco_term;
        }
        if (this.activeObject.config_json.hasOwnProperty('totalWeight') && this.activeObject.config_json.totalWeight !== null && this.activeObject.config_json.totalWeight !== undefined) {
          this.showTotalWeight = true;
          this.totalWeightFieldValue = parseFloat(this.activeObject.config_json.totalWeight).toFixed(2);
        }
        if (this.activeObject.config_json.hasOwnProperty('totalMeasure') && this.activeObject.config_json.totalMeasure !== null && this.activeObject.config_json.totalMeasure !== undefined) {
          this.showTotalMeasure = true;
          this.totalMeasureFieldValue = parseFloat(this.activeObject.config_json.totalMeasure).toFixed(2);
        }
      }

      if (this.activeObject.amount_tax) {
        this.activeObject.amount_tax = parseFloat(this.activeObject.amount_tax);
      }
      if (this.activeObject.amount_total) {
        this.activeObject.amount_total = parseFloat(this.activeObject.amount_total);
      }
      if (this.activeObject.amount_untaxed) {
        this.activeObject.amount_untaxed = parseFloat(this.activeObject.amount_untaxed);
      }
      if (this.activeObject.tax_source) {
        this.activeObject.tax_source = parseFloat(this.activeObject.tax_source);
      } else if (this.activeObject.charges) {
        this.activeObject.charges = parseFloat(this.activeObject.charges);
      }

      this.compute_totals();

      const product_ids = [...new Set(this.activeObject.lines.map(item => item.product_id))];
      this.get_customer_available_stocks(this.activeObject.partner_id, this.activeObject.territory_id, product_ids, (err, result) => {
        if (err) {
          this.handleError(err);
        } else if (result) {
          this.rowData.forEach(line => {
            result.forEach(tp => {
              if (line.product_id === tp.product_id) {
                line.stock = tp.stock;
              }
            });
          });
        }
        this.gridOptions.api.setRowData(this.rowData);
        callback();
      });
    });

  }


  onCellEditingStarted(event: CellEditingStartedEvent) {
    console.log('cellEditingStarted');
  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {
    if (event.colDef.field === 'quantity') {
      event.data['reusable_qty'] = event.newValue;
      event.data['price_tax'] = 0;
      event.data['price_subtotal'] = parseInt(event.newValue, 10) * parseFloat(event.data['price_unit']);
      if (event.data['tax_amount'] !== undefined && event.data['tax_amount'] > 0) {
        event.data['price_tax'] = (event.data['price_subtotal'] * parseFloat(event.data['tax_amount'])) / 100;
      }
      event.data['price_total'] = parseFloat(event.data['price_subtotal']) + parseFloat(event.data['price_tax']);

      this.compute_scheme_and_tax();
    }
  }

  isExternalFilterPresent(): boolean {
    // to hide based on deleted flag
    return true;
  }

  doesExternalFilterPass(node: any): boolean {
    if (node.data) {
      return !(node.data.deleted);
    }
    return true;
  }

  //Page Events
  showCustomerSelection() {
    // const config: MatDialogConfig = {
    //   width: "100vw",
    //   height: "100vh",
    //   maxWidth: "1000px",
    //   maxHeight: "620px"
    // };
    //
    // const creatediaeref = this.dialog.open(DMSCustomerSelectionPopupComponent, config);
    //
    // creatediaeref.componentInstance.selectedOutlet.subscribe(data => {
    //   this.changeCustomer(data);
    //   this.dialog.closeAll();
    // });
  }

  customerChangeEvent(selected_customer_id) {
    const data = this.customerList.find(item => item.id === selected_customer_id);
    // this.changeCustomer(selected_customer);
    if (data) {
      this.activeObject['partner_id'] = data.id;
      this.activeObject['partner_invoice_id'] = data.id;
      this.activeObject['partner_shipping_id'] = data.id;
      this.activeObject['customer_name'] = data.name;
      this.activeObject['customer_code'] = data.code;
      this.activeObject['customer_gst_id'] = data.gst_id;
      this.activeObject['customer_email'] = data.email;
      this.activeObject['customer_address'] = data.address;

      this.activeObject['area_id'] = data.area_id;
      this.activeObject['zone_id'] = data.zone_id;
      this.activeObject['state_id'] = data.state_id;

      if (data.hasOwnProperty('partner_type') && data['partner_type'] === 'supplier') {
        this.OPERATION_MODE = 'P';
        this.activeObject['type'] = 'P';
      } else {
        this.OPERATION_MODE = 'S';
        this.activeObject['type'] = 'S';
      }

      this.activeObject['tax_source'] = data.tax_source;
      const prev_pricelist_id = this.activeObject['pricelist_id'];
      this.activeObject['pricelist_id'] = data.pricelist_id;
      this.activeObject['pricelist_name'] = data.pricelist_name;
      this.activeObject['shipto_address'] = data.address;
      // this.supplierList = [];
      this.beatList = [];

      if (data['beats']) {
        this.beatList = data['beats'];
        if (this.beatList.length > 0) {
          this.activeObject['beat_id'] = this.beatList[0].id;
          this.activeObject['territory_id'] = this.beatList[0].t_id;
          this.activeObject['user_id'] = this.beatList[0].sm_id;
          this.salesmanChange(this.activeObject['user_id']);
        }
      }

      // Check if the selected Customer has direct supplier mapping, Like The DC for van-sales
      // If so, Add to the Top
      // if (data.supplier_id > 0 ) {
      //   const supplier = this.supplierMasterList.find(item => item.id === data.supplier_id);
      //   this.supplierList.push(supplier);
      // }
      //
      // // If the supplier list for the selected logged in user, Like The territory Distributor, Then it should be added as immediate supplier
      // const defaultSuppliers = this.supplierMasterList.filter(item => item.default === true);
      // defaultSuppliers.forEach(supplier => {
      //   this.supplierList.push(supplier);
      // });
      //
      // if (data['suppliers']) {
      //   data['suppliers'].forEach(supplier_id => {
      //     if (supplier_id !== data.supplier_id) {
      //       const supplier = this.supplierMasterList.find(item => item.id === supplier_id);
      //       this.supplierList.push(supplier);
      //     }
      //   });
      // }

      if (this.supplierList.length === 0) {
        this.toastr.error('Unable to load suppliers');
      } else {
        this.supplierChangeEvent({id: this.supplierList[0].id});
      }
    }

  }

  // populateSupplierAttributes() {
  //   if (this.supplierList) {
  //     const supplier = this.supplierList.find(item => item.id === this.activeObject.supplier_id);
  //     if (supplier) {
  //       this.activeObject['supplier_name'] = supplier.name;
  //       this.activeObject['supplier_code'] = supplier.code;
  //       this.activeObject['supplier_gst_id'] = supplier.gst_id;
  //
  //       this.activeObject['supplier_territory_id'] = supplier.territory_id;
  //       if (this.activeObject['supplier_territory_id'] > 0) {
  //         if (this.activeObject['territory_id'] === null || this.activeObject['territory_id'] === undefined || this.activeObject['territory_id'] === 0) {
  //           this.activeObject['territory_id'] = this.activeObject['supplier_territory_id'];
  //         }
  //         if (this.activeObject['pricelist_id'] === null || this.activeObject['pricelist_id'] === undefined) {
  //           if (supplier.sec_pricelist_id > 0) {
  //             this.activeObject['pricelist_id'] = supplier.sec_pricelist_id;
  //           }
  //         }
  //       } else {
  //         this.toastr.error('Default Territory mapping is missing for selected supplier');
  //       }
  //     }
  //   }
  // }

  supplierChangeEvent(event) {

    if (event.id) {
      if (this.activeObject['supplier_id'] !== event.id) {
        this.activeObject['supplier_id'] = event.id;
        //Supplier Chnage Detected
        if (this.populateSupplierAttributes(this.activeObject['supplier_id'])) {
          this.loadProducts(this.activeObject['partner_id'], this.activeObject['supplier_id'], this.activeObject['supplier_territory_id'], this.activeObject['pricelist_id'], () => {
          });
        }
      }
    }
  }

  plantChangeEvent(plant_code) {
    if (plant_code) {
      this.activeObject['config_json']['plant'] = plant_code;

      const select_plan_obj = this.plantList.find(item => item.code = plant_code);
      if (select_plan_obj) {
        this.activeObject['config_json']['plant_name'] = select_plan_obj.name;
      }
    }
  }

  beatChange(beat_id) {
    this.activeObject['beat_id'] = beat_id;
    const selected_beat = this.beatList.find(item => item.id === beat_id);
    if (selected_beat) {
      this.activeObject['user_id'] = selected_beat.sm_id;

      this.activeObject['team_id'] = selected_beat.team_id;
      this.activeObject['salesman'] = selected_beat.sm;
    }
  }

  salesmanChange(user_id) {
    if (user_id) {
      const selecteduser = this.userList.find(item => item.id === user_id);
      if (selecteduser) {
        this.activeObject['salesman'] = selecteduser.itemname;
        this.activeObject['emp_code'] = selecteduser.emp_code;
        this.activeObject['team_id'] = selecteduser.sale_team_id;
      }
    }
  }

  productSelectorChange() {
    console.log(this.selectedProduct);
    const prod = this.prodList.find(item => item.p_id === this.selectedProduct);
    if (prod) {
      this.UOMList = this.UOMMasterList.filter(item => item.category_id === prod.uom_category_id);
      this.selectedUOM_id = prod.uom_id;
      this.stock = prod.stock;
    }
    if (this.qtyElement.nativeElement.value === "0") {
      this.qtyElement.nativeElement.value = "";
    }
    this.qtyElement.nativeElement.focus();
  }

  productUOMChange(uom_id) {
    console.log(this.selectedUOM_id);
  }

  changeDate(dt) {

  }

  discard() {
    this.editMode = false;
    if (this.activeObject && (!this.activeObject.hasOwnProperty('id') || this.activeObject.id === 0)) {
      history.back();
    }
  }

  action_edit() {
    this.editMode = true;
    this.pageReady = false;
    this.loadMasterDataForEdit(() => {
      this.loadProducts(this.activeObject['partner_id'], this.activeObject['supplier_id'], this.activeObject['supplier_territory_id'], this.activeObject['pricelist_id'], () => {
        this.pageReady = true;

        this.gridOptions.suppressClickEdit = false;
        this.gridOptions.singleClickEdit = true;
      });
    });
    this.gridApi.redrawRows();
  }

  gotoinvoice() {

    const order = this.activeObject;
    order['mid'] = undefined;
    localStorage.setItem('invoice_create', JSON.stringify(order));

    this.router.navigate([this.apiService.redirect_url + '/dms/sale_invoice'],
      {
        relativeTo: this.route,
        queryParams: {invoice_form_order: true}
      });
  }


  view_order() {

    if (this.activeObject.hasOwnProperty('order_id')) {
      this.router.navigate([this.apiService.redirect_url + '/dms/sale_order'],
        {
          relativeTo: this.route,
          queryParams: {id: this.activeObject['order_id']}
        });
    }
  }

  //GRID Functions
  configureGrid() {

    this.columnDefs = [
      {headerName: 'UID', field: 'uid', hide: true},
      {headerName: 'CODE', field: 'code', width: 50},
      {headerName: 'PRODUCT', field: 'name', width: 120},
      {headerName: 'STOCK', field: 'stock', width: 50},
      {
        headerName: 'QTY', field: 'quantity', width: 40, editable: true, cellEditor: 'agNumberCellEditor', cellRenderer: NumericEditorComponent,
        cellStyle: params => {
          if (params.data) {
            if (this.editMode) {
              return {backgroundColor: '#f2f6d7'};
            } else {
              return {backgroundColor: '#cae4f8'};
            }
          }
        }
      },
      {headerName: 'UOM', field: 'uom_name', width: 40},
      {headerName: 'PRICE', field: 'price_unit', width: 60, valueFormatter: params => this.formatNumber(params.data.price_unit)},
      {headerName: 'DISC%', field: 'discount', width: 50, editable: true, cellEditor: 'agNumberCellEditor', cellRenderer: NumericEditorComponent, hide: true},
      {headerName: 'TRADE DISC', field: 'trade_disc', width: 50, editable: true, cellEditor: 'agNumberCellEditor', cellRenderer: NumericEditorComponent, hide: true},
      {headerName: 'CHARGES', field: 'fixed_cost', width: 50, hide: true},
      {headerName: 'SCHEME', field: 'scheme_code', width: 80},
      {headerName: 'SCHEME DIS', field: 'scheme_discount', width: 50, valueFormatter: params => this.formatNumber(params.data.scheme_discount)},
      {headerName: 'SUB TOTAL', field: 'price_subtotal', width: 50, valueFormatter: params => this.formatNumber(params.data.price_subtotal)},
      {headerName: 'TAX', field: 'price_tax', width: 50, valueFormatter: params => this.formatNumber(params.data.price_tax)},
      {headerName: 'TAX Breakup', field: 'tax_notes', width: 50, hide: true},
      {headerName: 'TOTAL', field: 'price_total', width: 50, valueFormatter: params => this.formatNumber(params.data.price_total)},
      {headerName: 'Serial NO', hide: true, width: 100},
      {headerName: '', field: 'delete', lockPosition: true, cellRenderer: 'cellDeleteRenderer', width: 40},
    ];
    this.frameworkComponents = {
      cellDeleteRenderer: DeleteComponent,
    };
  }

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.uid;
  };

  addProductClick() {

    const new_line_uid = this.addProductLine(this.selectedProduct, this.selectedUOM_id, this.stock, this.enteredQty, undefined);

    this.compute_scheme_and_tax();

    this.enteredQty = 0;
    this.selectedProduct = 0;
    this.stock = 0;
    this.productSelector.focus();
  }

  removeProduct(index) {
    if (this.editMode) {
      const old_line = this.rowData[index];
      if (this.rowData[index].line_id !== undefined && this.rowData[index].line_id > 0) {
        this.rowData[index]['deleted'] = true;
        this.rowData[index]['quantity'] = 0;
        this.rowData[index]['reusable_qty'] = 0;

        this.gridApi.onFilterChanged();
      } else {
        this.rowData.splice(index, 1);
        this.gridApi.setRowData(this.rowData);
      }
      this.compute_totals();
    }
  }


  //Page core functions
  saveCart() {


    //TODO: Complete basic validation of page and line items
    if (this.activeObject['partner_id'] === undefined || this.activeObject['partner_id'] <= 0) {
      return this.toastr.error('Invalid Customer for invoice');
    }
    this.rowData.forEach(line => {
      if (line['mid'] === undefined || line['mid'].length === 0) {
        return this.toastr.error('Invalid mid for line');
      }
    });

    this.activeObject['lines'] = [];
    this.rowData.forEach(line => {
      this.activeObject['lines'].push(line);
    });

    //TODO: Validation
    this._Detail_SAVE_URL = "/api/pwa_dms_connects/save_sale_invoice";
    this.saveDetailPage((err, result) => {
      if (err) {
        this.toastr.error(err);
      } else {
        this.editMode = false;
        if ((this.reference_order_mode || this.newMode) && this.activeObject.id > 0) {
          this.router.navigate([this.apiService.redirect_url + '/dms/sale_invoice'], {
            relativeTo: this.route,
            queryParams: {id: this.activeObject.id}
          });
        }
      }
    });

  }

  action_create_invoice() {


  }

  action_cancel() {
    const inThis = this;
    this.userContext.data.id = this.activeObject.id;
    this.userContext['action'] = 'cancel';
    this.userContext['feature_disable_supplier_live_stock'] = this.sharedService.ACLcheck('feature_disable_supplier_live_stock');
    this.apiService.post('/api/pwa_dms_connects/sale_invoice_status_change', this.userContext)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && res.results.status === 200) {
            if (res.results.hasOwnProperty('data') && res.results.data.length > 0) {

              inThis.activeObject.state = res.results.data[0]['state'];
              this.toastr.success('Mark Delivery successfully :' + this.activeID);

            } else {
              this.toastr.success('Record Saved!');
            }
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        error => {
          this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
        });
  }

  // action_confirm_invoice() {
  //   let inThis = this;
  //   this.ConfirmInvoices([this.activeObject.id], function(err, res) {
  //     if (res.hasOwnProperty('results') && res.results.status === 200) {
  //       if (res.results.data.length > 0) {
  //         inThis.activeObject.state = res.results.data[0]['state'];
  //         inThis.toastr.success('Invoice Confirmed');
  //       } else {
  //         inThis.toastr.warning('Invoice already Confirmed!');
  //       }
  //
  //     } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
  //       this.handleError(ErrorHandler.getErrorObject(res.results.msg));
  //     } else {
  //       this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
  //     }
  //   });
  // }

  action_mark_received() {
    this.ChangeInvoiceStatus(this.activeObject.id, 'received', (err, result) => {
      if (result) {
        this.activeObject['state'] = result['state'];
        this.activeObject['grn'] = result['grn'];
      }
    });
  }

  action_accept_returns() {

    localStorage.setItem('reference_invoice', JSON.stringify(this.activeObject));

    this.router.navigate([this.apiService.redirect_url + '/dms/return_invoice'], {
      relativeTo: this.route,
      queryParams: {id: 0, reference_invoice: true, mode: this.OPERATION_MODE}
    });
  }

  openDeviationOrderPopup() {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "55%",
        maxHeight: "95%",
        panelClass: 'custom-dialog-container',
        data: {
          productData: this.rowData
        }
      };
      const creatediaeref = this.dialog.open(DeviationOrderPopupComponent, config);
      creatediaeref.afterClosed().subscribe(result => {

      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "90%",
        maxHeight: "95%%",
        data: {
          productData: this.rowData
        }
      };
      const creatediaeref = this.dialog.open(DeviationOrderPopupComponent, config);
      creatediaeref.afterClosed().subscribe(result => {

      });
    }
  }

}
